import { Fragment, useCallback, useState } from "react";
import { HtmlTooltip } from "./Tooltips";
import { Box, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

interface FocusedTooltipProps {
  tooltipContent: JSX.Element;
  children: React.ReactNode;
}
export const FocusedTooltip = ({
  tooltipContent,
  children,
}: FocusedTooltipProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleTooltipOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  return (
    <HtmlTooltip
      open={isOpen}
      disableHoverListener
      disableTouchListener
      title={
        <Fragment>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseButton onClick={toggleTooltipOpen} />
          </Box>

          <>{tooltipContent}</>
        </Fragment>
      }
    >
      <div onClick={toggleTooltipOpen}>{children}</div>
    </HtmlTooltip>
  );
};

const CloseButton = ({ onClick }) => {
  return (
    <IconButton aria-label="close" size="small" onClick={onClick}>
      <Close fontSize="small" />
    </IconButton>
  );
};
