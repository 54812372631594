type NestedObject = Record<string, unknown>;

export const snakeToCamelCase = (obj: NestedObject): NestedObject => {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Return if obj is not an object or is null
  }

  if (Array.isArray(obj)) {
    // If obj is an array, recursively call snakeToCamel on each element
    return obj.map((item) => snakeToCamelCase(item)) as unknown as NestedObject;
  }

  // Create an empty object to store the result
  const newObj: NestedObject = {};

  // Iterate through the keys of the object
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Convert snake case key to camel case
      const camelKey = key.replace(/_([a-z])/g, (_, letter) =>
        letter.toUpperCase()
      );
      // Recursively call snakeToCamel on the value of the current key
      newObj[camelKey] = snakeToCamelCase(obj[key] as NestedObject);
    }
  }

  return newObj;
};
