import { STATUS_IDS } from "../study-table-rows/studyTableRows.consts";

export const convertStatusValueToId = (statusValue: string | undefined) => {
  if (statusValue === undefined) return undefined;
  const selectedStatus = STATUS_IDS.find(({ value }) => value === statusValue);
  if (selectedStatus) {
    return selectedStatus.id;
  }
  return undefined;
};
