import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useCallback, useState } from "react";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { useDarkModeTheme } from "hooks/useDarkmodeTheme";
import { useUpdateStudyData } from "../../hooks/useUpdateStudyData";
import { FIELD_KEYS } from "../../study-table-columns/columns.consts";
import { useHomePageContext } from "pages/home-page/context/homepage.context";
import { useIsLocked } from "../../hooks/useIsLocked";

export const LockComponent = ({ studyData }) => {
  const { studypk } = studyData;
  const isLocked = useIsLocked(studyData);
  const updateStudy = useUpdateStudyData();
  const { dispatch } = useHomePageContext();
  const toggleLock = useCallback(() => {
    const newValue = !isLocked;
    const submissionData = { [FIELD_KEYS.LOCK]: newValue };
    updateStudy(studypk, submissionData);
    dispatch({
      type: "TOGGLE_ROW_LOCK",
      payload: {
        lockedRows: newValue === true ? [studypk] : [],
        unlockedRows: newValue === false ? [studypk] : [],
      },
    });
  }, [studypk, updateStudy, isLocked, dispatch]);

  const { brandThemeColor } = useDarkModeTheme();
  return (
    <Button onClick={toggleLock}>
      <FontAwesomeIcon
        icon={isLocked ? faLock : faUnlock}
        style={{
          color: brandThemeColor,
          height: "30px",
          marginLeft: "5px",
          cursor: "pointer",
        }}
      ></FontAwesomeIcon>
    </Button>
  );
};
