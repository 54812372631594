import { FRAMED_MODALITIES } from "../dicomViewer.consts";
import { ViewportState } from "../dicomViewer.types";

export const formatViewportUpdates = (
  updatedGridDimensions: number[],
  modalityType: string,
  studyImageData,
  currentViewports: ViewportState[]
): ViewportState[] => {
  const isFramedModality = FRAMED_MODALITIES.includes(modalityType);
  const length = updatedGridDimensions[0] * updatedGridDimensions[1];
  const differenceInLength = length - currentViewports.length;
  if (studyImageData && studyImageData[0] && differenceInLength !== 0) {
    if (differenceInLength > 0) {
      const defaultViewport = {
        imageIds: studyImageData[0].image,
        frames: isFramedModality ? studyImageData[0].frameImageIds ?? [] : null,
        studyImageIndex: 0,
        totalFrames: isFramedModality
          ? studyImageData[0].frameImageIds.length
          : 1,
      };
      const newViewports = new Array(differenceInLength).fill(defaultViewport);
      return [...currentViewports, ...newViewports];
    } else {
      return currentViewports.splice(
        0,
        currentViewports.length - differenceInLength
      );
    }
  }
  return currentViewports;
};
