// @ts-nocheck
import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import Drawing from "../../api/Drawing";
import Util from "../../api/Util";
import Manipulators from "../../api/Manipulators";
import { ImageMetadata } from "pages/viewer/dicomViewer.types";
import { getDimensionData } from "../measurementToolUtils";
import {
  calculateCircleCenter,
  calculateGradient,
  distance,
} from "./curveRadiusFunctions";
import { MEASUREMENT_SUFFIXES } from "../../consts/tools.consts";
import toolColors from "../../api/state-management/toolColours";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);
const drawLinkedTextBox = cornerstoneTools.import("drawing/drawLinkedTextBox");

export class CurveRadius extends BaseAnnotationTool {
  imageMetaData: ImageMetadata;

  constructor() {
    super({
      name: "CurveRadius",
      supportedInteractionTypes: ["Mouse", "Touch"],
    });
    this.imageMetaData = {};
  }

  updateCachedStats(image, element, data) {
    const {
      handles: { start, end, mid },
    } = data;
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );

    const center = calculateCircleCenter(start, end, mid);
    const radius = distance(center, start, rowPixelSpacing, colPixelSpacing);

    data.radius = radius;
    data.suffix = MEASUREMENT_SUFFIXES.MM;
    data.invalidated = false;
  }

  addNewMeasurement(event, interactionType) {
    const element = event.detail.element;

    let measurementData = this.createNewMeasurement(
      event.detail,
      interactionType
    );

    let doneMovingCallback = (success) => {
      if (!success) {
        cornerstoneTools.removeToolState(element, this.name, measurementData);
        return;
      }

      let currentState = cornerstoneTools.getToolState(element, this.name);

      let currentStateData = currentState.data[currentState.data.length - 1];
      currentStateData.handles.mid.x =
        (currentStateData.handles.end.x - currentStateData.handles.start.x) /
          2 +
        currentStateData.handles.start.x;
      currentStateData.handles.mid.y =
        (currentStateData.handles.end.y - currentStateData.handles.start.y) /
          2 +
        currentStateData.handles.start.y;

      cornerstoneTools.addToolState(element, this.name, currentStateData);
    };

    cornerstoneTools.addToolState(element, this.name, measurementData);

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);

    //measurementData.handles.mid.y = (measurementData.handles.start.y - measurementData.handles.end.y)/2;//measurementData.handles.start.x;

    Manipulators.moveNewHandle(
      //the selected handle follows the mouse pointer until it is placed somewhere
      event.detail, //eventData
      this.name, //toolName
      measurementData, //annotation
      measurementData.handles.end, //handle
      this.options, //options
      interactionType, //interaction type
      doneMovingCallback //doneMovingCallback
    );
  }

  createNewMeasurement(event, interactionType) {
    const goodEventData =
      event && event.currentPoints && event.currentPoints.image;
    if (!goodEventData) {
      // //console.log(`required eventData not supplied to tool ${this.name}'s createNewMeasurement`);
      return;
    }
    const { x, y } = event.currentPoints.image;
    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,

      complete: false, //////////////////

      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        mid: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          //edit here to draw many points for many lengths between different points
          x,
          y,
          highlight: true,
          active: false,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    let nearTool = false;

    const validParameters =
      data && data.handles && data.handles.start && data.handles.end;
    if (!validParameters) {
      // //console.log(`invalid parameters supplied to tool ${this.name}'s pointNearTool`);
      return false;
    }

    if (data.visible === false) {
    }

    const { midHandle, curveRadius, curveCenter, plotRegion } =
      this.getCurveData(element, data);

    const distFromCurveCenter = Math.sqrt(
      Math.pow(coords.x - curveCenter.x, 2) +
        Math.pow(coords.y - curveCenter.y, 2)
    );
    if (
      Math.abs(distFromCurveCenter - curveRadius) < 10 &&
      plotRegion(coords.x, coords.y)
    ) {
      nearTool = true;
    }

    if (curveRadius == Infinity) {
      if (
        Util.lineSegDistance(
          element,
          data.handles.start,
          data.handles.end,
          coords
        ) < 10
      ) {
        nearTool = true;
      }
    }

    const distFromMidHandle = Math.sqrt(
      Math.pow(coords.x - midHandle.x, 2) + Math.pow(coords.y - midHandle.y, 2)
    );
    if (distFromMidHandle < 5) {
      nearTool = true;
    }

    if (nearTool) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
    }

    return nearTool;
  }

  distanceFromPoint(event) {}
  getCurveData(element, data) {
    const startHandle = cornerstone.pixelToCanvas(element, data.handles.start);
    const endHandle = cornerstone.pixelToCanvas(element, data.handles.end);
    const midHandle = cornerstone.pixelToCanvas(element, data.handles.mid);

    const handlerCenterCoordinates = {
      x: (startHandle.x + endHandle.x) / 2,
      y: (startHandle.y + endHandle.y) / 2,
    };
    const handlerDistance = distance(startHandle, endHandle);
    const handlerGradient = calculateGradient(startHandle, endHandle);

    const perpGrad = -1 * (1 / handlerGradient);
    const midHandleOffset =
      (endHandle.y -
        handlerGradient * endHandle.x -
        (midHandle.y - handlerGradient * midHandle.x)) /
      Math.sqrt(Math.pow(handlerGradient, 2) + 1);

    let curveRadius =
      (Math.pow(midHandleOffset, 2) + Math.pow(handlerDistance / 2, 2)) /
      (2 * Math.abs(midHandleOffset));
    const curveCenterDisp = curveRadius - Math.abs(midHandleOffset);
    let curveCenter = handlerCenterCoordinates;
    const chordCenterDispX = Math.sqrt(
      Math.pow(curveCenterDisp, 2) / (1 + Math.pow(perpGrad, 2))
    );
    const chordCenterDispY = Math.sqrt(
      Math.pow(curveCenterDisp, 2) / (1 + Math.pow(perpGrad, -2))
    );

    let offsetSign = 1;

    let plotRegion = (x, y) => {
      return true;
    };

    if (handlerGradient < 0 && midHandleOffset > 0) {
      ////console.log('State A');
      curveCenter = {
        x: handlerCenterCoordinates.x + chordCenterDispX,
        y: handlerCenterCoordinates.y + chordCenterDispY,
      };
      plotRegion = (x, y) => {
        return (
          y <
          handlerGradient * x + startHandle.y - handlerGradient * startHandle.x
        );
      };
      offsetSign = -1;
    } else if (handlerGradient > 0 && midHandleOffset > 0) {
      ////console.log('State B');
      curveCenter = {
        x: handlerCenterCoordinates.x - chordCenterDispX,
        y: handlerCenterCoordinates.y + chordCenterDispY,
      };
      plotRegion = (x, y) => {
        return (
          y <
          handlerGradient * x + startHandle.y - handlerGradient * startHandle.x
        );
      };
    } else if (handlerGradient < 0 && midHandleOffset < 0) {
      ////console.log('State C');
      curveCenter = {
        x: handlerCenterCoordinates.x - chordCenterDispX,
        y: handlerCenterCoordinates.y - chordCenterDispY,
      };
      plotRegion = (x, y) => {
        return (
          y >
          handlerGradient * x + startHandle.y - handlerGradient * startHandle.x
        );
      };
    } else if (handlerGradient > 0 && midHandleOffset < 0) {
      ////console.log('State D');
      curveCenter = {
        x: handlerCenterCoordinates.x + chordCenterDispX,
        y: handlerCenterCoordinates.y - chordCenterDispY,
      };
      plotRegion = (x, y) => {
        return (
          y >
          handlerGradient * x + startHandle.y - handlerGradient * startHandle.x
        );
      };
      offsetSign = -1;
    }

    if (Math.abs(midHandleOffset) > handlerDistance / 2) {
      curveCenter = handlerCenterCoordinates;
      curveRadius = handlerDistance / 2;
    }

    const perpGradSquared = Math.pow(perpGrad, 2);
    const coefA = perpGradSquared + 1;
    const coefB = curveCenter.x * -2 * (perpGradSquared + 1);
    const coefC =
      Math.pow(curveCenter.x, 2) * (perpGradSquared + 1) -
      Math.pow(curveRadius, 2);
    const offsetPointX =
      (-1 * coefB +
        offsetSign * Math.sqrt(Math.pow(coefB, 2) - 4 * coefA * coefC)) /
      (2 * coefA);
    const offsetPointY =
      perpGrad * offsetPointX + curveCenter.y - perpGrad * curveCenter.x;
    let offsetPoint = { x: offsetPointX, y: offsetPointY };
    const startAng = 0; //Math.atan((curveCenter.y - startHandle.y)/(curveCenter.x - startHandle.x)); //0; //45*Math.PI/180;//
    const endAng = 2 * Math.PI; //Math.atan((curveCenter.y - endHandle.y)/(curveCenter.x - endHandle.x)); //0; //2*Math.PI; //2*Math.PI;//
    const inc = Math.PI / 180;
    for (let ang = startAng; ang < endAng; ang = ang + inc) {
      let xStart = curveCenter.x + curveRadius * Math.cos(ang);
      let yStart = curveCenter.y + curveRadius * Math.sin(ang);
      let xEnd = curveCenter.x + curveRadius * Math.cos(ang + inc);
      let yEnd = curveCenter.y + curveRadius * Math.sin(ang + inc);
      if (plotRegion(xStart, yStart) || plotRegion(xEnd, yEnd)) {
        if (!plotRegion(xStart, yStart)) {
          if (
            Math.sqrt(
              Math.pow(xStart - startHandle.x, 2) +
                Math.pow(yStart - startHandle.y, 2)
            ) <
            Math.sqrt(
              Math.pow(xStart - endHandle.x, 2) +
                Math.pow(yStart - endHandle.y, 2)
            )
          ) {
            xStart = startHandle.x;
            yStart = startHandle.y;
          } else {
            xStart = endHandle.x;
            yStart = endHandle.y;
          }
        }
        if (!plotRegion(xEnd, yEnd)) {
          if (
            Math.sqrt(
              Math.pow(xEnd - startHandle.x, 2) +
                Math.pow(yEnd - startHandle.y, 2)
            ) <
            Math.sqrt(
              Math.pow(xEnd - endHandle.x, 2) + Math.pow(yEnd - endHandle.y, 2)
            )
          ) {
            xEnd = startHandle.x;
            yEnd = startHandle.y;
          } else {
            xEnd = endHandle.x;
            yEnd = endHandle.y;
          }
        }
      }
    }
    return {
      startHandle,
      midHandle,
      endHandle,
      midHandleOffset,
      curveRadius,
      curveCenter,
      plotRegion,
    };
  }
  renderCurve(eventData, context, data, circleOptions) {
    const { element } = eventData;
    const {
      midHandleOffset,
      startHandle,
      endHandle,
      curveCenter,
      curveRadius,
      plotRegion,
    } = this.getCurveData(element, data);

    const startAng = 0;
    const endAng = 2 * Math.PI;
    const inc = Math.PI / 180;
    for (let ang = startAng; ang < endAng; ang = ang + inc) {
      let xStart = curveCenter.x + curveRadius * Math.cos(ang);
      let yStart = curveCenter.y + curveRadius * Math.sin(ang);
      let xEnd = curveCenter.x + curveRadius * Math.cos(ang + inc);
      let yEnd = curveCenter.y + curveRadius * Math.sin(ang + inc);
      if (plotRegion(xStart, yStart) || plotRegion(xEnd, yEnd)) {
        if (!plotRegion(xStart, yStart)) {
          if (
            Math.sqrt(
              Math.pow(xStart - startHandle.x, 2) +
                Math.pow(yStart - startHandle.y, 2)
            ) <
            Math.sqrt(
              Math.pow(xStart - endHandle.x, 2) +
                Math.pow(yStart - endHandle.y, 2)
            )
          ) {
            xStart = startHandle.x;
            yStart = startHandle.y;
          } else {
            xStart = endHandle.x;
            yStart = endHandle.y;
          }
        }
        if (!plotRegion(xEnd, yEnd)) {
          if (
            Math.sqrt(
              Math.pow(xEnd - startHandle.x, 2) +
                Math.pow(yEnd - startHandle.y, 2)
            ) <
            Math.sqrt(
              Math.pow(xEnd - endHandle.x, 2) + Math.pow(yEnd - endHandle.y, 2)
            )
          ) {
            xEnd = startHandle.x;
            yEnd = startHandle.y;
          } else {
            xEnd = endHandle.x;
            yEnd = endHandle.y;
          }
        }
        Drawing.drawLine(
          context,
          element,
          { x: xStart, y: yStart }, //{x,y}
          { x: xEnd, y: yEnd }, //{x,y}
          circleOptions,
          "canvas"
        );
      }
    }

    if (midHandleOffset == 0) {
      Drawing.drawLine(
        context, //Target context (CanvasRenderingContext2D)
        element,
        { x: startHandle.x, y: startHandle.y }, //{x,y}
        { x: endHandle.x, y: endHandle.y }, //{x,y}
        circleOptions, //See path
        "canvas"
      );
    }

    // @ts-ignore
    Drawing.drawHandles(context, eventData, data.handles, (5, "green"));

    context.beginPath();
    context.fillStyle = "red";
    context.arc(curveCenter.x, curveCenter.y, 3, 0, 2 * Math.PI);
    context.fill();
  }
  renderToolData(event) {
    const eventData = event.detail;
    // We have tool data for this element - iterate over each one and draw it
    const context = Drawing.getNewContext(eventData.canvasContext.canvas);
    const toolData = cornerstoneTools.getToolState(
      event.currentTarget,
      this.name
    );
    const color = toolColors.getColorIfActive(toolData);
    const circleOptions = { color };

    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      this.renderCurve(eventData, context, data, circleOptions);

      const { radius, suffix } = data;
      if (radius) {
        const { element } = eventData;

        if (!data.handles.textBox.hasMoved) {
          const coords = {
            x: Math.max(data.handles.start.x, data.handles.end.x),
            y: 0,
          };

          if (coords.x === data.handles.start.x) {
            coords.y = data.handles.start.y;
          } else {
            coords.y = data.handles.end.y;
          }

          data.handles.textBox.x = coords.x;
          data.handles.textBox.y = coords.y;
        }
        const formattedRadius = radius.toFixed(2);
        const text = `Curve radius: ${formattedRadius} ${suffix}`;
        drawLinkedTextBox(
          context, //The canvas context.
          element, //The element on which to draw the link. HTMLElement
          data.handles.textBox, //The textBox to link.
          text, //The text to display in the textbox.
          data.handles, //The handles of the annotation.
          textBoxAnchorPoints, //An array of possible anchor points on the textBox.
          color, //The link color.
          2, //The line width of the link.
          10, //The x offset of the textbox.
          true //Vertically centers the text if true.
        );
      }
    }

    function textBoxAnchorPoints(handles) {
      return [handles.start, handles.mid, handles.end];
    }
  }

  public setImageMetadata(imageMetaData: ImageMetadata) {
    if (imageMetaData) {
      this.imageMetaData = imageMetaData;
    }
  }
}
