import { Skeleton, styled } from "@mui/material";
import { ColumnConfig } from "./table.types";

const TableLoaderContainer = styled("tbody")`
  width: 100%;
  height: 500px;
`;

const StyledSkeleton = styled(Skeleton)`
  height: 40px; // Example height, you can adjust as needed
  width: 100%;
  margin-bottom: 10px; // Example margin, you can adjust as needed
`;

interface TableLoaderProps {
  columns: ColumnConfig[];
}
export const TableLoader = ({ columns }: TableLoaderProps) => {
  const arr = new Array(10).fill("");
  return (
    <TableLoaderContainer>
      {arr.map((_item, index) => (
        <tr key={`item-${index}`}>
          {columns.map((_column, index) => (
            <td key={`td-${index}`}>
              <StyledSkeleton variant="rectangular" />
            </td>
          ))}
        </tr>
      ))}
    </TableLoaderContainer>
  );
};
