import cornerstoneTools from "cornerstone-tools";

/**
 * Manipulators describe a tool's `handle` behavior. Leveraging a small set of manipulators
 * allows us to create a consistent experience when interacting with tools via their handles.
 * @namespace Manipulators
 */
export const Manipulators = {
  anyHandlesOutsideImage: cornerstoneTools.importInternal(
    "manipulators/anyHandlesOutsideImage"
  ),
  getHandleNearImagePoint: cornerstoneTools.importInternal(
    "manipulators/getHandleNearImagePoint"
  ),
  getHandlePixelPosition: cornerstoneTools.importInternal(
    "manipulators/getHandlePixelPosition"
  ),
  handleActivator: cornerstoneTools.importInternal(
    "manipulators/handleActivator"
  ),
  moveAllHandles: cornerstoneTools.importInternal(
    "manipulators/moveAllHandles"
  ),
  moveHandle: cornerstoneTools.importInternal("manipulators/moveHandle"),
  moveNewHandle: cornerstoneTools.importInternal("manipulators/moveNewHandle"),
  moveHandleNearImagePoint: cornerstoneTools.importInternal(
    "manipulators/moveHandleNearImagePoint"
  ),
  findHandleDataNearImagePoint: cornerstoneTools.importInternal(
    "manipulators/findHandleDataNearImagePoint"
  ),
  moveAnnotation: cornerstoneTools.importInternal(
    "manipulators/moveAnnotation"
  ),
};

export default Manipulators;
