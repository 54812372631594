import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import Drawing from "../../api/Drawing";
import Util from "../../api/Util";
import { TOOL_IDS } from "../../consts/tools.consts";
import {
  checkEventData,
  isPointNearToolEventValid,
} from "../../functions/eventHandlingValidation";
import { DEFAULT_HANDLE } from "../../consts/tools.defaults";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

export class StraightLine extends BaseAnnotationTool {
  hasIncomplete: boolean;
  toolId: string;
  constructor() {
    super({
      name: TOOL_IDS.STRAIGHT_LINE,
      supportedInteractionTypes: ["Mouse", "Touch"],
    });
    this.toolId = TOOL_IDS.STRAIGHT_LINE;
    this.hasIncomplete = false;
  }

  activeCallback(_element) {}

  preMouseDownCallback(_event) {}

  createNewMeasurement(event) {
    const isEventDataValid = checkEventData(event);
    if (isEventDataValid) {
      const { x, y } = event.currentPoints.image;
      return {
        visible: true,
        active: true,
        color: undefined,
        invalidated: true,
        complete: false,

        handles: {
          start: {
            x,
            y,
            highlight: true,
            active: false,
          },
          end: {
            x,
            y,
            highlight: true,
            active: false,
          },
        },
      };
    }
  }

  pointNearTool(element, data, coords) {
    const validPointerEvent = isPointNearToolEventValid(data);
    if (validPointerEvent) {
      if (this.hasIncomplete) {
        return false;
      }
      const line =
        Util.lineSegDistance(
          element,
          {
            x: data.handles.start.x,
            y: Math.min(data.handles.start.y, data.handles.end.y),
          },
          {
            x: data.handles.start.x,
            y: Math.max(data.handles.start.y, data.handles.end.y),
          },
          coords
        ) < 10;

      if (line) {
        data.handles.start.movesIndependently = false;
        data.handles.end.movesIndependently = false;
      }
      return line;
    }
  }

  distanceFromPoint(_event) {}

  renderToolData(event) {
    const eventData = event.detail;
    // We have tool data for this element - iterate over each one and draw it
    const context = Drawing.getNewContext(eventData.canvasContext.canvas);
    const toolData = cornerstoneTools.getToolState(
      event.currentTarget,
      this.toolId
    );

    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      const textCoordsStart = cornerstone.pixelToCanvas(
        eventData.element,
        data.handles.start
      );

      const textCoordsEnd = cornerstone.pixelToCanvas(
        eventData.element,
        data.handles.end
      );

      const { x: startX, y: startY } = textCoordsStart;
      const { x: endX, y: endY } = textCoordsEnd;

      context.beginPath();
      context.fillStyle = "red";
      context.arc(startX, startY, 3, 0, 2 * Math.PI); // Control point two handle 2
      context.arc(endX, endY, 3, 0, 2 * Math.PI);
      context.fill();

      const lineHandles = {
        start: {
          ...data.handles.start,
        },
        end: {
          ...data.handles.end,
        },
      };
      const handleOptions = {
        ...DEFAULT_HANDLE,
      };
      Drawing.drawHandles(context, eventData, lineHandles, handleOptions);
      Drawing.drawLine(
        context,
        eventData.element,
        textCoordsStart,
        textCoordsEnd,
        "red",
        "canvas"
      );
    }
  }
}
