import cornerstoneTools from "cornerstone-tools";

/**
 * Base Tools
 * @namespace Base
 */
export const Base = {
  BaseTool: cornerstoneTools.importInternal("base/BaseTool"),
  BaseAnnotationTool: cornerstoneTools.importInternal(
    "base/BaseAnnotationTool"
  ),
  BaseBrushTool: cornerstoneTools.importInternal("base/BaseBrushTool"),
};

export default Base;
