import { useGoogleLogin } from "@react-oauth/google";
import { ActionButton } from "components/buttons/Buttons";
import { useHandleGoogleResponse } from "./useHandleGoogleResponse";

interface GoogleLoginSectionProps {
  toggleIsLoading: () => void;
  redirectUrlOnLogin?: string | null;
}
export const GoogleLoginSection = ({
  toggleIsLoading,
  redirectUrlOnLogin,
}: GoogleLoginSectionProps) => {
  const { handleGoogleSuccess, handleGoogleError } = useHandleGoogleResponse(
    toggleIsLoading,
    redirectUrlOnLogin
  );

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleSuccess(tokenResponse),
    onError: handleGoogleError,
    flow: "auth-code",
    include_granted_scopes: false,
  });
  return (
    <ActionButton onClick={() => login()} fullWidth>
      LOGIN WITH GOOGLE
    </ActionButton>
  );
};
