import { getAuthorizationData } from "./getAuthorizationData";
import { snakeToCamelCase } from "./snakeToCamelCase";

interface PostDataOptions {
  uri: string;
  body: Record<string, unknown>;
  parsedHeaders?: Record<string, string>;
  convertToCamel?: boolean;
}

interface ResponseData {
  [key: string]: any;
}

export const postData = async ({
  uri,
  body,
  parsedHeaders = {},
  convertToCamel = false,
}: PostDataOptions): Promise<ResponseData | { errorMessage: string }> => {
  try {
    const { accessToken } = getAuthorizationData();
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
      ...parsedHeaders,
    };
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/${uri}`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }
    );

    if (headers.responseType === "blob") {
      if (response.status == 202) {
        return response;
      } else {
        return await response.blob();
      }
    } else {
      const responseData = await response.json();
      if (!response.ok) {
        const errorMessage = responseData.message ?? "Post Request Failed";
        if (errorMessage === "Forbidden: Token invalid") {
          window.location.href = "/login";
        }
        throw new Error(errorMessage);
      }
      if (convertToCamel) {
        return snakeToCamelCase(responseData);
      } else {
        responseData.status = response.status;
        return responseData;
      }
    }
  } catch (error) {
    return { errorMessage: (error as Error).message };
  }
};
