// Function to calculate the distance between two points
export const distance = (
  point1,
  point2,
  colPixelSpacing = 1,
  rowPixelSpacing = 1
) => {
  return Math.sqrt(
    Math.pow(point2.x - point1.x, 2) * colPixelSpacing +
      Math.pow(point2.y - point1.y, 2) * rowPixelSpacing
  );
};

export const calculateGradient = (start, end) => {
  return (end.y - start.y) / (end.x - start.x);
};

// Function to calculate the center of the circle passing through three points
export const calculateCircleCenter = (start, end, mid) => {
  // const { dx, dy } = getAxisLengths(
  //   start,
  //   end,
  //   colPixelSpacing,
  //   rowPixelSpacing
  // );
  const slope1 = (end.y - start.y) / (end.x - start.x);
  const slope2 = (mid.y - end.y) / (mid.x - end.x);

  const centerX =
    (slope1 * slope2 * (start.y - mid.y) +
      slope2 * (start.x + end.x) -
      slope1 * (end.x + mid.x)) /
    (2 * (slope2 - slope1));
  const centerY =
    (-1 / slope1) * (centerX - (start.x + end.x) / 2) + (start.y + end.y) / 2;

  return { x: centerX, y: centerY };
};
