import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { DEFAULT_DRAWER_STYLES, DRAWER_SIDES } from "./drawer.consts";
import { FloatingDrawerButton } from "./FloatingDrawerButton";
import { DrawerStyles } from "./drawer.types";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  justifyContent: "flex-end",
}));

interface PersistentDrawerProps {
  drawerButtonTitle: string;
  children: ReactNode;
  drawerSide: string;
  isOpenDefault?: boolean;
  drawerStyles?: Partial<DrawerStyles>;
}

export const PersistentDrawer = ({
  drawerButtonTitle,
  isOpenDefault = false,
  children,
  drawerSide = DRAWER_SIDES.LEFT,
  drawerStyles = {},
}: PersistentDrawerProps) => {
  const formattedStyles = useDrawerStyles(drawerStyles, DEFAULT_DRAWER_STYLES);
  const { widthPx } = formattedStyles;
  const theme = useTheme();
  const [isOpen, setOpen] = useState(isOpenDefault);

  const toggleDrawer = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen, setOpen]);
  const backgroundThemeColor = "#f5f5f5";
  return (
    <>
      <Drawer
        sx={{
          width: `${widthPx}px`,
          flexShrink: 0,
          backgroundColor: backgroundThemeColor,
          "& .MuiDrawer-paper": {
            width: `${widthPx}px`,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        // @ts-ignore
        anchor={drawerSide}
        open={isOpen}
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        {children}
      </Drawer>
      <FloatingDrawerButton
        drawerSide={drawerSide}
        drawerStyles={formattedStyles}
        isDrawerOpen={isOpen}
        drawerButtonTitle={drawerButtonTitle}
        onClick={toggleDrawer}
      />
    </>
  );
};

const useDrawerStyles = (
  parsedDrawerStyles: Partial<DrawerStyles>,
  defaultDrawerStyles: DrawerStyles
) =>
  useMemo(
    () => ({
      ...defaultDrawerStyles,
      ...parsedDrawerStyles,
    }),
    [parsedDrawerStyles, defaultDrawerStyles]
  );
