import cornerstone from "cornerstone-core";
import { metaData as metadata } from "@cornerstonejs/core";

// There are 4 items in a pixel - r,g,b,a
const PIXEL_UNIT_COUNT = 4;

const getMinMax = (storedPixelData) => {
  // we always calculate the min max values since they are not always
  // present in DICOM and we don't want to trust them anyway as cornerstone
  // depends on us providing reliable values for these
  var min = 65535;
  var max = -32768;
  var numPixels = storedPixelData.length;
  var pixelData = storedPixelData;
  for (var index = 0; index < numPixels; index++) {
    var spv = pixelData[index];
    // TODO: test to see if it is faster to use conditional here rather than calling min/max functions
    min = Math.min(min, spv);
    max = Math.max(max, spv);
  }

  return {
    min: min,
    max: max,
  };
};

function isModalityLUTForDisplay(sopClassUid) {
  // special case for XA and XRF
  // https://groups.google.com/forum/#!searchin/comp.protocols.dicom/Modality$20LUT$20XA/comp.protocols.dicom/UBxhOZ2anJ0/D0R_QP8V2wIJ
  return (
    sopClassUid !== "1.2.840.10008.5.1.4.1.1.12.1" && // XA
    sopClassUid !== "1.2.840.10008.5.1.4.1.1.12.2.1"
  ); // XRF
}
const createImage = (image, Metadata) => {
  const voiLutModule = Metadata.voiLutModule ?? {};
  const modalityLutModule = Metadata.modalityLutModule ?? {};
  const sopCommonModule = Metadata.sopCommonModule ?? {};
  // Modality LUT
  if (
    modalityLutModule.modalityLUTSequence &&
    modalityLutModule.modalityLUTSequence.length > 0 &&
    isModalityLUTForDisplay(sopCommonModule.sopClassUID)
  ) {
    image.modalityLUT = modalityLutModule.modalityLUTSequence[0];
  }
  // VOI LUT
  if (voiLutModule.voiLUTSequence && voiLutModule.voiLUTSequence.length > 0) {
    image.voiLUT = voiLutModule.voiLUTSequence[0];
  }
  return image;
};

export const reconstructImages = async (
  imagePoint,
  axialImages,
  seriesIndex,
  processedMetadata
) => {
  if (!axialImages || axialImages.length === 0) {
    return;
  }
  const [rowPixelSpacing, columnPixelSpacing] = String(
    processedMetadata.pixelSpacing
  )
    .split("\\")
    .map(parseFloat);
  var y = Math.round(imagePoint.y);
  var x = Math.round(imagePoint.x);
  let coronalPixels = [];
  let pixelNumV = 0;
  let pixelNum = 0;
  let storedPixelsV = [];
  const sampleImg = axialImages[0];
  for (let imageIndex = 0; imageIndex < axialImages.length; imageIndex += 1) {
    const currentImage = axialImages[imageIndex];
    const tmpPixelMatrix = currentImage.getPixelData();

    var unitNum = Math.round(
      tmpPixelMatrix.length / currentImage.columns / currentImage.rows
    );

    for (var column = 0; column < currentImage.columns; column++) {
      for (var index = 0; index < PIXEL_UNIT_COUNT; index++) {
        var spIndex =
          ((y - 1) * currentImage.columns + column) * unitNum + index;
        coronalPixels[pixelNum++] = tmpPixelMatrix[spIndex];
      }
    }

    for (var row = 0; row < currentImage.rows; row++) {
      for (var index = 0; index < unitNum; index++) {
        var spIndex = (row * currentImage.columns + x) * unitNum + index;
        storedPixelsV[pixelNumV++] = tmpPixelMatrix[spIndex];
      }
    }
  }

  var minMax = getMinMax(coronalPixels);

  const lut = cornerstone.generateLut(
    sampleImg,
    sampleImg.windowWidth,
    sampleImg.windowCenter,
    false
  );

  // Extract the various attributes we need
  const horizontalImg = {
    imageId:
      seriesIndex.toString() +
      "." +
      x.toString() +
      "." +
      y.toString() +
      "." +
      "H",
    minPixelValue: minMax.min,
    maxPixelValue: minMax.max,
    getPixelData: () => coronalPixels,
    rows: axialImages.length,
    columns: sampleImg.columns,
    width: sampleImg.columns,
    height: axialImages.length,
    rgba: false,
    color: false,
    render: cornerstone.renderColorImage,
    columnPixelSpacing,
    rowPixelSpacing: (rowPixelSpacing * sampleImg.rows) / axialImages.length,
    invert: sampleImg.invert,
    windowCenter: sampleImg.windowCenter,
    windowWidth: sampleImg.windowWidth,
    sizeInBytes: sampleImg.sizeInBytes,
    intercept: sampleImg.intercept,
    slope: sampleImg.slope,
  };

  // TODO: deal with pixel padding and all of the various issues by setting it to min pixel value (or lower)
  // TODO: Mask out overlays embedded in pixel data above high bit

  var maxVoi =
    horizontalImg.maxPixelValue * horizontalImg.slope + horizontalImg.intercept;
  var minVoi =
    horizontalImg.minPixelValue * horizontalImg.slope + horizontalImg.intercept;
  horizontalImg.windowWidth = maxVoi - minVoi;
  horizontalImg.windowCenter = (maxVoi + minVoi) / 2;

  minMax = getMinMax(storedPixelsV);
  const formattedSagittal = new Uint16Array(storedPixelsV);
  var verticalImg = {
    imageId:
      seriesIndex.toString() +
      "." +
      x.toString() +
      "." +
      y.toString() +
      "." +
      "V",
    minPixelValue: minMax.min,
    maxPixelValue: minMax.max,
    slope: sampleImg.slope,
    rgba: true,
    view: "sagittal",
    intercept: sampleImg.intercept,
    windowWidth: maxVoi - minVoi,
    windowCenter: (maxVoi + minVoi) / 2,
    getPixelData: () => formattedSagittal,
    rows: axialImages.length,
    columns: sampleImg.rows,
    render: cornerstone.renderColorImage,
    getCanvas: sampleImg.getCanvas,
    height: axialImages.length,
    width: sampleImg.rows,
    color: false,
    columnPixelSpacing:
      (columnPixelSpacing * sampleImg.columns) / sampleImg.rows,
    rowPixelSpacing: (rowPixelSpacing * sampleImg.rows) / axialImages.length,
    invert: sampleImg.invert,
    sizeInBytes: sampleImg.sizeInBytes,
    frameOfReferenceUID: processedMetadata.frameOfReferenceUID,
    sliceThickness: processedMetadata.sliceThickness,
    sliceLocation: processedMetadata.sliceLocation,
    pixelRepresentation: processedMetadata.pixelRepresentation,
  };
  console.log(sampleImg);
  console.log(verticalImg);
  console.log(horizontalImg);
  const coronalImage = createImage(horizontalImg, metadata);
  return { coronalImages: coronalImage, sagittalImages: verticalImg };
};

/*

import cornerstone from "cornerstone-core";
import { metaData as metadata } from "@cornerstonejs/core";

// There are 4 items in a pixel - r,g,b,a
const PIXEL_UNIT_COUNT = 4;

const getMinMax = (storedPixelData) => {
  // we always calculate the min max values since they are not always
  // present in DICOM and we don't want to trust them anyway as cornerstone
  // depends on us providing reliable values for these
  var min = 65535;
  var max = -32768;
  var numPixels = storedPixelData.length;
  var pixelData = storedPixelData;
  for (var index = 0; index < numPixels; index++) {
    var spv = pixelData[index];
    // TODO: test to see if it is faster to use conditional here rather than calling min/max functions
    min = Math.min(min, spv);
    max = Math.max(max, spv);
  }

  return {
    min: min,
    max: max,
  };
};

function isModalityLUTForDisplay(sopClassUid) {
  // special case for XA and XRF
  // https://groups.google.com/forum/#!searchin/comp.protocols.dicom/Modality$20LUT$20XA/comp.protocols.dicom/UBxhOZ2anJ0/D0R_QP8V2wIJ
  return (
    sopClassUid !== "1.2.840.10008.5.1.4.1.1.12.1" && // XA
    sopClassUid !== "1.2.840.10008.5.1.4.1.1.12.2.1"
  ); // XRF
}
const createImage = (image, Metadata) => {
  const voiLutModule = Metadata.voiLutModule ?? {};
  const modalityLutModule = Metadata.modalityLutModule ?? {};
  const sopCommonModule = Metadata.sopCommonModule ?? {};
  // Modality LUT
  if (
    modalityLutModule.modalityLUTSequence &&
    modalityLutModule.modalityLUTSequence.length > 0 &&
    isModalityLUTForDisplay(sopCommonModule.sopClassUID)
  ) {
    image.modalityLUT = modalityLutModule.modalityLUTSequence[0];
  }
  // VOI LUT
  if (voiLutModule.voiLUTSequence && voiLutModule.voiLUTSequence.length > 0) {
    image.voiLUT = voiLutModule.voiLUTSequence[0];
  }
  return image;
};

export const reconstructImages = async (
  imagePoint,
  axialImages,
  seriesIndex,
  processedMetadata
) => {
  if (!axialImages || axialImages.length === 0) {
    return;
  }
  const [rowPixelSpacing, columnPixelSpacing] = String(
    processedMetadata.pixelSpacing
  )
    .split("\\")
    .map(parseFloat);
  var y = Math.round(imagePoint.y);
  var x = Math.round(imagePoint.x);
  let coronalPixels = [];
  let pixelNumV = 0;
  let pixelNum = 0;
  let storedPixelsV = [];
  const sampleImg = axialImages[0];
  for (let imageIndex = 0; imageIndex < axialImages.length; imageIndex += 1) {
    const currentImage = axialImages[imageIndex];
    const tmpPixelMatrix = currentImage.getPixelData();
    var unitNum = Math.round(
      tmpPixelMatrix.length / currentImage.columns / currentImage.rows
    );

    for (var column = 0; column < currentImage.columns; column++) {
      for (var index = 0; index < PIXEL_UNIT_COUNT; index++) {
        var spIndex =
          ((y - 1) * currentImage.columns + column) * unitNum + index;
        coronalPixels[pixelNum++] = tmpPixelMatrix[spIndex];
      }
    }

    for (var row = 0; row < currentImage.rows; row++) {
      for (var index = 0; index < unitNum; index++) {
        var spIndex = (row * currentImage.columns + x) * unitNum + index;
        storedPixelsV[pixelNumV++] = tmpPixelMatrix[spIndex];
      }
    }
  }

  var minMax = getMinMax(coronalPixels);
  const formattedCoronal = new Uint16Array(coronalPixels);
  // Extract the various attributes we need
  const horizontalImg = {
    imageId:
      seriesIndex.toString() +
      "." +
      x.toString() +
      "." +
      y.toString() +
      "." +
      "H",
    minPixelValue: minMax.min,
    maxPixelValue: minMax.max,
    getPixelData: () => formattedCoronal,
    rows: axialImages.length,
    columns: sampleImg.columns,
    width: sampleImg.columns,
    height: axialImages.length,
    render: cornerstone.renderColorImage,
    color: false,
    columnPixelSpacing,
    rowPixelSpacing: (rowPixelSpacing * sampleImg.rows) / axialImages.length,
    invert: sampleImg.invert,
    windowCenter: sampleImg.windowCenter,
    windowWidth: sampleImg.windowWidth,
    sizeInBytes: sampleImg.sizeInBytes,
    intercept: sampleImg.intercept,
    slope: sampleImg.slope,
  };

  // TODO: deal with pixel padding and all of the various issues by setting it to min pixel value (or lower)
  // TODO: Mask out overlays embedded in pixel data above high bit

  var maxVoi =
    horizontalImg.maxPixelValue * horizontalImg.slope + horizontalImg.intercept;
  var minVoi =
    horizontalImg.minPixelValue * horizontalImg.slope + horizontalImg.intercept;
  horizontalImg.windowWidth = maxVoi - minVoi;
  horizontalImg.windowCenter = (maxVoi + minVoi) / 2;

  minMax = getMinMax(storedPixelsV);
  var verticalImg = {
    imageId:
      seriesIndex.toString() +
      "." +
      x.toString() +
      "." +
      y.toString() +
      "." +
      "V",
    minPixelValue: minMax.min,
    maxPixelValue: minMax.max,
    slope: sampleImg.slope,
    rgba: true,
    view: "sagittal",
    intercept: sampleImg.intercept,
    windowWidth: maxVoi - minVoi,
    windowCenter: (maxVoi + minVoi) / 2,
    getPixelData: () => storedPixelsV,
    rows: axialImages.length,
    columns: sampleImg.rows,
    render: cornerstone.renderColorImage,
    height: axialImages.length,
    width: sampleImg.rows,
    color: false,
    columnPixelSpacing:
      (columnPixelSpacing * sampleImg.columns) / sampleImg.rows,
    rowPixelSpacing: (rowPixelSpacing * sampleImg.rows) / axialImages.length,
    invert: sampleImg.invert,
    sizeInBytes: sampleImg.sizeInBytes,
  };
  console.log(verticalImg);
  const coronalImage = createImage(horizontalImg, metadata);
  return { coronalImages: coronalImage, sagittalImages: verticalImg };
};
*/
