export const DEFAULT_FREE_HAND_AREA_CONFIG = {
  mouseLocation: {
    handles: {
      start: {
        highlight: true,
        active: true,
      },
    },
  },
  spacing: 1,
  activeHandleRadius: 3,
  completeHandleRadius: 6,
  completeHandleRadiusTouch: 28,
  alwaysShowHandles: false,
  invalidColor: "crimson",
  currentHandle: 0,
  currentTool: -1,
  drawHandles: true,
  renderDashed: false,
};
