import { useCallback } from "react";
import { TOOL_IDS } from "../consts/tools.consts";
import { ClearAnnotations } from "../default-tools/ClearAnnotations";
import { HFlip } from "../manipulation-tools/HFlip";
import { Invert } from "../manipulation-tools/Invert";
import { VFlip } from "../manipulation-tools/VFlip";
import { useSaveAnnotation } from "../default-tools/save-annotations/useSaveAnnotation";

export const useAutoPerform = () => {
  const saveAnnotations = useSaveAnnotation();
  return useCallback((selectedToolId: string, enabledElement) => {
    switch (selectedToolId) {
      case TOOL_IDS.HFLIP: {
        const hFlipTool = new HFlip();
        hFlipTool.autoPerform(enabledElement.element);
        break;
      }
      case TOOL_IDS.VFLIP: {
        const vFlipTool = new VFlip();
        vFlipTool.autoPerform(enabledElement.element);
        break;
      }
      case TOOL_IDS.INVERT: {
        const invertTool = new Invert();
        invertTool.autoPerform(enabledElement.element);
        break;
      }
      case TOOL_IDS.CLEAR_ANNOTATIONS: {
        const clearAnnotationsTool = new ClearAnnotations();
        clearAnnotationsTool.autoPerform(enabledElement);
        break;
      }
      case TOOL_IDS.SAVE_ANNOTATIONS:
        {
          saveAnnotations(enabledElement);
        }
        break;
      default:
        console.warn(
          `no auto perform has been configured for ${selectedToolId}`
        );
    }
  }, []);
};
