import { useCallback, useMemo, useState } from "react";
import { REPORT_TYPES } from "../../studyTableRows.consts";
import { FIELD_KEYS } from "../../../study-table-columns/columns.consts";
import { createReport } from "services/google-docs/googleDocs";
import { useAppContext } from "context/app.context";
import { StudyData } from "../../../studyTable.types";
import { Spinner } from "react-bootstrap";

export const GoogleDocController = ({ reportType, studyData }) => {
  const { iconUrl, isLoading, createReportOnClick, openReport, reportId } =
    useGoogleControllerProps(reportType, studyData);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <img
      title={`${reportId ? "edit" : "add"} ${reportType} report`}
      src={iconUrl}
      height="30"
      style={{
        cursor: "pointer",
        filter: "drop-shadow(3px 3px 1px rgb(0 0 0 / 0.1)",
      }}
      onClick={reportId ? openReport : createReportOnClick}
    />
  );
};

const useGoogleControllerProps = (reportType, studyData: StudyData) => {
  const initialReportId = useMemo(() => {
    if (
      reportType === REPORT_TYPES.REFERRAL &&
      studyData[FIELD_KEYS.REFERRAL_REPORT_IDS]
    ) {
      return studyData[FIELD_KEYS.REFERRAL_REPORT_IDS];
    } else if (
      reportType === REPORT_TYPES.MEDICAL &&
      studyData[FIELD_KEYS.MEDICAL_REPORT_IDS]
    ) {
      return studyData[FIELD_KEYS.MEDICAL_REPORT_IDS];
    }
    return null;
  }, [studyData, reportType]);

  const [reportId, setReportId] = useState<string | null>(initialReportId);
  const iconUrl = useMemo(() => {
    const BASE_URL = "../assets/images/icons/table-icons/";
    const addOrEdit = reportId ? "" : "add-";

    switch (reportType) {
      case REPORT_TYPES.MEDICAL:
        return `${BASE_URL}${addOrEdit}medical-report-icon.svg`;
      case REPORT_TYPES.REFERRAL:
      default:
        return `${BASE_URL}${addOrEdit}referral-report-icon.svg`;
    }
  }, [reportType, reportId]);

  const {
    state: { userId },
  } = useAppContext();

  const openReport = useCallback(() => {
    window.open(
      `https://docs.google.com/document/d/${reportId}/edit`,
      "_blank"
    );
  }, [reportId]);

  const [isLoading, setIslLoading] = useState<boolean>(false);
  const createReportOnClick = useCallback(async () => {
    setIslLoading(true);
    const reportId = await createReport(studyData, reportType, userId);
    setReportId(reportId);
    setIslLoading(false);
  }, [reportType, studyData, userId, setReportId, setIslLoading]);

  return { iconUrl, createReportOnClick, openReport, reportId, isLoading };
};
