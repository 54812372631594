//@ts-nocheck
import Util from "../api/Util";
import cornerstoneMath from "cornerstone-math";

export const getCircleCoords = (startHandle, endHandle) => {
  const { distance } = cornerstoneMath.point;
  const radius = distance(startHandle, endHandle); //This line gives error on moving

  return {
    left: Math.floor(Math.min(startHandle.x - radius, endHandle.x)),
    top: Math.floor(Math.min(startHandle.y - radius, endHandle.y)),
    width: radius * 2,
    height: radius * 2,
  };
};

export const calculateEllipseStats = (
  image,
  element,
  start,
  end,
  modality,
  rowPixelSpacing,
  colPixelSpacing
) => {
  // Retrieve the bounds of the ellipse in image coordinates
  const circleCoordinates = getCircleCoords(start, end);

  // TODO - this may be incorrect
  const ellipseMeanStdDev = { mean: 0, stdDev: 0 };

  let meanStdDevSUV;

  if (modality === "PT") {
    meanStdDevSUV = {
      mean: Util.calculateSUV(image, ellipseMeanStdDev.mean, true) || 0,
      stdDev: Util.calculateSUV(image, ellipseMeanStdDev.stdDev, true) || 0,
    };
  }

  const radius = (circleCoordinates.width * (colPixelSpacing || 1)) / 2;
  const perimeter = 2 * Math.PI * radius;
  const area =
    Math.PI *
    ((circleCoordinates.width * (colPixelSpacing || 1)) / 2) *
    ((circleCoordinates.height * (rowPixelSpacing || 1)) / 2);

  return {
    area: area || 0,
    radius: radius || 0,
    perimeter: perimeter || 0,
    count: ellipseMeanStdDev.count || 0,
    mean: ellipseMeanStdDev.mean || 0,
    variance: ellipseMeanStdDev.variance || 0,
    stdDev: ellipseMeanStdDev.stdDev || 0,
    min: ellipseMeanStdDev.min || 0,
    max: ellipseMeanStdDev.max || 0,
    meanStdDevSUV,
  };
};

export const getUnit = (
  modality: string,
  showHounsfieldUnits: boolean = false
) => {
  return modality === "CT" && showHounsfieldUnits !== false ? "HU" : "";
};
