import { useCallback, useEffect, useState } from "react";
import { StudyData } from "../../../studyTable.types";
import { FIELD_KEYS } from "../../../study-table-columns/columns.consts";
import { formatValue } from "utils/formatting/formatValue";

export const useTableItemDisplayData = (
  studyData: StudyData,
  fieldName: string
) => {
  const [displayData, setDisplayData] = useState<string>("");

  const updateDisplayData = useCallback(
    (updatedValues?: Partial<StudyData>) => {
      const mergedStudyData = { ...studyData, ...updatedValues };
      switch (fieldName) {
        case FIELD_KEYS.MEDICARE_NUMBER:
          setDisplayData(
            formatMedicareDisplayValue(
              mergedStudyData.medicareNumber,
              mergedStudyData.irnNumber
            )
          );
          break;
        case FIELD_KEYS.SERVICES:
          setDisplayData(mergedStudyData.services ?? "Unknown");

          break;
        case FIELD_KEYS.PAT_BIRTHDATE:
          setDisplayData(
            formatValue(mergedStudyData.patBirthdate, FIELD_KEYS.PAT_BIRTHDATE)
          );
          break;
        case FIELD_KEYS.PAT_NAME:
          setDisplayData(
            formatValue(mergedStudyData.patName, FIELD_KEYS.PAT_NAME)
          );
          break;
        default:
          console.warn(`No value configured for ${fieldName}`);
          break;
      }
    },
    [studyData, fieldName, setDisplayData]
  );

  useEffect(() => {
    updateDisplayData();
  }, [updateDisplayData]);

  return { displayData, updateDisplayData };
};

const formatMedicareDisplayValue = (medicareNumber, irnNumber) =>
  medicareNumber || irnNumber ? `${medicareNumber} ${irnNumber}` : "Unknown";
