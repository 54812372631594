import { motion } from "framer-motion";
import { StudyTable } from "./components/study-table/StudyTable";
import { HomePageProvider } from "./context/homepage.context";
import { useAuth } from "hooks/useAuth";
import { AppHeader } from "components/header/AppHeader";
import { StudyTableControls } from "./components/study-table-controls/StudyTableControls";
import { useSetGoogleToken } from "hooks/useSetGoogleToken";
import { useCanDisplaySettings } from "hooks/useCanDisplaySettings";
import { SettingsPanel } from "pages/settings/SettingsPanel";

export const HomePage = () => {
  useAuth();
  useSetGoogleToken();
  useCanDisplaySettings();
  return (
    <HomePageProvider>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.25 }}
        exit={{ opacity: 0 }}
      >
        <AppHeader />
        <StudyTableControls />
        <StudyTable />
        <SettingsPanel />
      </motion.div>
    </HomePageProvider>
  );
};
