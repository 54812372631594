import { useNavigate } from "react-router-dom";
import { useAppContext } from "context/app.context";
import { useUserType } from "hooks/useUserType";
import { useCallback } from "react";
import { Fab } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useAuth } from "hooks/useAuth";
import { CustomFab } from "components/buttons/CustomFab";

export const LogoutButton = () => {
  const navigation = useNavigate();
  const { userTypeColour } = useUserType();
  const { dispatch } = useAppContext();
  const { resetAuthData } = useAuth();

  const logout = useCallback(() => {
    resetAuthData();
    dispatch({ type: "LOGOUT" });
    navigation("/login");
  }, [dispatch, resetAuthData]);

  return (
    <CustomFab ariaLabel="logout" onClick={logout}>
      <>
        <Logout style={{ marginRight: "5px" }} /> LOGOUT
      </>
    </CustomFab>
  );
};
