import { Typography } from "@mui/material";
import { REQUIRED } from "components/form/form.consts";

interface FieldLabelProps {
  isRequired: boolean;
  label: string;
}
export const FieldLabel = ({ label, isRequired }: FieldLabelProps) => (
  <Typography>
    {label} {isRequired ? <span>{REQUIRED}</span> : ""}
  </Typography>
);
