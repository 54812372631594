import { Box } from "@mui/material";
import { DarkModeSwitch } from "components/DarkmodeSwitch/DarkmodeSwitch";
import { useUserType } from "hooks/useUserType";
import { UserInfo } from "./components/UserInfo";
import { LogoutButton } from "./components/LogoutButton";
import { HomeIcon } from "./components/HomeIcon";

export const AppHeader = () => {
  const { userTypeColour } = useUserType();
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          color: userTypeColour,
          padding: "1rem",
        }}
      >
        <HomeIcon /> iTX Cloud PACS
      </h1>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifySelf: "flex-end",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <UserInfo />
        <LogoutButton />
        <DarkModeSwitch />
      </Box>
    </Box>
  );
};
