import JSZip from "jszip";
import { saveAs } from "file-saver";

export const downloadAsZip = async (files, zipName) => {
  const zip = new JSZip();
  // Adding files to the zip
  files.forEach((file) => {
    zip.file(file.name, file.content);
  });
  // Generating the zip file and initiating the download
  const blob = await zip.generateAsync({ type: "blob" });
  saveAs(blob, zipName);
};
