import { StudyData } from "pages/home-page/components/study-table/studyTable.types";
import {
  decodeDateString,
  decodeNameFromHL7,
  decodeStudyDate,
} from "utils/formatting/decodeHL7";
import { REPORT_TYPES } from "pages/home-page/components/study-table/study-table-rows/studyTableRows.consts";
import secureLocalStorage from "react-secure-storage";
import {
  DOCTOR_SIGNATURE_HEIGH,
  DOCTOR_SIGNATURE_WIDTH,
  REPORT_BODY,
} from "./googleDocs.consts";
import {
  getRadiologistSignatureUri,
  getPatientDetails,
  getReportingDoctor,
} from "./documentFormatting";
import { FIELD_KEYS } from "pages/home-page/components/study-table/study-table-columns/columns.consts";
import { toast } from "react-toastify";
import { RadiologySite } from "models/study.types";
import { setUpDocumentHeader } from "./setup-document-header/setUpDocumentHeader";
import { setUpDocumentFooter } from "./set-up-document-footer/setUpDocumentFooter";
import {
  getInstitutionTemplate,
  updateStudyValues,
} from "services/studies/studies";

const getPatientParameters = (studyData: StudyData) => {
  const {
    studyDatetime,
    patName,
    patBirthdate,
    patSex,
    medRepIds,
    refRepIds,
    physician,
    institution,
    patId,
  } = studyData;

  const formattedData = {
    studyDate: decodeStudyDate(studyDatetime),
    patientName: decodeNameFromHL7(patName),
    patientId: patId,
    institution,
    patientSex: patSex,
    patientDob: decodeDateString(patBirthdate),
    referringDoctor: decodeNameFromHL7(physician),
    medRepIds,
    refRepIds,
  };

  return formattedData;
};

const setUpReportTemplate = (patientData, reportType) => {
  const { patientName, patientId, institution, medRepIds, refRepIds } =
    patientData;
  // The original code had no differentiation - in future if we need to build that out we can
  return {
    reportTemplateBody: {},
    fileName: `Report for ${patientName}  - Patient ID ${patientId} - ${institution}`,
    existingReportId:
      reportType === REPORT_TYPES.MEDICAL
        ? medRepIds ?? null
        : refRepIds ?? null,
  };
};

const setUpHeaderAndFooter = async (
  documentId: string,
  accessToken: string
) => {
  const result = await fetch(
    "https://docs.googleapis.com/v1/documents/" + documentId + ":batchUpdate",
    {
      method: "POST",
      headers: new Headers({ Authorization: "Bearer " + accessToken }),
      body: JSON.stringify({
        requests: [
          {
            createHeader: {
              type: "DEFAULT",
              sectionBreakLocation: { index: 0 },
            },
          },
          { createFooter: { type: "DEFAULT" } },
        ],
      }),
    }
  );

  if (!result.ok) {
    toast.error(`Error creating new google document ${result.status}`);
    console.error(
      "Error creating document header and footer. HTTP status code"
    );
  }
  const responseData = await result.json();
  const { headerId } = responseData.replies[0].createHeader;
  const { footerId } = responseData.replies[1].createFooter;
  return { headerId, footerId };
};

const setUpReportParameters = (
  reportType,
  patientData,
  siteInfo: RadiologySite | null,
  headerId,
  footerId
) => {
  const { studyDate, patientName, patientDob, patientSex, institution } =
    patientData;
  if (reportType === REPORT_TYPES.REFERRAL) {
    return {
      requests: [
        {
          insertText: {
            location: {
              segmentId: headerId,
              index: 0,
            },
            text:
              studyDate +
              " " +
              patientName.toUpperCase() +
              " " +
              patientDob +
              " " +
              patientSex,
          },
        },
      ],
    };
  } else {
    const patientDetails = getPatientDetails(patientData);

    const reportingDoctor = getReportingDoctor(siteInfo);
    const patientDetailsIndex = 1;
    const reportBodyIndex = patientDetailsIndex + patientDetails.length;

    const headerDetails = setUpDocumentHeader(siteInfo, headerId, institution);
    const footerDetails = setUpDocumentFooter(footerId);
    const doctorSignatureUri = getRadiologistSignatureUri(siteInfo);
    const doctorSignatureIndex = reportBodyIndex + REPORT_BODY.length;
    const reportingDoctorIndex = doctorSignatureIndex + 1;
    return {
      requests: [
        ...headerDetails,

        {
          insertText: {
            location: {
              index: patientDetailsIndex,
            },
            text: patientDetails,
          },
        },
        {
          updateTextStyle: {
            textStyle: {
              fontSize: {
                magnitude: 12,
                unit: "PT",
              },
              weightedFontFamily: {
                fontFamily: "Cambria",
              },
            },
            fields: "fontSize, weightedFontFamily",
            range: {
              startIndex: patientDetailsIndex,
              endIndex: patientDetailsIndex + patientDetails.length,
            },
          },
        },
        {
          insertText: {
            location: {
              index: reportBodyIndex,
            },
            text: REPORT_BODY,
          },
        },
        {
          updateTextStyle: {
            textStyle: {
              bold: true,
              underline: true,
            },
            fields: "bold, underline", //"bold",
            range: {
              startIndex: reportBodyIndex + 2,
              endIndex: reportBodyIndex + 2 + 20,
            },
          },
        },
        {
          updateTextStyle: {
            textStyle: {
              bold: true,
              underline: true,
            },
            fields: "bold, underline", //"bold",
            range: {
              startIndex: reportBodyIndex + 2 + 20 + 2,
              endIndex: reportBodyIndex + 2 + 20 + 2 + 20,
            },
          },
        },
        {
          updateTextStyle: {
            textStyle: {
              bold: true,
              underline: true,
            },
            fields: "bold, underline", //"bold",
            range: {
              startIndex: reportBodyIndex + 2 + 20 + 2 + 20 + 2,
              endIndex: reportBodyIndex + 2 + 20 + 2 + 20 + 2 + 50,
            },
          },
        },
        {
          insertInlineImage: {
            uri: doctorSignatureUri,
            objectSize: {
              height: {
                magnitude: DOCTOR_SIGNATURE_HEIGH,
                unit: "PT",
              },
              width: {
                magnitude: DOCTOR_SIGNATURE_WIDTH,
                unit: "PT",
              },
            },
            location: {
              index: doctorSignatureIndex,
            },
          },
        },
        {
          insertText: {
            location: {
              index: reportingDoctorIndex,
            },
            text: reportingDoctor,
          },
        },
        {
          updateTextStyle: {
            textStyle: {
              bold: true,
            },
            fields: "bold",
            range: {
              startIndex: reportingDoctorIndex,
              endIndex: reportingDoctorIndex + reportingDoctor.length,
            },
          },
        },
        ...footerDetails,
      ],
    };
  }
};

const setUpContentAndStyles = async (
  reportType: string,
  patientData,
  siteInfo: RadiologySite | null,
  headerId: string,
  footerId: string,
  documentId: string,
  accessToken: string
) => {
  const reportParameters = setUpReportParameters(
    reportType,
    patientData,
    siteInfo,
    headerId,
    footerId
  );
  //Populate and style the document
  //Refer to https://developers.google.com/docs/api/reference/rest/v1/documents/request
  const styleAndFormattingResult = await fetch(
    "https://docs.googleapis.com/v1/documents/" + documentId + ":batchUpdate",
    {
      method: "POST",
      headers: new Headers({ Authorization: "Bearer " + accessToken }),
      body: JSON.stringify(reportParameters),
    }
  );
  if (!styleAndFormattingResult.ok) {
    toast.error(
      `Error creating new document ${styleAndFormattingResult.status}`
    );
    console.error("Error with style and formatting");
  }
  const result = await styleAndFormattingResult.json();
  return result;
};

const updateRefIds = async (studyData: StudyData, reportType, documentId) => {
  const { studypk } = studyData;
  const fieldName =
    reportType === REPORT_TYPES.MEDICAL
      ? FIELD_KEYS.MEDICAL_REPORT_IDS
      : FIELD_KEYS.REFERRAL_REPORT_IDS;
  const result = await updateStudyValues(studypk, { [fieldName]: documentId });
  return result;
};

const setDocumentPermissions = async (
  documentId: string,
  accessToken: string
) => {
  const PERMISSIONS = {
    type: "anyone",
    role: "writer",
  };

  const result = await fetch(
    "https://www.googleapis.com/drive/v3/files/" + documentId + "/permissions",
    {
      method: "POST",
      headers: new Headers({ Authorization: "Bearer " + accessToken }),
      body: JSON.stringify(PERMISSIONS),
    }
  );
  //Open the document in a new tab - CHECK THE BROWSER ALLOWS POPUPS FROM THIS SOURCE
  if (result.ok) {
    window.open(
      "https://docs.google.com/document/d/" + documentId + "/edit",
      "_blank"
    );
  }
};

export const createReport = async (
  studyData: StudyData,
  reportType,
  userId
) => {
  const patientParameters = getPatientParameters(studyData);
  const { reportTemplateBody, fileName, existingReportId } =
    setUpReportTemplate(patientParameters, reportType);

  const savedGoogleAccessToken = secureLocalStorage.getItem(
    "googleAccessToken"
  ) as { googleAccessToken: string };

  if (savedGoogleAccessToken && savedGoogleAccessToken.googleAccessToken) {
    const { googleAccessToken } = savedGoogleAccessToken;
    const createDocumentResult = await fetch(
      "https://docs.googleapis.com/v1/documents?title=" + fileName,
      {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + googleAccessToken,
        }),
      }
    );
    if (!createDocumentResult.ok) {
      toast.error(`Error creating new document ${createDocumentResult.status}`);
      console.error("Error creating new document: ");
    }
    const responseData = await createDocumentResult.json();

    const { documentId } = responseData;
    const { headerId, footerId } = await setUpHeaderAndFooter(
      documentId,
      googleAccessToken
    );
    const siteInfo = await getInstitutionTemplate(
      patientParameters.institution
    );
    await setUpContentAndStyles(
      reportType,
      patientParameters,
      siteInfo,
      headerId,
      footerId,
      documentId,
      googleAccessToken
    );
    const updatedRefIds = await updateRefIds(studyData, reportType, documentId);
    setDocumentPermissions(documentId, googleAccessToken);
    return documentId;
  } else {
    window.location.href = "/google-connect";
  }
};

// CREATE a new document (first creates a blank document for the user, with a unique id, then can update it.. )
//Refer to https://developers.google.com/docs/api/reference/rest/v1/documents/create
