import { FormattedHandlesData } from "./gonstedTool.types";

export const formatHandles = (currentImagePoints: {
  x: number;
  y: number;
}): FormattedHandlesData => {
  return {
    visible: true,
    active: true,
    color: undefined,
    invalidated: true,
    complete: false,
    value: "",
    handles: {
      rightFemurHeadHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "R Femur Head",
        placed: true, // True because this is the first item to be placed
        insideImage: true,
      },
      leftFemurHeadHandle: {
        ...currentImagePoints,
        highlight: true,
        active: true,
        label: "L Femur Head",
        placed: false,
        insideImage: true,
      },
      rightIliacCrestHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "R Iliac Crest",
        placed: false,
        insideImage: true,
      },
      rightIschialTuberosityHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "R Ischial Tuberosity",
        placed: false,
        insideImage: true,
      },
      leftIliacCrestHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "L Iliac Crest",
        placed: false,
        insideImage: true,
      },
      leftIschialTuberosityHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "L Ischial Tuberosity",
        placed: false,
        insideImage: true,
      },
      s2TubercleHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "S2 Tubercle",
        placed: false,
        insideImage: true,
      },
      pubicSymphysisHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "Pubic Symphysis",
        placed: false,
        insideImage: true,
      },
      rightS1FacetBaseHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "R S1 Facet Base",
        placed: false,
        insideImage: true,
      },
      leftS1FacetBaseHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "L S1 Facet Base",
        placed: false,
        insideImage: true,
      },
      rightSacralWingHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "R Sacral Wing",
        placed: false,
        insideImage: true,
      },
      leftSacralWingHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "L Sacral Wing",
        placed: false,
        insideImage: true,
      },
      rightIliacWingHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "R Iliac Wing",
        placed: false,
        insideImage: true,
      },
      leftIliacWingHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "L Iliac Wing",
        placed: false,
        insideImage: true,
      },
      rightPsisHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "R PSIS",
        placed: false,
        insideImage: true,
      },
      leftPsisHandle: {
        ...currentImagePoints,
        highlight: true,
        active: false,
        label: "L PSIS",
        placed: false,
        insideImage: true,
      },

      rightFemurHeadTextBox: {
        handleKey: "rightFemurHeadHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: true,
        insideImage: true,
      },
      leftFemurHeadTextBox: {
        handleKey: "leftFemurHeadHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      rightIliacCrestTextBox: {
        handleKey: "rightIliacCrestHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      rightIschialTuberosityTextBox: {
        handleKey: "rightIschialTuberosityHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      leftIliacCrestTextBox: {
        handleKey: "leftIliacCrestHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      leftIschialTuberosityTextBox: {
        handleKey: "leftIschialTuberosityHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      s2TubercleTextBox: {
        handleKey: "s2TubercleHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      pubicSymphysisTextBox: {
        handleKey: "pubicSymphysisHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      rightS1FacetBaseTextBox: {
        handleKey: "rightS1FacetBaseHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      leftS1FacetBaseTextBox: {
        handleKey: "leftS1FacetBaseHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      rightSacralWingTextBox: {
        handleKey: "rightSacralWingHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      leftSacralWingTextBox: {
        handleKey: "leftSacralWingHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      rightIliacWingTextBox: {
        handleKey: "rightIliacWingHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      leftIliacWingTextBox: {
        handleKey: "leftIliacWingHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      rightPsisTextBox: {
        handleKey: "rightPsisHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      leftPsisTextBox: {
        handleKey: "leftPsisHandle",
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },

      //Measurement Handles
      textBoxMD: {
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      textBox_iliacCrestRLine_ischialTuberosityRLine: {
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      textBox_iliacCrestLLine_ischialTuberosityLLine: {
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      textBox_S2TubercleLine_pubicSymphysisLine: {
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      textBox_rightSacralWingOnSbl_s2TubercleOnSbl: {
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      textBox_leftSacralWingOnSbl_s2TubercleOnSbl: {
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      textBox_rightIliacWingLine_rightPSISLine: {
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
      textBox_leftIliacWingLine_leftPSISLine: {
        active: false,
        hasMoved: false,
        movesIndependently: true,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
        placed: false,
        insideImage: true,
      },
    },
    prePlacementHandles: {
      handle: {
        ...currentImagePoints,
        highlight: true,
        active: true,
        label: "",
        placed: false,
        insideImage: true,
      },
    },
  };
};
