import { FORM_FIELDS } from "components/form/form.consts";

export const STATUS_OPTIONS = [
  { label: "Unread", value: "Unread" },
  { label: "Billed", value: "Billed" },
  { label: "Privately Billed", value: "Privately Billed" },
  { label: "Ready to report", value: "Ready to report" },
  { label: "Dictated", value: "Dictated" },
  { label: "Typed", value: "Typed" },
  { label: "Reported", value: "Reported" },
  { label: "Completed", value: "Completed" },
  { label: "Emergency", value: "Emergency" },
  { label: "Missing image(s)", value: "Missing images(s)" },
  { label: "Missing referral", value: "Missing referral" },
  { label: "Action required", value: "Action required" },
];

export const STATUS_IDS = [
  { value: "Unread", id: 0 },
  { value: "Billed", id: 5 },
  { value: "Privately Billed", id: 7 },
  { value: "Ready to report", id: 1 },
  { value: "Dictated", id: 8 },
  { value: "Typed", id: 9 },
  { value: "Reported", id: 2 },
  { value: "Completed", id: 3 },
  { value: "Emergency", id: 4 },
  { value: "Missing image(s)", id: 11 },
  { value: "Missing referral", id: 12 },
  { value: "Action required", id: 13 },
];

export const MEDICARE_FORM = {
  medicareNumber: {
    label: "Medicare Number",
    required: true,
    maxLength: 10,
    numericOnly: true,
  },
  irnNumber: {
    label: "IRN",
    required: true,
    numericOnly: true,
  },
};

export const SERVICES_FORM = {
  services: {
    label: "Services",
    required: true,
  },
};

export const PATIENT_NAME_FORM = {
  prefix: {
    label: "Prefix",
  },
  firstName: {
    label: "First Name",
    required: true,
  },
  middleName: {
    label: "Middle Name",
  },
  lastName: {
    label: "Last Name",
    required: true,
  },
  suffix: {
    label: "Suffix",
  },
};

export const DOB_FORM = {
  dob: {
    label: "DOB",
    inputType: FORM_FIELDS.DATE,
    required: true,
  },
};

export const REPORT_TYPES = {
  REFERRAL: "referral",
  MEDICAL: "medical",
};
