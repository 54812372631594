const encodeDateToHL7 = (input) => {
  if (input === null || input === undefined || input.length < 8) {
    return "";
  }
  if (input.length == 10) {
    return input.slice(0, 4) + input.slice(5, 7) + input.slice(8, 10);
  } else {
    return "";
  }
};

export const encodeDateToHL7Date = (date: Date) => {
  if (!date) return "";

  try {
    // Define the time zone for Australia/Sydney
    const timeZone = "Australia/Sydney";

    // Format the date to Australian Eastern Time (AEST/AEDT)
    const options = {
      timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    // @ts-ignore
    const formatter = new Intl.DateTimeFormat("en-AU", options);
    const parts = formatter.formatToParts(date);

    const year = parts.find((part) => part.type === "year")?.value;
    const month = parts.find((part) => part.type === "month")?.value;
    const day = parts.find((part) => part.type === "day")?.value;

    if (year && month && day) {
      return `${year}${month}${day}`;
    } else {
      return "";
    }
  } catch (error) {
    console.error("Error encoding date to HL7 format:", error);
    return "";
  }
};
