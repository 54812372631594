// @ts-nocheck
import { Typography, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faBone } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import HL7 from "../../../api/hl7";

import { HomeIcon } from "../../../components/header/components/HomeIcon";
import { XCaliberGuideDialog } from "./XCaliberGuideDialog";
import { LogoutButton } from "components/header/components/LogoutButton";

const XCaliberHeader = (props) => {
  //Change Logo Based on Type
  let logo = props.logo;
  let colourValue = props.colourValue;

  //Submit button variables
  let submitButtonBackground = props.submitButtonBackground;
  let submitButtonColor = props.submitButtonColor;
  let submitButtonText = props.submitButtonText;
  let onSubmitButtonClick = props.onSubmitButtonClick;
  let submitButtonDisabled = props.submitButtonDisabled;
  let infoText = props.infoText;
  let submissionInProgress = props.submissionInProgress;

  //Measure Heights of the two containers
  const [containerHeights, setContainerHeights] = useState({});
  const [parentHeight, setParentHeight] = useState({});
  const [spinnerDimensions, setSpinnerDimensions] = useState({});

  const updateDimensions = () => {
    // Calculate and set the heights dynamically
    const firstContainer = document.getElementById("patient-container");
    const secondContainer = document.getElementById("xcaliber-container");
    const parentContainer = document.getElementById("parent-container");
    //const submitButton = document.getElementById('submit-button');
    const spinnerContainer = document.getElementById("spinner-icon");

    if (firstContainer && secondContainer && parentContainer) {
      // Reset heights to 'fit-content'
      setContainerHeights({ height: "fit-content" });
      setParentHeight({ height: "fit-content" });

      const firstContainerHeight = firstContainer.clientHeight;
      const secondContainerHeight = secondContainer.clientHeight;
      //const submitButtonHeight =  submitButton.clientHeight;

      const maxHeight = Math.max(firstContainerHeight, secondContainerHeight); //, submitButtonHeight);

      // Calculate the minimum height (150px)
      const minHeight = 150;

      //const maxHeight = Math.max(firstContainer.clientHeight, secondContainer.clientHeight);
      setContainerHeights({ height: `${Math.max(minHeight, maxHeight)}px` }); //setContainerHeights({ height: `${maxHeight}px` });

      const parentTop = parentContainer.offsetTop;
      const childTop = firstContainer.offsetTop;
      const minParentHeight =
        childTop + Math.max(minHeight, maxHeight) - parentTop; //const minParentHeight = childTop + maxHeight - parentTop;
      setParentHeight({ height: `${minParentHeight}px` });

      if (spinnerContainer) {
        //////////////////////////SPINNER DIMENSIONS/////////////////////////////////
        //Reset dimensions
        const secondContainerBoundingBox =
          secondContainer.getBoundingClientRect();
        const secondContainerLeft = secondContainerBoundingBox.left;
        const secondContainerTop = secondContainerBoundingBox.top;
        const secondContainerWidth = secondContainer.clientWidth;
        const newSecondContainerHeight = containerHeights.height; //secondContainer.clientHeight;
        //setSpinnerLeft({ left: secondContainerLeft });
        //setSpinnerWidth({ width: secondContainerWidth });

        //Calculate new dimensions
        const scale = 0.5;
        const newSpinnerWidth = scale * Math.max(minHeight, maxHeight);
        const newSpinnerLeft =
          secondContainerLeft +
          0.5 * secondContainerWidth -
          0.5 * newSpinnerWidth;
        const newSpinnerHeight = scale * Math.max(minHeight, maxHeight);
        const newSpinnerTop =
          secondContainerTop +
          0.5 * Math.max(minHeight, maxHeight) -
          0.5 * newSpinnerHeight +
          0.2 * Math.max(minHeight, maxHeight);

        //Set the new dimensions
        setSpinnerDimensions({
          left: newSpinnerLeft,
          width: newSpinnerWidth,
          height: newSpinnerHeight,
          top: newSpinnerTop,
        });
      }
    }
  };

  useEffect(() => {
    updateDimensions();
  }, [submitButtonDisabled, submissionInProgress]);

  //Effect for initial heights calculation and resize event listener
  useEffect(() => {
    // Initial heights calculation
    //updateDimensions();

    //To dynamically adjust the heights when the user changes the browser windown's shape and size
    // Initial heights calculation
    updateDimensions();
    // Add event listener for window resize
    window.addEventListener("resize", updateDimensions);
    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return (
    <Container
      id="parent-container"
      style={{ maxWidth: "100%", ...parentHeight }}
    >
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1
          style={{
            position: "absolute",
            textAlign: "center",
            top: "4.25%",
            left: "42.5%",
            color: colourValue,
          }}
        >
          <HomeIcon /> iTX Cloud PACS
        </h1>
        <Link
          to="/login"
          style={{
            position: "absolute",
            top: "2.5%",
            right: "2.5%",
            textDecoration: "none",
          }}
        >
          <LogoutButton />
        </Link>
        <Link
          to="/home"
          style={{
            position: "absolute",
            top: "2.5%",
            left: "2.5%",
            textDecoration: "none",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: colourValue,
              color: "white",
              borderRadius: "24px",
              borderColor: colourValue,
            }}
          >
            BACK{" "}
            <FontAwesomeIcon
              icon={faBackward}
              style={{ marginLeft: "15px" }}
            ></FontAwesomeIcon>
          </Button>
        </Link>
        <Container
          id="patient-container"
          style={{
            background: "#e3e3e3",
            position: "absolute",
            width: "15%",
            borderRadius: "5px",
            top: "2.5%",
            left: "10%",
            padding: "12.5px",
            ...containerHeights,
          }}
        >
          <Typography variant="subtitle2">
            Patient Name: {HL7.getPatientName(props.name)}
          </Typography>
          <Typography variant="subtitle2">Patient ID: {props.id}</Typography>
          <Typography variant="subtitle2">
            Birthday: {HL7.getBirthday(props.birthday)}
          </Typography>
          <Typography variant="subtitle2">Sex: {props.sex}</Typography>
        </Container>
        <Container
          id="xcaliber-container"
          style={{
            background: "#e3e3e3",
            position: "absolute",
            width: "15%",
            borderRadius: "5px",
            top: "2.5%",
            left: "26%",
            padding: "12.5px",
            textAlign: "left",
            ...containerHeights,
          }}
        >
          <Typography variant="subtitle2">
            <b>
              <span style={{ fontSize: "22px", color: colourValue }}>
                XCaliber AI
              </span>
            </b>
            <p style={{ margin: "0" }}>
              <select
                id="dropdown"
                value={props.selectedDropdown}
                onChange={props.onDropdownChange}
                style={{ display: "inline-block", fontSize: "14px" }}
              >
                <option value="01|vet_vhs">DOG VHS</option>
                <option value="01|vet_msk">DOG MSK</option>
                <option value="01|vet_thorax">DOG THORAX</option>
                <option value="01|vet_canine_abdomen">DOG ABDOMEN</option>
                <option value="02|vet_vhs">CAT VHS</option>
                <option value="02|vet_feline_thorax">CAT THORAX</option>
                <option value="02|vet_feline_abdomen">CAT ABDOMEN</option>
              </select>
            </p>
            <p style={{ margin: "0" }}>
              {submissionInProgress ? "Processing..." : infoText}
            </p>
            <XCaliberGuideDialog />
          </Typography>

          <div
            style={{
              position: "absolute",
              top: "24%",
              right: "10%",
              padding: "5px",
              boxSizing: "border-box",
              textAlign: "right",
            }}
          ></div>

          <Button
            id="submit-button"
            style={{
              backgroundColor: submitButtonBackground,
              color: submitButtonColor,
              borderRadius: "24px",
              borderColor: colourValue,
              display: "block",
              margin: "0 auto",
              marginTop: "10px",
            }}
            variant="contained"
            onClick={onSubmitButtonClick}
            disabled={submitButtonDisabled}
          >
            {submitButtonText}
          </Button>
        </Container>
        {submissionInProgress ? (
          <FontAwesomeIcon
            id="spinner-icon"
            icon={faBone}
            style={{
              position: "absolute",
              ...spinnerDimensions,
              animation: "spin 1s linear infinite", // Apply the spinning animation
              color: colourValue,
            }}
          />
        ) : null}
      </Grid>
    </Container>
  );
};

export default XCaliberHeader;
