import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useSavedRoute = () => {
  const location = useLocation();
  const [savedRoute, setSavedRoute] = useState<string | null>(null);
  useEffect(() => {
    if (location.state) {
      const {
        state: { redirectUrl },
      } = location || { state: {} };
      if (!redirectUrl) {
        setSavedRoute(null);
      } else {
        if (redirectUrl.indexOf("login") === -1) {
          const routeWithoutRoot = redirectUrl.replace(
            window.location.origin,
            ""
          );

          setSavedRoute(routeWithoutRoot);
        }
      }
    }
  }, [location, setSavedRoute]);
  return savedRoute;
};
