// @ts-nocheck

import { getColourByUserType } from "./getColorByUserType";

export function setFaviconAndBackgroundColors(userType) {
  //Get favicon based on userType
  const favicon = document.querySelector("[rel=icon]");
  switch (userType) {
    case "medical": {
      favicon.href = "main.ico";
      //document.body.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%237F1C53'/%3E%3Cstop offset='1' stop-color='%237F1C53'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%237F1C53'/%3E%3Cstop offset='1' stop-color='%237F1C53'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
      //document.body.style.backgroundImage = `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><path id='curve' d='M450,-20 C300,200 100,200 -20,250' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.7'/><path id='curve' d='M250,-20 Q200,350 -20,400' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.3'/><path id='curve' d='M1620,200 C1200,300 1550,700 1000,920' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.7'/><path id='curve' d='M1620,450 Q1050,450 750,920' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.7'/><path id='curve' d='M1250,-20 Q1300,200 1620,250' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.3'/></svg>")`;
      break;
    }
    case "dental": {
      favicon.href = "dental.ico";
      //document.body.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%239F9F9F'/%3E%3Cstop offset='1' stop-color='%239F9F9F'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%239F9F9F'/%3E%3Cstop offset='1' stop-color='%239F9F9F'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
      //document.body.style.backgroundImage = `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><path id='curve' d='M450,-20 C300,200 100,200 -20,250' fill='none' stroke='%239F9F9F' stroke-width='25' opacity='0.7'/><path id='curve' d='M250,-20 Q200,350 -20,400' fill='none' stroke='%239F9F9F' stroke-width='25' opacity='0.3'/><path id='curve' d='M1620,200 C1200,300 1550,700 1000,920' fill='none' stroke='%239F9F9F' stroke-width='25' opacity='0.7'/><path id='curve' d='M1620,450 Q1050,450 750,920' fill='none' stroke='%239F9F9F' stroke-width='25' opacity='0.7'/><path id='curve' d='M1250,-20 Q1300,200 1620,250' fill='none' stroke='%239F9F9F' stroke-width='25' opacity='0.3'/></svg>")`;
      break;
    }
    case "chiro": {
      favicon.href = "chiro.ico";
      //document.body.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%2324BFD0'/%3E%3Cstop offset='1' stop-color='%2324BFD0'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2324BFD0'/%3E%3Cstop offset='1' stop-color='%2324BFD0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
      //document.body.style.backgroundImage = `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><path id='curve' d='M450,-20 C300,200 100,200 -20,250' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M250,-20 Q200,350 -20,400' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.3'/><path id='curve' d='M1620,200 C1200,300 1550,700 1000,920' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M1620,450 Q1050,450 750,920' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M1250,-20 Q1300,200 1620,250' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.3'/></svg>")`;
      break;
    }
    case "chiroMedicare": {
      favicon.href = "chiro.ico";
      //document.body.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%2324BFD0'/%3E%3Cstop offset='1' stop-color='%2324BFD0'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2324BFD0'/%3E%3Cstop offset='1' stop-color='%2324BFD0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
      //document.body.style.backgroundImage = `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><path id='curve' d='M450,-20 C300,200 100,200 -20,250' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M250,-20 Q200,350 -20,400' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.3'/><path id='curve' d='M1620,200 C1200,300 1550,700 1000,920' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M1620,450 Q1050,450 750,920' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M1250,-20 Q1300,200 1620,250' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.3'/></svg>")`;
      break;
    }
    case "vet": {
      favicon.href = "vet.ico";
      //document.body.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%2315AA4E'/%3E%3Cstop offset='1' stop-color='%2315AA4E'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2315AA4E'/%3E%3Cstop offset='1' stop-color='%2315AA4E'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
      //document.body.style.backgroundImage = `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><path id='curve' d='M450,-20 C300,200 100,200 -20,250' fill='none' stroke='%2315AA4E' stroke-width='25' opacity='0.7'/><path id='curve' d='M250,-20 Q200,350 -20,400' fill='none' stroke='%2315AA4E' stroke-width='25' opacity='0.3'/><path id='curve' d='M1620,200 C1200,300 1550,700 1000,920' fill='none' stroke='%2315AA4E' stroke-width='25' opacity='0.7'/><path id='curve' d='M1620,450 Q1050,450 750,920' fill='none' stroke='%2315AA4E' stroke-width='25' opacity='0.7'/><path id='curve' d='M1250,-20 Q1300,200 1620,250' fill='none' stroke='%2315AA4E' stroke-width='25' opacity='0.3'/></svg>")`;
      break;
    }
    case "radiologist": {
      favicon.href = "chiro.ico";
      //document.body.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%2324BFD0'/%3E%3Cstop offset='1' stop-color='%2324BFD0'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2324BFD0'/%3E%3Cstop offset='1' stop-color='%2324BFD0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
      //document.body.style.backgroundImage = `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><path id='curve' d='M450,-20 C300,200 100,200 -20,250' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M250,-20 Q200,350 -20,400' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.3'/><path id='curve' d='M1620,200 C1200,300 1550,700 1000,920' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M1620,450 Q1050,450 750,920' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M1250,-20 Q1300,200 1620,250' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.3'/></svg>")`;
      break;
    }
    case "admin": {
      favicon.href = "main.ico";
      //document.body.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%237F1C53'/%3E%3Cstop offset='1' stop-color='%237F1C53'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%237F1C53'/%3E%3Cstop offset='1' stop-color='%237F1C53'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
      //document.body.style.backgroundImage = `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><path id='curve' d='M450,-20 C300,200 100,200 -20,250' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.7'/><path id='curve' d='M250,-20 Q200,350 -20,400' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.3'/><path id='curve' d='M1620,200 C1200,300 1550,700 1000,920' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.7'/><path id='curve' d='M1620,450 Q1050,450 750,920' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.7'/><path id='curve' d='M1250,-20 Q1300,200 1620,250' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.3'/></svg>")`;
      break;
    }
    case "medicareAdmin": {
      favicon.href = "chiro.ico";
      //document.body.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%2324BFD0'/%3E%3Cstop offset='1' stop-color='%2324BFD0'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2324BFD0'/%3E%3Cstop offset='1' stop-color='%2324BFD0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
      //document.body.style.backgroundImage = `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><path id='curve' d='M450,-20 C300,200 100,200 -20,250' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M250,-20 Q200,350 -20,400' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.3'/><path id='curve' d='M1620,200 C1200,300 1550,700 1000,920' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M1620,450 Q1050,450 750,920' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M1250,-20 Q1300,200 1620,250' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.3'/></svg>")`;
      break;
    }
    case "developer": {
      favicon.href = "chiro.ico";
      //document.body.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%2324BFD0'/%3E%3Cstop offset='1' stop-color='%2324BFD0'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2324BFD0'/%3E%3Cstop offset='1' stop-color='%2324BFD0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
      //document.body.style.backgroundImage = `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><path id='curve' d='M450,-20 C300,200 100,200 -20,250' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M250,-20 Q200,350 -20,400' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.3'/><path id='curve' d='M1620,200 C1200,300 1550,700 1000,920' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M1620,450 Q1050,450 750,920' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.7'/><path id='curve' d='M1250,-20 Q1300,200 1620,250' fill='none' stroke='%2324BFD0' stroke-width='25' opacity='0.3'/></svg>")`;
      break;
    }
    case "public": {
      favicon.href = "main.ico";
      break;
    }
    default: {
      favicon.href = "main.ico";
      //document.body.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop offset='0' stop-color='%237F1C53'/%3E%3Cstop offset='1' stop-color='%237F1C53'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%237F1C53'/%3E%3Cstop offset='1' stop-color='%237F1C53'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='2'%3E%3Cpath transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='4'%3E%3Cpath transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`;
      //document.body.style.backgroundImage = `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><path id='curve' d='M450,-20 C300,200 100,200 -20,250' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.7'/><path id='curve' d='M250,-20 Q200,350 -20,400' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.3'/><path id='curve' d='M1620,200 C1200,300 1550,700 1000,920' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.7'/><path id='curve' d='M1620,450 Q1050,450 750,920' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.7'/><path id='curve' d='M1250,-20 Q1300,200 1620,250' fill='none' stroke='%237F1C53' stroke-width='25' opacity='0.3'/></svg>")`;
      break;
    }
  }

  //Get ribbon background image colour based on userType
  const ribbonColour = getColourByUserType(userType).replace("#", "%23"); //'%2315AA4E';//'%2324BFD0';

  //Create ribbons background image
  document.body.style.backgroundImage =
    `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><path id='curve' d='M450,-20 C300,200 100,200 -20,250' fill='none' stroke='` +
    ribbonColour +
    `' stroke-width='25' opacity='0.7'/><path id='curve' d='M250,-20 Q200,350 -20,400' fill='none' stroke='` +
    ribbonColour +
    `' stroke-width='25' opacity='0.3'/><path id='curve' d='M1620,200 C1200,300 1550,700 1000,920' fill='none' stroke='` +
    ribbonColour +
    `' stroke-width='25' opacity='0.7'/><path id='curve' d='M1620,450 Q1050,450 750,920' fill='none' stroke='` +
    ribbonColour +
    `' stroke-width='25' opacity='0.7'/><path id='curve' d='M1250,-20 Q1300,200 1620,250' fill='none' stroke='` +
    ribbonColour +
    `' stroke-width='25' opacity='0.3'/></svg>")`;
  document.body.style.backgroundSize = "cover";
  document.body.style.height = "100vh";
  document.body.style.width = "100vw";
  //Check if dark mode is on
  //document.body.style.setProperty('background-color', '#333333', 'important');
}
