import { useMemo, useState } from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserType } from "../../../../hooks/useUserType";
import { ActionButton } from "../../../../components/buttons/Buttons";
import {
  ImageMetadata,
  StudyImageData,
  ViewportState,
} from "../../dicomViewer.types";
import { Paper } from "@mui/material";
import { DICOM_EXPORT_TYPES } from "../../dicomViewer.consts";
import Spinner from "react-bootstrap/esm/Spinner";
import { styled } from "@mui/system";
import { downloadAsZip } from "utils/downloadAsZip";
import { useViewerContext } from "pages/viewer/context/viewer.context";

export const DicomDownload = () => {
  const {
    state: { studyImageData, viewports, studyImageMetaData },
  } = useViewerContext();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [showDownloadOptions, setShowDownloadOptions] =
    useState<boolean>(false);

  const toggleDownloadOptions = () => {
    if (!isDownloading) {
      setShowDownloadOptions(!showDownloadOptions);
    }
  };

  const { patientName, patientId, date, dataUrls, selectedStudyIndex } =
    useDicomDataUrl(viewports, studyImageData, studyImageMetaData);

  const handleDownloadImage = async (
    imageType: string,
    downloadMultiple: boolean = false
  ) => {
    setIsDownloading(true);
    setShowDownloadOptions(false);
    const fileName = `ITXViewer-${patientName}-${patientId}-${date}`;
    if (imageType === DICOM_EXPORT_TYPES.DICOM) {
      await downloadFullDicom(fileName, dataUrls, downloadMultiple);
    } else {
      await downloadImage(fileName, dataUrls, imageType, downloadMultiple);
    }
    setIsDownloading(false);
  };

  const downloadFullDicom = async (
    filename: string,
    dicomUrls: string[],
    downloadMultiple: boolean
  ) => {
    const selectedImages = getSelectedImages(
      dicomUrls,
      selectedStudyIndex,
      downloadMultiple
    );
    const downloadPromises = selectedImages.map(async (image) => {
      const formattedUrl = `${image.dicomUrl}&contentType=application/dicom`;
      const response = await fetch(formattedUrl);
      const dicomData = await response.arrayBuffer();
      const dicomByteArray = new Uint8Array(dicomData);
      const blob = new Blob([dicomByteArray], { type: "application/dicom" });
      return {
        content: blob,
        name: `${filename}-${image.imageIndex + 1}.dicom`,
      };
    });
    const downloadResult = await Promise.all(downloadPromises);
    if (downloadMultiple) {
      downloadAsZip(downloadResult, `${filename}-full-study-download`);
    } else {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(downloadResult[0].content);
      link.download = downloadResult[0].name;
      link.click();
    }
  };

  const downloadImage = async (
    filename: string,
    dicomUrls: string[],
    imageType: string,
    downloadMultiple: boolean
  ) => {
    const selectedImages = getSelectedImages(
      dicomUrls,
      selectedStudyIndex,
      downloadMultiple
    );
    const imagePromises = selectedImages.map(async (image, index) => {
      const imageDownloadType = `image/${imageType}`;
      const response = await fetch(image.dicomUrl);
      const dicomData = await response.arrayBuffer();
      const dicomByteArray = new Uint8Array(dicomData);
      const blob = new Blob([dicomByteArray], { type: imageDownloadType });
      return {
        content: blob,
        name: `${filename}-${image.imageIndex + 1}.${imageType}`,
      };
    });

    const downloadResult = await Promise.all(imagePromises);
    if (downloadMultiple) {
      downloadAsZip(downloadResult, `${filename}-full-study-download`);
    } else if (downloadResult.length) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(downloadResult[0].content);
      link.download = downloadResult[0].name;
      link.click();
    }
  };
  const { userTypeColour } = useUserType();

  return (
    <>
      <ActionButton
        variant="contained"
        buttoncolor={userTypeColour}
        onClick={toggleDownloadOptions}
      >
        {isDownloading ? (
          <DicomButtonSpinner />
        ) : (
          <>
            Download{}{" "}
            <FontAwesomeIcon icon={faDownload} style={{ marginLeft: "5px" }} />
          </>
        )}
      </ActionButton>

      {showDownloadOptions && (
        <Paper
          variant="outlined"
          sx={{
            position: "absolute",
            top: 45,
            p: 0.5,
            border: 1,
            zIndex: 1,
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <span>Download Current Image</span>
          <ActionButton
            variant="contained"
            buttoncolor={userTypeColour}
            onClick={() => handleDownloadImage(DICOM_EXPORT_TYPES.DICOM)}
          >
            Dicom
          </ActionButton>
          <ActionButton
            variant="contained"
            buttoncolor={userTypeColour}
            onClick={() => handleDownloadImage(DICOM_EXPORT_TYPES.JPG)}
          >
            JPG
          </ActionButton>
          <ActionButton
            variant="contained"
            buttoncolor={userTypeColour}
            onClick={() => handleDownloadImage(DICOM_EXPORT_TYPES.PNG)}
          >
            PNG
          </ActionButton>
          <span>
            Download Entire Study <br />
          </span>
          <ActionButton
            variant="contained"
            buttoncolor={userTypeColour}
            onClick={() => handleDownloadImage(DICOM_EXPORT_TYPES.DICOM, true)}
          >
            Dicom
          </ActionButton>
          <ActionButton
            variant="contained"
            buttoncolor={userTypeColour}
            onClick={() => handleDownloadImage(DICOM_EXPORT_TYPES.JPG, true)}
          >
            JPG
          </ActionButton>
          <ActionButton
            variant="contained"
            buttoncolor={userTypeColour}
            onClick={() => handleDownloadImage(DICOM_EXPORT_TYPES.PNG, true)}
          >
            PNG
          </ActionButton>
        </Paper>
      )}
    </>
  );
};

const useDicomDataUrl = (
  viewports: ViewportState[],
  studyImageData: StudyImageData[],
  imageMetaData: ImageMetadata[]
) =>
  useMemo(() => {
    const selectedImage = viewports[0];
    const date = getDate();
    if (selectedImage) {
      const selectedStudyIndex = selectedImage.studyImageIndex;

      // get metadata
      const selectedMetaData = imageMetaData[selectedImage.studyImageIndex];
      const { patientId, patientName } = selectedMetaData ?? {
        patientId: "",
        patientName: "",
      };
      // download
      const dataUrls = studyImageData.map(({ image }) => image[0]);

      return { patientId, patientName, date, dataUrls, selectedStudyIndex };
    }
    return {
      patientId: "",
      patientName: "",
      date,
      dataUrls: [],
      selectedStudyIndex: 0,
    };
  }, [viewports, studyImageData, imageMetaData]);

const getDate = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

const SpinnerContainer = styled("div")({
  position: "relative",
  display: "flex",
  fontSize: "0.8rem",
  span: {
    marginTop: "0.2rem",
  },
});

const DicomButtonSpinner = () => {
  return (
    <SpinnerContainer>
      <span>Downloading </span>
      <Spinner
        animation="border"
        role="status"
        style={{
          position: "relative",
          width: "1.5rem",
          height: "1.5rem",
          marginLeft: "0.5rem",

          color: "white",
        }}
      ></Spinner>
    </SpinnerContainer>
  );
};

const getSelectedImages = (
  dicomUrls: string[],
  selectedStudyIndex: number,
  downloadMultiple: boolean
) =>
  dicomUrls.flatMap((dicomUrl, index) => {
    if (downloadMultiple || index === selectedStudyIndex) {
      return { dicomUrl, imageIndex: index };
    }
    return [];
  });
