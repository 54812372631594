import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell } from "./TableCell";
import { ColumnConfig } from "./table.types";
import { getOppositeSortDirection } from "./functions";
import { SORT_DIRECTIONS } from "pages/home-page/components/study-table/study-table-columns/columns.consts";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useCallback } from "react";

interface TableHeaderProps {
  columns: ColumnConfig[];
  onColumnClick: (columnId: string, sortDirection: string) => void;
  sortedColumnId: string;
  sortDirection: string;
  sortableColumnIds?: string[];
}
export const TableHeader = ({
  columns,
  onColumnClick,
  sortedColumnId,
  sortDirection,
  sortableColumnIds = [],
}: TableHeaderProps) => {
  const handleColumnClick = useCallback(
    (columnId: string) => {
      if (sortableColumnIds.includes(columnId)) {
        if (sortedColumnId === columnId) {
          onColumnClick(columnId, getOppositeSortDirection(sortDirection));
        } else {
          onColumnClick(columnId, SORT_DIRECTIONS.DESC);
        }
      }
    },
    [sortedColumnId, sortDirection, onColumnClick]
  );
  return (
    <TableHead>
      <TableRow>
        {columns.map(({ field, headerName }) => (
          <StyledTableCell
            key={`${field} table header`}
            onClick={() => handleColumnClick(field)}
          >
            {headerName.toUpperCase()}{" "}
            {field === sortedColumnId &&
              (sortDirection === SORT_DIRECTIONS.ASC ? (
                <ArrowUpward />
              ) : (
                <ArrowDownward />
              ))}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
