import { StudyData } from "../../../studyTable.types";
import { REPORT_TYPES } from "../../studyTableRows.consts";
import { GoogleDocController } from "./GoogleDocController";

interface DocumentsProps {
  studyData: StudyData;
}

export const Documents = ({ studyData }: DocumentsProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <GoogleDocController
        reportType={REPORT_TYPES.REFERRAL}
        studyData={studyData}
      />
      <GoogleDocController
        reportType={REPORT_TYPES.MEDICAL}
        studyData={studyData}
      />
    </div>
  );
};
