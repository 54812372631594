import { StudyImageData, ViewportState } from "../dicomViewer.types";

export const setMPRViewports = (
  viewports: ViewportState[],
  studyImageData: StudyImageData[]
) => {
  const secondViewport = {
    ...viewports[1],
    viewportOptions: {
      orientation: "sagittal",
    },
    studyImageIndex: studyImageData[1] ? 1 : 0,
  };
  const thirdViewport = {
    ...viewports[2],
    viewportOptions: {
      orientation: "coronal",
    },
    studyImageIndex: studyImageData[1] ? 2 : 0,
  };
  return [viewports[0], secondViewport, thirdViewport];
};
