import { TokenData } from "models/auth.types";
import secureLocalStorage from "react-secure-storage";

export const getAuthorizationData = (): Partial<TokenData> => {
  try {
    const tokenData = secureLocalStorage.getItem("tokenData");
    if (tokenData) {
      return tokenData as TokenData;
    } else {
      console.error("No persisted state found in local storage.");
      return {};
    }
  } catch {
    console.error("Error parsing auth headers from storage reload site");
    return {};
  }
};
