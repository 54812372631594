export const decodeNameFromHL7 = (input) => {
  if (input === null || input === undefined || input.length === 0) {
    return "Unknown";
  }
  let temp = input.toString().trim();
  let split = temp.split("^");
  for (let i = 0; i < split.length; i++) {
    split[i] = split[i].toLowerCase();
    split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
    if (split[i] !== "") {
      split[i] = split[i] + " ";
    }
  }

  let output = split[3] + split[1] + split[2] + split[0] + split[4];
  return output.trimEnd();
};
export const decodeFirstNameFromHL7 = (input) => {
  if (input === null || input === undefined) {
    return "Unknown";
  }
  let temp = input.toString().trim();
  let split = temp.split("^");
  for (let i = 0; i < split.length; i++) {
    split[i] = split[i].toLowerCase();
    split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
    if (split[i] !== "") {
      split[i] = split[i] + " ";
    }
  }

  let output = split[1];

  return output.trimEnd();
};
export const decodeLastNameFromHL7 = (input) => {
  if (input === null || input === undefined) {
    return "Unknown";
  }
  let temp = input.toString().trim();
  let split = temp.split("^");
  for (let i = 0; i < split.length; i++) {
    split[i] = split[i].toLowerCase();
    split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
    if (split[i] !== "") {
      split[i] = split[i] + " ";
    }
  }

  let output = split[0];

  return output ? output.trimEnd() : "";
};
export const decodeMiddleNameFromHL7 = (input) => {
  if (input === null || input === undefined) {
    return "Unknown";
  }
  let temp = input.toString().trim();
  let split = temp.split("^");
  for (let i = 0; i < split.length; i++) {
    split[i] = split[i].toLowerCase();
    split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
    if (split[i] !== "") {
      split[i] = split[i] + " ";
    }
  }

  let output = split[2];

  return output ? output.trimEnd() : "";
};
export const decodePrefixFromHL7 = (input) => {
  if (input === null || input === undefined || input.length === 0) {
    return "Unknown";
  }
  let temp = input.toString().trim();
  let split = temp.split("^");
  for (let i = 0; i < split.length; i++) {
    split[i] = split[i].toLowerCase();
    split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
    if (split[i] !== "") {
      split[i] = split[i] + " ";
    }
  }

  const output = split[3];
  if (output) {
    return output.trimEnd();
  } else {
    return "";
  }
};
export const decodeSuffixFromHL7 = (input) => {
  if (input === null || input === undefined || input.length === 0) {
    return "Unknown";
  }
  let temp = input.toString().trim();
  let split = temp.split("^");
  for (let i = 0; i < split.length; i++) {
    split[i] = split[i].toLowerCase();
    split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
    if (split[i] !== "") {
      split[i] = split[i] + " ";
    }
  }

  let output = split[4];
  return output ? output.trimEnd() : "";
};

export const decodeStudyDate = (input) => {
  if (input === null || input === undefined || input.length < 16) {
    return "Unknown";
  }
  input = input.toString().trim();
  let year = input.slice(0, 4);
  let month = input.slice(5, 7);
  let day = input.slice(8, 10);
  let hour = input.slice(11, 13);
  let minute = input.slice(14, 16);
  input = day + "/" + month + "/" + year + " " + hour + ":" + minute;
  return input;
};

export const decodeHL7DateToDate = (dateString: string | null) => {
  if (!dateString) return "Unknown";

  try {
    // Extract year, month, and day from the string
    const year = parseInt(dateString.substring(0, 4), 10);
    const month = parseInt(dateString.substring(4, 6), 10) - 1; // Month is 0-based in JavaScript Date
    const day = parseInt(dateString.substring(6, 8), 10);

    // Construct the Date object
    const date = new Date(Date.UTC(year, month, day));

    // Format the date to Australian Eastern Time (AEST/AEDT)
    const options = {
      timeZone: "Australia/Sydney",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    // @ts-ignore
    const formatter = new Intl.DateTimeFormat("en-AU", options);
    const [formattedDate] = formatter
      .formatToParts(date)
      .filter(
        (part) =>
          part.type === "year" || part.type === "month" || part.type === "day"
      )
      .map((part) => part.value)
      .join("-");

    return formattedDate;
  } catch {
    return "Unknown";
  }
};

export const decodeDateString = (dateString: string | null) => {
  if (!dateString) return "Unknown";
  try {
    // Extract year, month, and day from the string
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    // Construct the date string in the format DD/MM/YYYY
    return `${day}/${month}/${year}`;
  } catch {
    return "-";
  }
};
