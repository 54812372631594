import { PersistentDrawer } from "components/drawer/Drawer";
import { Dropdown } from "components/dropdown/Dropdown";
import { USER_TYPES } from "consts";
import { useAppContext } from "context/app.context";
import { useCallback, useMemo } from "react";

const ALLOWED_USER_IDS = ["atxpublic"];
export const SettingsPanel = () => {
  const {
    dispatch,
    state: { isSettingsOpen, userId },
  } = useAppContext();

  const updateUserType = useCallback(
    (userType) => {
      dispatch({ type: "SET_USER_TYPE", payload: { userType } });
    },
    [dispatch]
  );

  const setUser = useCallback(
    (blurEvent: React.FocusEvent<HTMLInputElement, Element>) => {
      const user = blurEvent.target.value;
      dispatch({ type: "SET_USER", payload: { user } });
    },
    [dispatch]
  );
  const userTypeOptionConfigs = useMemo(
    () =>
      Object.values(USER_TYPES).map((value) => ({
        label: value,
        value,
      })),
    [USER_TYPES]
  );
  if (isSettingsOpen && ALLOWED_USER_IDS.includes(userId)) {
    return (
      <PersistentDrawer
        isOpenDefault={isSettingsOpen}
        drawerButtonTitle={""}
        drawerSide={""}
      >
        <>
          <Dropdown
            title={"Select User type"}
            optionsConfig={userTypeOptionConfigs}
            onChange={updateUserType}
          />
          <input onBlur={(event) => setUser(event)} />
        </>
      </PersistentDrawer>
    );
  }
  return null;
};
