import { useMemo } from "react";
import { useViewerContext } from "../../context/viewer.context";

export const useImageMetaData = () => {
  const {
    state: { studyImageMetaData, rawMetaData, viewports },
  } = useViewerContext();
  return useMemo(() => {
    if (viewports[0]) {
      const { studyImageIndex } = viewports[0];
      const unprocessedMeta = rawMetaData[studyImageIndex];
      const imageMetaData = studyImageMetaData[studyImageIndex];
      return { unprocessedMeta, imageMetaData };
    }
    return {
      unprocessedMeta: null,
      imageMetaData: null,
    };
  }, [studyImageMetaData, rawMetaData, viewports]);
};
