import cornerstoneTools from "cornerstone-tools";

/**
 * Tools
 * @namespace Tools
 */
export const Tools = {
  MouseCursor: cornerstoneTools.importInternal("tools/cursors/MouseCursor"),
  cursors: cornerstoneTools.importInternal("tools/cursors"),
};

export default Tools;
