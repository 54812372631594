import { useViewerContext } from "pages/viewer/context/viewer.context";
import { MPR_TOOL_CONFIG, TOOL_IDS } from "../consts/tools.consts";
import { useCallback, useEffect } from "react";
import { useGridControllerActions } from "pages/viewer/components/dicom-grid-controller/useGridControllerActions";
import { INITIAL_VIEWER_GRID_STATE } from "pages/viewer/dicomViewer.consts";
import cornerstone from "cornerstone-core";
import { selectToolAndDeselectDefault } from "../hooks/useActiveTools";
import { ToolConfig } from "../tools.types";
import { MPRTool } from "./MPRTool";
import cornerstoneTools from "cornerstone-tools";
import { useLoadMprData } from "./loadAndDisplayMpr";
import { useInitialPerform } from "../hooks/useInitialPerform";
import { getFormattedModeOption } from "../functions/formatModeOptions";

const MPR_GRID_DIMENSIONS = [3, 1];
export const useMPRTool = () => {
  const {
    dispatch,
    state: { isMPRActive },
  } = useViewerContext();

  const { mprImagesLoaded } = useLoadMprData();

  const { updateGridAndViewport } = useGridControllerActions();

  useEffect(() => {
    if (isMPRActive) {
      updateGridAndViewport(MPR_GRID_DIMENSIONS);
      dispatch({ type: "SET_MPR_VIEWPORTS" });
    } else {
      updateGridAndViewport(INITIAL_VIEWER_GRID_STATE);
    }
  }, [isMPRActive, dispatch]);

  const onMPRToolUpdate = useCallback(
    (toolId: string, data: unknown) => {
      if (toolId === TOOL_IDS.MPR_TOOL) {
        const { x, y } = data as { x: number; y: number };
        dispatch({ type: "MPR_TOOL_UPDATE", payload: { x, y } });
      }
    },
    [dispatch]
  );

  const initialPerform = useInitialPerform();
  useEffect(() => {
    if (mprImagesLoaded && isMPRActive) {
      const enabledElementsArray = cornerstone.getEnabledElements();
      enabledElementsArray.forEach((enabledElement) => {
        const toolConfig = [{ ...MPR_TOOL_CONFIG }];
        selectToolAndDeselectDefault(
          TOOL_IDS.MPR_TOOL,
          // @ts-ignore
          toolConfig,
          enabledElement
        );
        const { modeOptions } = MPR_TOOL_CONFIG;
        const { mouseButtonMask } = getFormattedModeOption(modeOptions);
        cornerstoneTools.addTool(MPRTool);
        cornerstoneTools.setToolActive(TOOL_IDS.MPR_TOOL, {
          mouseButtonMask,
        });
        const toolInstance = cornerstoneTools.getToolForElement(
          enabledElement.element,
          TOOL_IDS.MPR_TOOL
        );
        toolInstance.onToolActivation();
        toolInstance.setContextFunction(onMPRToolUpdate);
        toolInstance.renderInitial();
      });
    }
  }, [mprImagesLoaded, isMPRActive, initialPerform, onMPRToolUpdate]);
};
