// @ts-nocheck
import cornerstoneTools from "cornerstone-tools";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import cornerstone from "cornerstone-core";
import { TOOL_IDS } from "../consts/tools.consts";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);
const configuration = {
  markers: ["L1", "L2", "L3", "L4", "L5"],
  current: "L1",
  ascending: true,
  loop: true,
};
const LumbarTool = cornerstoneTools.TextMarkerTool;

export default class LumbarLabel extends BaseAnnotationTool {
  constructor(name = TOOL_IDS.LUMBAR_LABEL) {
    super({
      name,
      supportedInteractionTypes: ["Mouse", "Touch"],
    });
    this.hasIncomplete = false;
    this.counter = -1;
  }

  activeCallback(element) {
    cornerstoneTools.addTool(LumbarTool, { configuration });
  }

  preMouseDownCallback(event) {
    if (this.counter < configuration.markers.length - 1) {
      this.counter += 1;
    } else {
      this.counter = 0;
      //console.log("restart");
      // cornerstoneTools.setToolDisabled('LumbarTool');
    }
    //  //console.log(configuration.markers[this.counter])
  }

  createNewMeasurement(event) {
    const goodEventData =
      event && event.currentPoints && event.currentPoints.image;
    if (!goodEventData) {
      // //console.log(
      //     `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      // );
      return;
    }

    const { x, y } = event.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: "greenyellow",
      text: configuration.markers[this.counter],
      invalidated: true,
      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: false,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    const validParameters =
      data && data.handles && data.handles.start && data.handles.end;

    if (!validParameters) {
      // //console.log(
      //     `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      // );
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const line =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 10;

    if (line) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
    }
    return line;
  }

  distanceFromPoint(event) {}

  renderToolData(evt) {
    const eventData = evt.detail;
    const { element } = eventData;
    const context = Drawing.getNewContext(eventData.canvasContext.canvas);

    const toolData = cornerstoneTools.getToolState(
      evt.currentTarget,
      this.name
    );
    const config = this.configuration;

    // If we have no toolData for this element, return immediately as there is nothing to do
    if (!toolData) {
      return;
    }
    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      const color = cornerstoneTools.toolColors.getColorIfActive(data);

      Drawing.draw(context, (context) => {
        Drawing.setShadow(context, config);

        const textCoords = cornerstone.pixelToCanvas(
          eventData.element,
          data.handles.end
        );

        const options = {
          centering: {
            x: true,
            y: true,
          },
        };
        //Drawing.drawHandles(context, eventData ,data.handles,(5,'green'));
        data.handles.end.boundingBox = Drawing.drawTextBox(
          context,
          data.text,
          textCoords.x,
          textCoords.y - 10,
          color,
          options
        );
      });
    }
  }

  _changeText(evt) {
    // //console.log('lumbar tool - change text');
    const eventData = evt.detail;
    const { element, currentPoints } = eventData;
    let data;

    function doneChangingTextCallback(data, updatedText, deleteTool) {
      if (deleteTool === true) {
        cornerstoneTools.removeToolState(element, this.name, data);
      } else {
        data.text = updatedText;
      }

      data.active = false;
      cornerstoneTools.updateImage(element);
    }

    const config = this.configuration;
    const coords = currentPoints.canvas;
    const toolData = cornerstoneTools.getToolState(element, this.name);

    // Now check to see if there is a handle we can move
    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      data = toolData.data[i];
      if (this.pointNearTool(element, data, coords)) {
        data.active = true;
        cornerstoneTools.updateImage(element);

        // Allow relabelling via a callback
        config.changeTextCallback(data, eventData, doneChangingTextCallback);

        evt.stopImmediatePropagation();
        evt.preventDefault();
        evt.stopPropagation();

        return;
      }
    }
  }
}
