import { Field, FieldProps } from "formik";
import { Stack } from "@mui/system";
import { FieldLabel } from "components/text/Text";
import { useCallback } from "react";

interface CheckboxInputProps {
  fieldName: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  value?: boolean;
  defaultValue?: string;
}
export const FormCheckbox = ({
  label,
  fieldName,
  required,

  value,
}: CheckboxInputProps) => {
  return (
    <Stack>
      <FieldLabel isRequired={required} label={label ?? fieldName} />
      <span>
        <Field name={fieldName} component={CheckboxField} value={value} />
      </span>
    </Stack>
  );
};

type CheckboxFieldProps = FieldProps & { value: boolean };
const CheckboxField: React.FC<CheckboxFieldProps> = ({
  field,
  form,
  value,
  ...props
}) => {
  const handleUpdateValue = useCallback(
    (event) => {
      const {
        target: { checked },
      } = event;
      form.setFieldValue(field.name, checked);
    },
    [form, value]
  );
  return (
    <input
      type="checkbox"
      value={field.name}
      checked={value}
      style={{
        cursor: "pointer",

        transform: "scale(1.5)",
      }}
      onChange={(e) => handleUpdateValue(e)}
    />
  );
};
