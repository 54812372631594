import { USER_TYPE_COLORS } from "theme/theme.consts";
import { USER_TYPES } from "../../consts";

export const getColourByUserType = (type: string) => {
  switch (type) {
    case USER_TYPES.DENTAL:
      return USER_TYPE_COLORS.GREY;
    case USER_TYPES.CHIRO:
    case USER_TYPES.RADIOLOGIST:
    case USER_TYPES.CHIRO_MEDICARE:
    case USER_TYPES.MEDICARE_ADMIN:
    case USER_TYPES.DEVELOPER:
      return USER_TYPE_COLORS.AQUA;
    case USER_TYPES.VET:
      return USER_TYPE_COLORS.GREEN;
    case USER_TYPES.VET_XCALIBER:
    case USER_TYPES.ADMIN:
    case USER_TYPES.MEDICAL:
    case USER_TYPES.PUBLIC:
    default:
      return USER_TYPE_COLORS.MAROON;
  }
};
