// Unsure why mode options are being set as an array when they are defined as an object
// Might be due to cornerstone modifying the config objects it's causing issues with setting/disabling tools
// Made a helper function to work around this issue in the meantime
export const getFormattedModeOption = (modeOption: any) => {
  if (modeOption.mouseButtonMask instanceof Array) {
    return { ...modeOption, mouseButtonMask: modeOption.mouseButtonMask[0] };
  } else {
    return modeOption;
  }
};
