import { createContext, useReducer, useContext, ReactNode } from "react";
import { UserAuth, UserType } from "./app.context.types";
import { TokenData } from "models/auth.types";

export type AppState = {
  isLoggedIn: boolean;
  userId: null | string;
  userType: null | UserType;
  isDarkModeOn: boolean | null;
  userAuthData: null | UserAuth;
  tokenData: null | TokenData;
  xcaliberStatus: null | boolean;
  isSettingsOpen: boolean;
};
const INITIAL_STATE: AppState = {
  isLoggedIn: false,
  userType: null,
  userId: null,
  isDarkModeOn: null,
  userAuthData: null,
  tokenData: null,
  xcaliberStatus: null,
  isSettingsOpen: false,
};

export type AppActions =
  | {
      type: "LOGIN";
      payload: { userId: string; userType: UserType; userAuthData: UserAuth };
    }
  | { type: "LOGOUT" }
  | { type: "TOGGLE_DARK_MODE"; payload: { isDarkModeOn: boolean } }
  | { type: "SET_XCALIBER_STATUS"; payload: { xcaliberStatus: boolean } }
  | { type: "TOGGLE_SETTINGS_PANEL"; payload: {} }
  | { type: "SET_USER_TYPE"; payload: { userType: UserType } }
  | { type: "SET_USER"; payload: { user: string } }
  // TODO - set the incoming url
  | { type: "SET_INCOMING_URL"; payload: { url: string } };

type AppContextType = {
  state: AppState;
  dispatch: React.Dispatch<AppActions>;
};
const AppContext = createContext<AppContextType | undefined>(undefined);

const reducer = (state: AppState, action: AppActions) => {
  switch (action.type) {
    case "TOGGLE_DARK_MODE":
      return {
        ...state,
        isDarkModeOn: action.payload.isDarkModeOn,
      };
    case "SET_XCALIBER_STATUS":
      return {
        ...state,
        xcaliberStatus: action.payload.xcaliberStatus,
      };
    case "TOGGLE_SETTINGS_PANEL":
      return {
        ...state,
        isSettingsOpen: !state.isSettingsOpen,
      };
    case "LOGIN":
      return {
        ...state,
        isLoggedIn: true,
        userAuthData: action.payload.userAuthData,
        userId: action.payload.userId,
        userType: action.payload.userType,
      };
    case "LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        userAuthData: null,
        userId: null,
        userType: null,
        tokenData: null,
        xcaliberStatus: null,
      };
    case "SET_USER":
      return {
        ...state,
        userId: action.payload.user,
      };
    case "SET_USER_TYPE":
      return {
        ...state,
        userType: action.payload.userType,
      };
    default:
      return state;
  }
};

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a App Provider");
  }

  return context;
};

export { AppProvider, useAppContext };
