const FOOTER_TEXT = "This referral may be presented to any imaging practice.";
export const setUpDocumentFooter = (footerId) => [
  {
    insertText: {
      location: {
        //"index": 1
        segmentId: footerId,
        index: 0,
      },
      text: FOOTER_TEXT,
    },
  },
  {
    updateTextStyle: {
      textStyle: {
        fontSize: {
          magnitude: 9,
          unit: "PT",
        },
        weightedFontFamily: {
          fontFamily: "Cabin",
        },
      },
      fields: "fontSize, weightedFontFamily",
      range: {
        segmentId: footerId,
        startIndex: 0,
        endIndex: FOOTER_TEXT.length,
      },
    },
  },
];
