import { FieldLabel } from "components/text/Text";
import { ALLOWED_KEYS, TEXT_INPUT_TYPES } from "../form.consts";
import { Field, FieldProps } from "formik";

interface CustomInputProps extends FieldProps {
  type: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  numericOnly?: boolean;
}

const CustomTextInput = ({
  form,
  field,
  type,
  placeholder,
  maxLength,
  numericOnly,
}: CustomInputProps) => {
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      numericOnly &&
      !/^[0-9]*$/.test(event.key) &&
      !ALLOWED_KEYS.includes(event.key)
    ) {
      event.preventDefault();
    }
  };
  return (
    <div>
      <input
        {...field}
        onKeyDown={onKeyPress}
        type={type}
        maxLength={maxLength}
        placeholder={placeholder}
        pattern={numericOnly ? "[0-9]*" : undefined}
        className="input-text form-control"
      />
      {form.touched[field.name] && form.errors[field.name] && (
        <div className="error">{form.errors[field.name] as string}</div>
      )}
    </div>
  );
};

interface TextInputProps {
  value?: string | null;
  fieldName: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  numericOnly?: boolean;
}
export const FormTextInput = ({
  value = "",
  label,
  fieldName,
  required,
  maxLength,
  placeholder,
  numericOnly = false,
}: TextInputProps) => {
  const inputType = TEXT_INPUT_TYPES.TEXT;

  return (
    <div>
      <FieldLabel isRequired={required} label={label ?? fieldName} />

      <Field
        value={value ?? ""}
        name={fieldName}
        component={CustomTextInput}
        maxLength={maxLength}
        type={inputType}
        placeholder={placeholder}
        numericOnly={numericOnly}
      />
    </div>
  );
};
