import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useCallback } from "react";

export const useGridControllerActions = () => {
  const {
    dispatch,
    state: { gridDimensions },
  } = useViewerContext();

  const updateGridAndViewport = useCallback(
    (updatedGridDimensions: number[]) => {
      dispatch({
        type: "UPDATE_VIEWER_GRID",
        payload: { updatedGridDimensions },
      });
    },
    [dispatch]
  );

  return { updateGridAndViewport, gridDimensions };
};
