import { useCallback } from "react";
import { useViewerContext } from "pages/viewer/context/viewer.context";

export const useScrubFrame = () => {
  const { dispatch } = useViewerContext();
  return useCallback(
    (direction: string) => {
      dispatch({ type: "SCRUB_IMAGE_FRAME_INDEX", payload: { direction } });
    },
    [dispatch]
  );
};
