import { OpenInNew } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { useHomePageContext } from "pages/home-page/context/homepage.context";
import { useCallback } from "react";
import { pluralize } from "utils/formatting/formatValue";
import { formatViewerUrl } from "components/table/table-row-components/viewer-link/formatViewerUrl";
import { StudyData } from "../../study-table/studyTable.types";
import { CustomFab } from "components/buttons/CustomFab";

export const OpenInViewer = () => {
  const {
    state: { selectedRows },
  } = useHomePageContext();

  const onOpen = useCallback(() => {
    selectedRows.forEach((studyData: StudyData) => {
      const { studypk, studyIuid } = studyData;
      const url = formatViewerUrl(studypk, studyIuid);
      window.open(url, "_blank");
    });
  }, [selectedRows, formatViewerUrl]);

  return (
    <CustomFab
      ariaLabel={"open in viewer"}
      disabled={!selectedRows.length}
      onClick={onOpen}
    >
      <>
        <OpenInNew />
        {selectedRows.length
          ? `Open ${selectedRows.length} ${pluralize(
              selectedRows.length,
              "Study",
              "Studies"
            )} in Viewer`
          : `Open Studies in Viewer`}
      </>
    </CustomFab>
  );
};
