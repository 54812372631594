import cornerstoneTools from "cornerstone-tools";
import Util from "../../api/Util";
import { getDimensionData } from "../measurementToolUtils";
import { ImageMetadata } from "pages/viewer/dicomViewer.types";
import { TOOL_IDS } from "../../consts/tools.consts";
import Drawing from "../../api/Drawing";
import toolStyle from "../../api/state-management/toolStyle";
import cornerstone, { triggerEvent } from "cornerstone-core";
import Manipulators from "../../api/Manipulators";
import toolColors from "../../api/state-management/toolColours";
import { DEFAULT_HANDLE } from "../../consts/tools.defaults";
const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

/**
 * @public
 * @class CobbAngleTool
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring the angle between two straight lines.
 * @extends Tools.Base.BaseAnnotationTool
 */

const COBB_ANGLE_CONFIG = {
  drawHandles: true,
  drawHandlesOnHover: true,
  hideHandlesIfMoving: true,
};
export default class CobbAngleTool extends BaseAnnotationTool {
  hasIncomplete: boolean;
  throttledUpdateCachedStats: any;
  imageMetadata: ImageMetadata;
  toolId: string;
  constructor(props = {}) {
    const defaultProps = {
      name: TOOL_IDS.COBB_ANGLE,
      supportedInteractionTypes: ["Mouse", "Touch"],
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
      },
    };

    super(props, defaultProps);
    this.toolId = TOOL_IDS.COBB_ANGLE;
    this.hasIncomplete = false;
    this.throttledUpdateCachedStats = Util.throttle(
      this.updateCachedStats,
      110
    );
    this.imageMetadata = {};
    this.setImageMetadata = this.setImageMetadata.bind(this);
  }
  public setImageMetadata(imageMetaData: ImageMetadata) {
    if (imageMetaData) {
      this.imageMetadata = imageMetaData;
    }
  }
  createNewMeasurement(eventData) {
    // Create the measurement data for this tool with the end handle activated
    this.hasIncomplete = true;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: "",
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        start2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        end2: {
          x: eventData.currentPoints.image.x + 1,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const seg1Near =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 25;
    const seg2Near =
      Util.lineSegDistance(
        element,
        data.handles.start2,
        data.handles.end2,
        coords
      ) < 25;

    return seg1Near || seg2Near;
  }

  updateCachedStats(image, element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetadata
    );

    const dx1 =
      (Math.ceil(data.handles.start.x) - Math.ceil(data.handles.end.x)) *
      (colPixelSpacing || 1);
    const dy1 =
      (Math.ceil(data.handles.start.y) - Math.ceil(data.handles.end.y)) *
      (rowPixelSpacing || 1);
    const dx2 =
      (Math.ceil(data.handles.start2.x) - Math.ceil(data.handles.end2.x)) *
      (colPixelSpacing || 1);
    const dy2 =
      (Math.ceil(data.handles.start2.y) - Math.ceil(data.handles.end2.y)) *
      (rowPixelSpacing || 1);

    let angle = Math.acos(
      Math.abs(
        (dx1 * dx2 + dy1 * dy2) /
          (Math.sqrt(dx1 * dx1 + dy1 * dy1) * Math.sqrt(dx2 * dx2 + dy2 * dy2))
      )
    );

    angle *= 180 / Math.PI;

    data.rAngle = Util.roundToDecimal(angle, 2);
    data.invalidated = false;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { drawHandles, drawHandlesOnHover, hideHandlesIfMoving } =
      COBB_ANGLE_CONFIG;
    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = cornerstoneTools.getToolState(
      evt.currentTarget,
      this.toolId
    );

    if (!toolData) {
      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const context = Drawing.getNewContext(eventData.canvasContext.canvas);

    const lineWidth = toolStyle.getToolWidth();
    const lineDash = { color: "#fff" };
    const font = cornerstoneTools.textStyle.getFont();
    const { element } = evt.detail;
    const image = cornerstone.getEnabledElement(element).image;
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetadata
    );

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      if (!data.value) {
        data.value = this.textBoxText(data, rowPixelSpacing, colPixelSpacing);
      }

      Drawing.draw(context, (context) => {
        Drawing.setShadow(context, COBB_ANGLE_CONFIG);

        // Differentiate the color of activation tool
        const color = toolColors.getColorIfActive(data);

        const lineOptions = { color };

        Drawing.drawLine(
          context,
          eventData.element,
          data.handles.start,
          data.handles.end,
          lineOptions
        );

        if (data.complete) {
          Drawing.drawLine(
            context,
            eventData.element,
            data.handles.start2,
            data.handles.end2,
            lineOptions
          );
        }

        // Draw the handles
        const handleOptions = {
          ...DEFAULT_HANDLE,
          color,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        if (drawHandles) {
          Drawing.drawHandles(context, eventData, data.handles, handleOptions);
        }

        // Draw the text
        context.fillStyle = color;

        const text = data.value;

        if (!data.handles.textBox.hasMoved) {
          const textCoords = {
            x: (data.handles.start.x + data.handles.end.x) / 2,
            y: (data.handles.start.y + data.handles.end.y) / 2 - 10,
          };

          context.font = font;
          data.handles.textBox.x = textCoords.x;
          data.handles.textBox.y = textCoords.y;
        }

        Drawing.drawLinkedTextBox(
          context,
          eventData.element,
          data.handles.textBox,
          text,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          0,
          true
        );
      });
    }

    function textBoxAnchorPoints(handles) {
      return [handles.start, handles.start2, handles.end, handles.end2];
    }
  }

  getIncomplete(element) {
    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    if (toolState && Array.isArray(toolState.data)) {
      return toolState.data.find(({ complete }) => complete === false);
    }
  }

  addNewMeasurement(evt, interactionType) {
    evt.preventDefault();
    evt.stopPropagation();

    const eventData = evt.detail;

    let measurementData;
    let toMoveHandle;
    let doneMovingCallback = (success) => {
      // DoneMovingCallback for first measurement.
      if (!success) {
        cornerstoneTools.removeToolState(element, this.toolId, measurementData);

        return;
      }
      const eventType = cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED;
      const eventData = {
        toolName: this.toolId,
        toolType: this.toolId, // Deprecation notice: toolType will be replaced by toolName
        element,
        measurementData,
      };

      triggerEvent(element, eventType, eventData);
    };

    // Search for incomplete measurements
    const element = evt.detail.element;
    const pendingMeasurement = this.getIncomplete(element);

    if (pendingMeasurement) {
      measurementData = pendingMeasurement;
      measurementData.complete = true;
      measurementData.handles.start2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: false,
      };
      measurementData.handles.end2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      toMoveHandle = measurementData.handles.end2;
      this.hasIncomplete = false;
      doneMovingCallback = (success) => {
        // DoneMovingCallback for second measurement
        if (!success) {
          cornerstoneTools.removeToolState(
            element,
            this.toolId,
            measurementData
          );

          return;
        }

        const eventType = cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED;
        const eventData = {
          toolName: this.toolId,
          toolType: this.toolId, // Deprecation notice: toolType will be replaced by toolName
          element,
          measurementData,
        };

        triggerEvent(element, eventType, eventData);
      };
    } else {
      measurementData = this.createNewMeasurement(eventData);
      cornerstoneTools.addToolState(element, this.toolId, measurementData);
      toMoveHandle = measurementData.handles.end;
    }

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);

    Manipulators.moveNewHandle(
      eventData,
      this.toolId,
      measurementData,
      toMoveHandle,
      {},
      interactionType,
      doneMovingCallback
    );
  }

  onMeasureModified(ev) {
    const { element } = ev.detail;
    const image = cornerstone.getEnabledElement(element).image;
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetadata
    );

    if (ev.detail.toolName !== this.toolId) {
      return;
    }
    const data = ev.detail.measurementData;

    // Update textbox stats
    if (data.invalidated === true) {
      if (data.rAngle) {
        this.throttledUpdateCachedStats(image, element, data);
      } else {
        this.updateCachedStats(image, element, data);
      }
    }

    data.value = this.textBoxText(data, rowPixelSpacing, colPixelSpacing);
  }

  textBoxText({ rAngle }, rowPixelSpacing, colPixelSpacing) {
    if (rAngle === undefined) {
      return "";
    }
    if (Number.isNaN(rAngle)) {
      return "";
    }

    const suffix = !rowPixelSpacing || !colPixelSpacing ? " (isotropic)" : "";

    return `${rAngle}\u00B0${suffix}`;
  }

  activeCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  passiveCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  enabledCallback(element) {
    element.removeEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  disabledCallback(element) {
    element.removeEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }
}
