import { useMemo } from "react";
import { useViewerContext } from "../context/viewer.context";
import { FRAMED_MODALITIES } from "../dicomViewer.consts";
import { useUpdateFrameCount } from "../tools/default-tools/scroll-stack/useUpdateFrameCount";

export const useFramedModality = () => {
  const {
    state: { modalityType, totalFrames, viewportFrameIndexes },
  } = useViewerContext();

  const isFramedModality = useMemo(
    () => FRAMED_MODALITIES.includes(modalityType),
    [modalityType]
  );
  const updateFrameCount = useUpdateFrameCount();
  return {
    totalFrames,
    isFramedModality,
    viewportFrameIndexes,
    updateFrameCount,
  };
};
