import { useCallback, useEffect } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { useFramedModality } from "./useFramedModality";
import { useViewerContext } from "../context/viewer.context";

const IMAGE_BAR_LENGTH_PX = 48;

export const useDicomViewports = () => {
  const { isFramedModality } = useFramedModality();
  const {
    dispatch,
    state: { modalityType, viewports, studyImageData, gridDimensions },
  } = useViewerContext();

  useEffect(() => {
    if (studyImageData && modalityType) {
      dispatch({ type: "INITIALIZE_VIEWPORT", payload: {} });
    }
  }, [dispatch, studyImageData, modalityType]);
  const onChangeActiveViewport = useCallback(
    (imageIndex: number) => {
      if (studyImageData && studyImageData[imageIndex]) {
        const newViewport = {
          imageIds: studyImageData[imageIndex].image,
          frames: isFramedModality
            ? studyImageData[imageIndex].frameImageIds ?? []
            : null,
          totalFrames: isFramedModality
            ? studyImageData[imageIndex].frameImageIds.length
            : 1,
          studyImageIndex: imageIndex,
        };
        dispatch({ type: "CHANGE_ACTIVE_VIEWPORT", payload: { newViewport } });
      }
    },
    [studyImageData, dispatch, isFramedModality]
  );

  const { width, height } = useWindowSize();
  const changeViewportDataOnDrag = useCallback(
    (cursorX: number, cursorY: number, imageIndex: number) => {
      if (
        cursorY > IMAGE_BAR_LENGTH_PX &&
        studyImageData &&
        studyImageData[imageIndex]
      ) {
        const viewportIndexX = Math.floor(
          cursorX / (width / gridDimensions[0])
        );
        const viewportIndexY = Math.floor(
          (cursorY - 48) / ((height - 48) / gridDimensions[1])
        );
        const viewportIndex =
          viewportIndexX + viewportIndexY * gridDimensions[0];
        const viewportData = {
          imageIds: studyImageData[imageIndex].image,
          frames: isFramedModality
            ? studyImageData[imageIndex].frameImageIds ?? []
            : null,
          totalFrames: isFramedModality
            ? studyImageData[imageIndex].frameImageIds.length
            : 1,
          studyImageIndex: imageIndex,
        };
        dispatch({
          type: "UPDATE_VIEWPORT_IMAGE_DATA",
          payload: { viewportIndex, viewportData },
        });
      }
    },
    [gridDimensions, dispatch, width, height]
  );

  return { onChangeActiveViewport, viewports, changeViewportDataOnDrag };
};
