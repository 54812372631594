export const DEFAULT_COLOR = "white";
export const ACTIVE_COLOR = "greenyellow";
export const DEFAULT_HANDLE = {
  color: ACTIVE_COLOR,
  handleRadius: 2,
  drawHandles: true,
  drawHandlesIfActive: true,
  hideHandlesIfMoving: true,
};

export const DEFAULT_LINE_DASH = {};
