export const GONSTEAD_POINTS = {
  RIGHT_FEMUR_HEAD: "rightFemurHead",
  LEFT_FEMUR_HEAD: "leftFemurHead",
  RIGHT_ILIAC_CREST: "rightIliacCrest",
  RIGHT_ISCHIAL_TUBEROSITY: "rightIschialTuberosity",
  LEFT_ILIAC_CREST: "leftIliacCrest",
  LEFT_ISCHIAL_TUBEROSITY: "leftIschialTuberosity",
  S2_TUBERCLE: "s2Tubercle",
  PUBIC_SYMPHYSIS: "pubicSymphysis",
  RIGHT_S1_FACET_BASE: "rightS1FacetBase",
  LEFT_S1_FACET_BASE: "leftS1FacetBase",
  RIGHT_SACRAL_WING: "rightSacralWing",
  LEFT_SACRAL_WING: "leftSacralWing",
  RIGHT_ILIAC_WING: "rightIliacWing",
  LEFT_ILIAC_WING: "leftIliacWing",
  RIGHT_PSIS: "rightPsis",
  LEFT_PSIS: "leftPsis",
};
