export const checkEventData = (eventData) => {
  const isEventDataValid =
    eventData && eventData.currentPoints && eventData.currentPoints.image;
  return isEventDataValid;
};

export const isPointNearToolEventValid = (pointerEventData) => {
  const validParameters =
    pointerEventData &&
    pointerEventData.handles &&
    pointerEventData.handles.start &&
    pointerEventData.handles.end;
  return (validParameters && pointerEventData?.visible) ?? true;
};
