import {
  HL7_DECODE,
  FIELD_KEYS,
} from "pages/home-page/components/study-table/study-table-columns/columns.consts";
import {
  decodeDateString,
  decodeNameFromHL7,
  decodeStudyDate,
} from "./decodeHL7";
import { DropdownOption } from "components/form/Form.types";
import { STATUS_OPTIONS } from "pages/home-page/components/study-table/study-table-rows/studyTableRows.consts";

export const formatValue = (rawValue: string | null, fieldName: string) => {
  if (HL7_DECODE.includes(fieldName)) {
    return decodeHL7(rawValue, fieldName);
  }
  if (fieldName === FIELD_KEYS.STATUS) {
    return getDropdownLabel(rawValue, STATUS_OPTIONS);
  }
  return rawValue;
};

export const decodeHL7 = (value: string | null, fieldName) => {
  switch (fieldName) {
    case FIELD_KEYS.PAT_BIRTHDATE:
      return decodeDateString(value);
    case FIELD_KEYS.PHYSICIAN:
    case FIELD_KEYS.PAT_NAME:
      return decodeNameFromHL7(value);
    case FIELD_KEYS.STUDY_DATETIME:
      return decodeStudyDate(value);
    default:
      return "Unknown";
  }
};

export const getDropdownLabel = (
  selectedValue: string,
  dropdownOptions: DropdownOption[]
) => {
  const selectedOption = dropdownOptions.find(
    ({ value }) => String(selectedValue) === value
  );

  if (selectedOption) {
    return selectedOption.label;
  }
  return "Unknown";
};

export const isDate = (value) => {
  return value instanceof Date;
};

export const pluralize = (value: number, singular: string, plural: string) => {
  if (value === 1) {
    return singular;
  } else {
    return plural;
  }
};

export const objectsAreDifferent = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the objects have different number of keys
  if (keys1.length !== keys2.length) {
    return true;
  }

  // Check if there is at least one key that is different
  if (!keys1.every((key) => keys2.includes(key))) {
    return true;
  }

  // Check if there is at least one value that is different
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return true;
    }
  }

  // If all keys and values are the same
  return false;
};
