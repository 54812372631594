import { getAuthorizationData } from "./getAuthorizationData";

export const refreshTokens = async () => {
  const { refreshToken } = getAuthorizationData();
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  if (refreshToken) {
    headers.Authorization = `Bearer ${refreshToken}`;
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/authentication/refresh`,
    {
      method: "POST",
      headers,
      body: "",
    }
  );
  // @ts-ignore
  if (response.status === "Valid Credentials") {
    return response;
  }
  return null;
};
