import { useMemo } from "react";
import {
  COMMON_MANIPULATION_TOOLS,
  COMMON_MEASUREMENT_TOOLS,
  MEDICAL_TOOLS,
  VET_TOOLS,
  CHIRO_TOOLS,
  DENTAL_TOOLS,
  DEFAULT_TOOLS,
} from "../consts/tools.consts";
import { ToolConfig } from "../tools.types";
import { USER_TYPES } from "consts";
import { useUserType } from "hooks/useUserType";

export const useToolConfigForUser = (): ToolConfig[] => {
  const { userType } = useUserType();
  //@ts-ignore
  return useMemo(() => {
    const userTypeTools = [
      ...DEFAULT_TOOLS,
      ...COMMON_MANIPULATION_TOOLS,
      ...COMMON_MEASUREMENT_TOOLS,
      ...MEDICAL_TOOLS,
    ];

    switch (userType) {
      case USER_TYPES.VET:
        userTypeTools.push(...VET_TOOLS);
        break;
      case USER_TYPES.CHIRO:
      case USER_TYPES.CHIRO_MEDICARE:
      case USER_TYPES.RADIOLOGIST:
        userTypeTools.push(...CHIRO_TOOLS);
        break;
      case USER_TYPES.DENTAL:
        userTypeTools.push(...DENTAL_TOOLS);
        break;
      case USER_TYPES.DEVELOPER:
        userTypeTools.push(...VET_TOOLS);
        userTypeTools.push(...DENTAL_TOOLS);
        userTypeTools.push(...CHIRO_TOOLS);
        break;
      default:
        console.warn(`No tools have been configured for ${userType}`);
    }
    return userTypeTools;
  }, [userType]);
};
