import cornerstone from "cornerstone-core";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useCallback, useEffect, useMemo } from "react";

export const useAxialImages = () => {
  const {
    dispatch,
    state: { viewports, isMPRActive, axialImages },
  } = useViewerContext();

  const imageIds = useMemo(
    () => (viewports.length && viewports[0].frames ? viewports[0].frames : []),
    [viewports]
  );
  const loadAxialImages = useCallback(async () => {
    const loadAxialImages = Promise.all(
      imageIds.map((imageId) => cornerstone.loadAndCacheImage(imageId))
    );
    const loadedAxialImages = await loadAxialImages.then((images) => images);
    dispatch({
      type: "LOAD_AXIAL_IMAGES",
      payload: { axialImages: loadedAxialImages },
    });
  }, [imageIds, dispatch]);

  useEffect(() => {
    if (isMPRActive && axialImages.length === 0) {
      loadAxialImages();
    }
  }, [loadAxialImages, isMPRActive]);
};
