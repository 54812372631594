import { useHomePageContext } from "pages/home-page/context/homepage.context";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { updateStudyValues } from "services/studies/studies";

export const useUpdateStudyData = () => {
  const {
    dispatch,
    state: { page },
  } = useHomePageContext();
  return useCallback(
    async (studypk, submissionData) => {
      const updateResult = await updateStudyValues(studypk, submissionData);
      if (updateResult.success) {
        toast.success(updateResult.message);
        dispatch({
          type: "UPDATE_ROW_ITEM",
          payload: { studypk, page, updatedStudyData: submissionData },
        });
        return submissionData;
      } else {
        toast.error(`Error updating study ${studypk}`);
        return {};
      }
    },
    [dispatch, page]
  );
};
