import { useAppContext } from "context/app.context";
import { useEffect } from "react";

export const useCanDisplaySettings = () => {
  const { dispatch } = useAppContext();
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.key === "0") {
        dispatch({ type: "TOGGLE_SETTINGS_PANEL", payload: {} });
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [dispatch]);
};
