import { Typography } from "@mui/material";
import { Box } from "@mui/system";

interface TableEmptyProps {
  displayText: string;
}
export const TableEmpty = ({ displayText }: TableEmptyProps) => {
  return (
    <tr>
      <td colSpan={10}>
        <Box
          style={{
            margin: "1rem 0rem",
            textAlign: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "larger",
            }}
          >
            {displayText}
          </Typography>
        </Box>
      </td>
    </tr>
  );
};
