const viewportIds = {
  CT: { AXIAL: "CT_AXIAL", SAGITTAL: "CT_SAGITTAL", CORONAL: "CT_CORONAL" },
  PT: { AXIAL: "PT_AXIAL", SAGITTAL: "PT_SAGITTAL", CORONAL: "PT_CORONAL" },
  FUSION: {
    AXIAL: "FUSION_AXIAL",
    SAGITTAL: "FUSION_SAGITTAL",
    CORONAL: "FUSION_CORONAL",
  },
  PETMIP: {
    CORONAL: "PET_MIP_CORONAL",
  },
};
export const MPR_STATUSES = {
  LOADING_AXIAL: "Loading All Axial Images",
  RECONSTRUCTING: "Reconstructing Image",
  COMPLETED: "Completed",
};
