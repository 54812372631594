import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";

const BaseTool = cornerstoneTools.importInternal("base/BaseTool");

const { wwwcCursor } = cornerstoneTools.import("tools/cursors");

export class Invert extends BaseTool {
  constructor(name = "Invert") {
    super({
      name,
      //strategies: { basicLevelingStrategy },
      supportedInteractionTypes: ["Mouse"], //, 'Touch'],
      configuration: {
        orientation: 0,
      },
      svgCursor: wwwcCursor,
    });
  }

  mouseClickCallback(evt) {
    const vp = cornerstone.getViewport(evt.detail.element);
    vp.invert = !vp.invert;
    cornerstone.setViewport(evt.detail.element, vp);
  }

  //For programatically executing the functionality
  autoPerform(element) {
    const vp = cornerstone.getViewport(element);
    if (typeof vp !== "undefined") {
      if (typeof vp.invert === "undefined") {
        vp.invert = false; //Initialize the invert property if not defined
      } else {
        vp.invert = !vp.invert; //Toggle the invert property
      }
      cornerstone.setViewport(element, vp);
    }
  }
}
