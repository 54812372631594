import { styled } from "@mui/system";
import Spinner from "react-bootstrap/Spinner";

const SpinnerContainer = styled("div")({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  fontSize: "0.8rem",
  span: {
    width: "100%",
    marginTop: "0.5rem",
  },
});

export const DicomOverlaySpinner = () => {
  return (
    <SpinnerContainer>
      <Spinner
        animation="border"
        role="status"
        style={{
          position: "relative",
          left: "10%",
          width: "2rem",
          height: "2rem",
          color: "#89cbe0",
        }}
      ></Spinner>
      <span>Retrieving MetaData...</span>
    </SpinnerContainer>
  );
};
