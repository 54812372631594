import { TableDropdown } from "components/dropdown/TableDropdown";
import { useCallback, useMemo, useState } from "react";

import { FIELD_KEYS } from "../../study-table-columns/columns.consts";
import { STATUS_OPTIONS } from "../studyTableRows.consts";
import { StudyData } from "../../studyTable.types";
import { PATIENT_FIELDS } from "../../studyTable.consts";
import { useUpdatePatientData } from "../../hooks/useUpdatePatientData";
import { useUpdateStudyData } from "../../hooks/useUpdateStudyData";
import { useIsLocked } from "../../hooks/useIsLocked";
import { getDropdownLabel } from "utils/formatting/formatValue";
import { convertStatusIdToValue } from "../../functions/convertStatusIdToValue";
import { convertStatusValueToId } from "../../functions/convertStatusValueToId";

interface ValueDropdownProps {
  studyData: StudyData;
  fieldName: string;
  isEditable: boolean;
}
export const ValueDropdown = ({ studyData, fieldName }: ValueDropdownProps) => {
  const { optionsConfig, handleUpdateValue, value } = useDropdownProps(
    studyData,
    fieldName
  );
  const isLocked = useIsLocked(studyData);
  if (isLocked) {
    return <span>{getDropdownLabel(value, STATUS_OPTIONS)}</span>;
  }
  return (
    <TableDropdown
      title={fieldName}
      value={value}
      optionsConfig={optionsConfig}
      onChange={handleUpdateValue}
    />
  );
};

const useDropdownProps = (studyData, fieldName) => {
  const updatePatientValues = useUpdatePatientData();
  const updateStudy = useUpdateStudyData();

  const initialValue = useMemo(() => {
    switch (fieldName) {
      case FIELD_KEYS.STATUS:
        const initialStatusValue = studyData[FIELD_KEYS.STATUS];
        return convertStatusIdToValue(initialStatusValue);
      default:
        return;
    }
  }, [fieldName, studyData]);

  const [value, setValue] = useState(initialValue);

  const handleUpdateValue = useCallback(
    async (updatedValue) => {
      const { studypk, patientpk } = studyData;
      const shouldUpdatePatient = PATIENT_FIELDS.includes(fieldName);
      setValue(updatedValue);
      const formattedValue =
        fieldName === FIELD_KEYS.STATUS
          ? convertStatusValueToId(updatedValue)
          : updatedValue;
      const submissionData = { [fieldName]: formattedValue };
      shouldUpdatePatient
        ? await updatePatientValues(studypk, patientpk, submissionData)
        : await updateStudy(studypk, submissionData);
    },
    [studyData, fieldName, updatePatientValues, updateStudy, setValue]
  );

  const optionsConfig = useMemo(() => {
    switch (fieldName) {
      case FIELD_KEYS.STATUS:
        return STATUS_OPTIONS;
      default:
        return [];
    }
  }, [fieldName]);

  return { optionsConfig, handleUpdateValue, value };
};
