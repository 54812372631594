import { useCallback } from "react";
import cornerstoneTools from "cornerstone-tools";

export const useInitialPerform = () => {
  return useCallback((selectedToolId: string, enabledElement) => {
    const toolInstance = cornerstoneTools.getToolForElement(
      enabledElement.element,
      selectedToolId
    );
    if (toolInstance && toolInstance.onToolActivation) {
      toolInstance.onToolActivation();
    }
  }, []);
};
