import { STATUS_IDS } from "../study-table-rows/studyTableRows.consts";

export const convertStatusIdToValue = (
  statusId: number | undefined | string
) => {
  if (statusId === undefined) return undefined;
  const numericStatus = Number(statusId);
  if (isNaN(numericStatus)) return undefined;
  const selectedStatus = STATUS_IDS.find(({ id }) => id === numericStatus);
  if (selectedStatus) {
    return selectedStatus.value;
  }
  return undefined;
};
