import { styled } from "@mui/system";
import { DicomViewerDetails } from "./overlay-details/DicomViewerDetails";
import { ImageViewportData } from "./overlay-details/ImageViewportData";
import { OverlayData } from "../../dicomViewer.types";
import {
  DICOM_DETAILS_HORIZONTAL_POSITIONS,
  DICOM_DETAILS_VERTICAL_POSITIONS,
} from "../../dicomViewer.consts";

const OverlayContainer = styled("div")(() => ({
  position: "absolute",
  height: "100%",
  width: "100%",
  pointerEvents: "none",
}));

interface DicomViewerOverlayProps {
  overlayData: OverlayData;
  isRetrievingImageMetaData: boolean;
}
export const DicomViewerOverlay = ({
  overlayData,
  isRetrievingImageMetaData,
}: DicomViewerOverlayProps) => {
  const {
    topLeftDetails,
    topRightDetails,
    bottomLeftDetails,
    bottomRightDetails,
  } = overlayData;
  return (
    <OverlayContainer>
      {topLeftDetails && (
        <DicomViewerDetails
          isRetrievingImageMetaData={isRetrievingImageMetaData}
          details={topLeftDetails}
          verticalPosition={DICOM_DETAILS_VERTICAL_POSITIONS.TOP}
          horizontalPosition={DICOM_DETAILS_HORIZONTAL_POSITIONS.LEFT}
        />
      )}
      {topRightDetails && (
        <DicomViewerDetails
          details={topRightDetails}
          verticalPosition={DICOM_DETAILS_VERTICAL_POSITIONS.TOP}
          horizontalPosition={DICOM_DETAILS_HORIZONTAL_POSITIONS.RIGHT}
        />
      )}
      {bottomLeftDetails && (
        <DicomViewerDetails
          details={bottomLeftDetails}
          verticalPosition={DICOM_DETAILS_VERTICAL_POSITIONS.BOTTOM}
          horizontalPosition={DICOM_DETAILS_HORIZONTAL_POSITIONS.LEFT}
        />
      )}
      {bottomRightDetails && <ImageViewportData details={bottomRightDetails} />}
    </OverlayContainer>
  );
};
