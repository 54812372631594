import { styled } from "@mui/system";
import { USER_TYPES } from "consts";
import { UserType } from "context/app.context.types";
import { useUserType } from "hooks/useUserType";
import { useMemo } from "react";
import { getUserTypeColorName } from "utils/colors/userColors";

const HomeIconImage = styled("img")(() => ({
  margin: "-15px 0px",
  height: "3.5rem",
}));

export const HomeIcon = () => {
  const logoUrl = useLogoUrl();
  return <HomeIconImage src={logoUrl} alt="ATX Logo" />;
};

const useLogoUrl = () => {
  const { userTypeColour } = useUserType();
  return useMemo(() => {
    const rootUrl = "../assets/images/icons/home-icon/atx-logo";
    const typeSpecificImageSuffix = getUserTypeColorName(userTypeColour);
    return `${rootUrl}-${typeSpecificImageSuffix}.png`;
  }, [userTypeColour]);
};
