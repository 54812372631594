import { useHomePageContext } from "pages/home-page/context/homepage.context";
import { useMemo } from "react";

export const useStudyFilterCount = () => {
  const {
    state: { filters },
  } = useHomePageContext();
  return useMemo(() => {
    return Object.keys(filters).length;
  }, [filters]);
};
