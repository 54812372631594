import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/system";
import { DarkModeSwitch } from "components/DarkmodeSwitch/DarkmodeSwitch";
import { useDarkModeTheme } from "hooks/useDarkmodeTheme";

const ErrorContainer = styled("div")({
  color: "#000000",
  fontFamily: "'Nunito Sans', sans-serif",
  fontSize: "11rem",
  position: "absolute",
  left: "20%",
  top: "8%",
});

const Far = styled("div")({
  position: "absolute",
  fontSize: "8.5rem",
  left: "41%",
  top: "12%",
  color: "#000000",
});

const ErrorContainer2 = styled("div")({
  color: "#000000",
  fontFamily: "'Nunito Sans', sans-serif",
  fontSize: "11rem",
  position: "absolute",
  left: "68%",
  top: "8%",
});

const ErrorMessage = styled("div")({
  textAlign: "center",
  fontFamily: "'Nunito Sans', sans-serif",
  fontSize: "1.6rem",
  position: "absolute",
  left: "16%",
  top: "45%",
  width: "75%",
});

const HomeLink = styled("a")({
  textDecoration: "none",
  color: "black",
});

const AHover = styled("a")({
  textDecoration: "underline",
});

const PageNotFound = () => {
  const { backgroundThemeColor, textThemeColor } = useDarkModeTheme();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.25 }}
      exit={{ opacity: 0 }}
    >
      <DarkModeSwitch />
      <div style={{ backgroundColor: backgroundThemeColor }}>
        <div
          style={{
            height: "600px",
            width: "600px",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div>
            <ErrorContainer style={{ color: textThemeColor }}>4</ErrorContainer>
            <Far>
              <FontAwesomeIcon
                style={{ color: textThemeColor }}
                icon={faQuestionCircle}
                spin
              />
            </Far>

            <ErrorContainer2 style={{ color: textThemeColor }}>
              4
            </ErrorContainer2>
          </div>
          <ErrorMessage style={{ color: textThemeColor }}>
            Oh snap! Maybe this page moved? Got deleted? Is hiding out in
            quarantine? Never existed in the first place?
            <p>
              Let's go{" "}
              <HomeLink style={{ color: "blue" }} href="/">
                home
              </HomeLink>{" "}
              and try from there.
            </p>
          </ErrorMessage>
        </div>
      </div>
    </motion.div>
  );
};

export default PageNotFound;
