// @ts-nocheck
import { FormField, TextFormField } from "./Form.types";
import { FORM_FIELDS } from "./form.consts";
import { FormTextInput } from "./components/FormTextInput";
import { DateInput } from "./components/FormDateInput";
import { FormDropdown } from "./components/FormDropdown";
import { FormCheckbox } from "./components/checkbox/FormCheckbox";

interface FormFieldProps {
  value: string | null;
  fieldName: string;
  field: FormField;
}
export const FormFields = ({ value, fieldName, field }: FormFieldProps) => {
  switch (field.inputType) {
    case FORM_FIELDS.DATE:
      return <DateInput fieldName={fieldName} label={field.label} />;
    case FORM_FIELDS.DROPDOWN: {
      const { optionsConfig } = field;
      return (
        <FormDropdown
          value={value}
          fieldName={fieldName}
          label={field.label}
          optionsConfig={optionsConfig}
        />
      );
    }
    case FORM_FIELDS.CHECKBOX:
      return <FormCheckbox fieldName={fieldName} label={field.label} />;
    case FORM_FIELDS.TEXT:
    default: {
      const { maxLength, numericOnly } = field as TextFormField;
      return (
        <FormTextInput
          value={value}
          fieldName={fieldName}
          label={field.label}
          required={field.required}
          maxLength={maxLength}
          numericOnly={numericOnly}
        />
      );
    }
  }
};
