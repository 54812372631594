import { RadiologySite } from "models/study.types";
import { LOGO_HEIGHT } from "../googleDocs.consts";

export const setUpDocumentHeader = (
  siteInfo: RadiologySite | null,
  headerId: string,
  institutionName: string
) => {
  const headerDetails = [];
  if (siteInfo) {
    const { headerUrl, address, phone, siteName } = siteInfo;
    const header = getHeader(siteName, headerId, headerUrl);
    if (header.logoDetails) {
      headerDetails.push(header.logoDetails);
    } else {
      headerDetails.push(header.titleDetails);
      headerDetails.push(header.titleStyles);
    }

    const addressStartIndex = header.headerIndex;
    const { addressDetails, addressStyle, addressEndIndex } =
      institutionAddress(address ?? "", addressStartIndex, headerId);
    headerDetails.push(addressDetails);
    headerDetails.push(addressStyle);

    const phoneStartIndex = addressEndIndex;
    const { phoneDetails, phoneStyle, phoneEndIndex } = institutionPhoneNumber(
      phone ?? "",
      phoneStartIndex,
      headerId
    );
    headerDetails.push(phoneDetails);
    headerDetails.push(phoneStyle);

    const paragraphStyle = headerParagraphStyles(phoneEndIndex, headerId);
    headerDetails.push(paragraphStyle);
  } else if (institutionAddress && institutionName !== "Unknown") {
    const { titleDetails, titleStyles } = institutionTitle(
      institutionName,
      headerId
    );
    headerDetails.push(titleDetails);
    headerDetails.push(titleStyles);
    const paragraphStyle = headerParagraphStyles(
      institutionName.length,
      headerId
    );
    headerDetails.push(paragraphStyle);
  }
  return headerDetails;
};

const getHeader = (
  siteName: string,
  headerId: string,
  headerUrl?: string | null
) => {
  if (headerUrl && headerUrl.length) {
    const logoDetails = institutionLogo(headerUrl, headerId);
    return { logoDetails, headerIndex: 1 };
  } else {
    const { titleDetails, titleStyles } = institutionTitle(siteName, headerId);
    return { titleDetails, titleStyles, headerIndex: siteName.length };
  }
};

const institutionTitle = (siteName: string, headerId: string) => ({
  titleDetails: {
    insertText: {
      location: {
        segmentId: headerId,
        index: 0,
      },
      text: `${siteName} `,
    },
  },

  titleStyles: {
    updateTextStyle: {
      textStyle: {
        bold: true,
        fontSize: {
          magnitude: 18,
          unit: "PT",
        },
        weightedFontFamily: {
          fontFamily: "Cabin",
        },
      },
      fields: "bold, fontSize, weightedFontFamily",
      range: {
        segmentId: headerId,
        startIndex: 0,
        endIndex: siteName.length,
      },
    },
  },
});

const institutionLogo = (headerUrl: string, headerId: string) => ({
  insertInlineImage: {
    uri: headerUrl,
    objectSize: {
      height: {
        magnitude: LOGO_HEIGHT,
        unit: "PT",
      },
    },
    location: {
      segmentId: headerId,
      index: 0,
    },
  },
});

const institutionAddress = (address, addressStartIndex, headerId) => {
  const addressText = `\n ${address} `;
  const addressEndIndex = addressStartIndex + addressText.length;
  return {
    addressDetails: {
      insertText: {
        location: {
          segmentId: headerId,
          index: addressStartIndex,
        },
        text: addressText,
      },
    },
    addressStyle: {
      updateTextStyle: {
        textStyle: {
          bold: true,
          fontSize: {
            magnitude: 11,
            unit: "PT",
          },
          weightedFontFamily: {
            fontFamily: "Cabin",
          },
        },
        fields: "bold, fontSize, weightedFontFamily", //"bold",
        range: {
          segmentId: headerId,
          startIndex: addressStartIndex,
          endIndex: addressEndIndex,
        },
      },
    },
    addressEndIndex,
  };
};

const institutionPhoneNumber = (phoneNumber, phoneStartIndex, headerId) => {
  const phoneText = `\n ${phoneNumber} `;
  const phoneEndIndex = phoneStartIndex + phoneText.length;
  return {
    phoneEndIndex,
    phoneDetails: {
      insertText: {
        location: {
          segmentId: headerId,
          index: phoneStartIndex,
        },
        text: phoneText,
      },
    },

    phoneStyle: {
      updateTextStyle: {
        textStyle: {
          fontSize: {
            magnitude: 10,
            unit: "PT",
          },
          weightedFontFamily: {
            fontFamily: "Cabin",
          },
        },
        fields: "fontSize, weightedFontFamily",
        range: {
          segmentId: headerId,
          startIndex: phoneStartIndex,
          endIndex: phoneEndIndex,
        },
      },
    },
  };
};

const headerParagraphStyles = (endIndex: number, headerId: string) => ({
  updateParagraphStyle: {
    paragraphStyle: {
      alignment: "CENTER",
      lineSpacing: 100,
    },
    fields: "alignment, lineSpacing",
    range: {
      segmentId: headerId,
      startIndex: 0,
      endIndex,
    },
  },
});
