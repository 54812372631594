import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";

const BaseTool = cornerstoneTools.importInternal("base/BaseTool");

const { wwwcCursor } = cornerstoneTools.import("tools/cursors");

export class VFlip extends BaseTool {
  constructor(name = "VFlip") {
    super({
      name,
      //strategies: { basicLevelingStrategy },
      supportedInteractionTypes: ["Mouse"], //, 'Touch'],
      configuration: {
        orientation: 0,
      },
      svgCursor: wwwcCursor,
    });
  }

  mouseClickCallback(evt) {
    let vp = cornerstone.getViewport(evt.detail.element);
    vp.vflip = !vp.vflip;
    cornerstone.setViewport(evt.detail.element, vp);
  }

  //For programatically executing the functionality
  autoPerform(element) {
    let vp = cornerstone.getViewport(element);
    if (typeof vp !== "undefined") {
      if (typeof vp.vflip === "undefined") {
        vp.vflip = false; //Initialize the vflip property if not defined
      } else {
        vp.vflip = !vp.vflip; //Toggle the vflip property
      }
      cornerstone.setViewport(element, vp);
    }
  }
}
