import { useHomePageContext } from "pages/home-page/context/homepage.context";
import { useMemo } from "react";
import { StudyData } from "../studyTable.types";

export const useIsLocked = (studyData: StudyData) => {
  const {
    state: { unlockedRows, lockedRows },
  } = useHomePageContext();
  return useMemo(() => {
    const { editLock, studypk } = studyData;
    if (lockedRows.includes(studypk)) {
      return true;
    } else if (unlockedRows.includes(studypk)) {
      return false;
    } else {
      return Boolean(editLock);
    }
  }, [studyData, unlockedRows, lockedRows]);
};
