import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const DicomViewerHelpDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <HelpIcon sx={{ color: "white" }} />
      </IconButton>
      <Dialog maxWidth={"xl"} onClose={handleClose} open={isOpen}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Help
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            1. Interact with the image using your mouse: left click drag -
            ww/wl, middle click drag - pan, right click drag - zoom, mouse wheel
            - scroll through stack
          </Typography>
          <Typography gutterBottom>
            2. Select another series to display by left click the series list on
            the left
          </Typography>
          <Typography gutterBottom>
            3. Select another tool to use with the left mouse button by
            selecting the tool button
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
