import { motion } from "framer-motion";
import { DarkModeSwitch } from "components/DarkmodeSwitch/DarkmodeSwitch";
import { useDarkModeTheme } from "hooks/useDarkmodeTheme";
import { useMemo } from "react";

const IMAGE_URL_ROOT = ".../assets/images/error/";
const ErrorPage = () => {
  const { isDarkModeOn, textThemeColor } = useDarkModeTheme();

  const brokenBoneImageSrc = useMemo(
    () =>
      isDarkModeOn
        ? `${IMAGE_URL_ROOT}BrokenBoneImageWhite.png`
        : `${IMAGE_URL_ROOT}BrokenBoneImageBlack.png`,
    [isDarkModeOn]
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.25 }}
      exit={{ opacity: 0 }}
    >
      <>
        <DarkModeSwitch />
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            color: textThemeColor,
          }}
        >
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="" />
          <meta name="author" content="" />

          <title>Sorry, This Page Can't Be Accessed</title>
          <link
            rel="stylesheet"
            href={
              "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css"
            }
          />
          <link
            rel="stylesheet"
            href={
              "https://stackpath.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css"
            }
            integrity="sha384-WskhaSGFgHYWDcbwN70/dfYBj47jz9qbsMId/iRN3ewGhXQFZCSftd1LZCfmhktB"
            crossOrigin="anonymous"
          />
          <div className="container py-5">
            <div className="row">
              <div className="col-md-2 text-center">
                <img
                  src={brokenBoneImageSrc}
                  alt="Broken Bone"
                  style={{ width: "150%", height: "auto" }}
                />
              </div>
              <div className="col-md-10">
                <h3>Oh snap!! Sorry...</h3>
                <p>
                  Sorry, your access is refused for security reasons to protect
                  our sensitive data.
                  <br />
                  Please go back to the previous page to continue browsing.
                </p>
                <button
                  className="btn btn-danger"
                  onClick={() => window.history.go(-2)}
                  style={{ backgroundColor: "#7f1c53", borderColor: "#7f1c53" }}
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </motion.div>
  );
};

export default ErrorPage;
