import { useMemo } from "react";
import {
  CHIRO_MEDICARE_ADVANCED,
  DEFAULT_ADVANCED,
  INSTITUTION_DEFAULT,
  LONG_ADVANCED,
  MEDICARE_ADMIN_DEFAULT,
  STUDY_FILTER_FORM,
} from "./studyFilter.consts";
import { useUserType } from "hooks/useUserType";
import { USER_TYPES } from "consts";

export const useStudyFilterForm = () => {
  const { userType } = useUserType();
  return useMemo(() => {
    const defaultForm = { ...STUDY_FILTER_FORM, ...DEFAULT_ADVANCED };
    switch (userType) {
      case USER_TYPES.ADMIN:
        return { ...defaultForm, ...INSTITUTION_DEFAULT };
      case USER_TYPES.MEDICARE_ADMIN:
        return {
          ...MEDICARE_ADMIN_DEFAULT,
          ...defaultForm,
          ...INSTITUTION_DEFAULT,
          ...DEFAULT_ADVANCED,
        };
      case USER_TYPES.CHIRO_MEDICARE:
        return {
          ...defaultForm,
          ...DEFAULT_ADVANCED,
          ...CHIRO_MEDICARE_ADVANCED,
        };
      case USER_TYPES.RADIOLOGIST:
        return {
          ...defaultForm,
          ...INSTITUTION_DEFAULT,
          ...DEFAULT_ADVANCED,
          ...LONG_ADVANCED,
        };
      default:
        return {
          ...defaultForm,
        };
    }
  }, [userType]);
};
