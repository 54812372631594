import { USER_TYPES } from "consts";
import { useUserType } from "hooks/useUserType";
import { useCallback, useMemo, useState } from "react";
import { ColumnConfig } from "components/table/table.types";
import {
  CHIRO_MEDICARE_EDITABLE_FIELDS,
  CLAIM_COLUMN,
  DEFAULT_COLUMNS,
  DOB_COLUMN,
  DOCTOR_COLUMN,
  DOCUMENTS_COLUMN,
  EDITABLE_MEDICARE_ADMIN_FIELDS,
  FIELD_KEYS,
  INSTITUTION_COLUMN,
  LOCK_COLUMN,
  MEDICARE_COLUMN,
  MOD_COLUMN,
  PATIENT_NAME_COLUMN,
  PID_COLUMN,
  PK_COLUMN,
  RADIOLOGIST_EDITABLE_FIELDS,
  SELECT_COLUMN,
  SERVICES_COLUMN,
  SEX_COLUMN,
  SORTABLE_COLUMNS,
  SORT_DIRECTIONS,
  STATUS_COLUMN,
  STUDY_COLUMN,
  STUDY_DATE_COLUMN,
  STUDY_PROCESS_COLUMN,
  VERIFIED_COLUMN,
  XCALIBER_COLUMN,
} from "./columns.consts";
import { useHomePageContext } from "pages/home-page/context/homepage.context";

export const useColumnConfigs = () => {
  const { userType } = useUserType();
  const [sortingDirection, setSortingDirection] = useState<string>(
    SORT_DIRECTIONS.DESC
  );
  const { dispatch } = useHomePageContext();
  const [sortingColumn, setSortingColumn] = useState<string>(
    FIELD_KEYS.STUDY_DATETIME
  );

  const { columns, editable } = useMemo(() => {
    switch (userType) {
      case USER_TYPES.VET_XCALIBER: {
        const columns = [...DEFAULT_COLUMNS, XCALIBER_COLUMN];
        return { columns, editable: [] };
      }
      case USER_TYPES.ADMIN: {
        const columns = [
          SELECT_COLUMN,
          PID_COLUMN,
          STUDY_DATE_COLUMN,
          PATIENT_NAME_COLUMN,
          DOB_COLUMN,
          SEX_COLUMN,
          DOCTOR_COLUMN,
          INSTITUTION_COLUMN,
          MOD_COLUMN,
          STUDY_COLUMN,
        ];
        return { columns, editable: [] };
      }

      case USER_TYPES.MEDICARE_ADMIN: {
        const medicareAdminColumns = [
          SELECT_COLUMN,
          PK_COLUMN,
          STUDY_DATE_COLUMN,
          STUDY_PROCESS_COLUMN,
          LOCK_COLUMN,
          STATUS_COLUMN,
          PID_COLUMN,
          MEDICARE_COLUMN,
          SERVICES_COLUMN,
          PATIENT_NAME_COLUMN,
          DOB_COLUMN,
          SEX_COLUMN,
          DOCTOR_COLUMN,
          INSTITUTION_COLUMN,
          DOCUMENTS_COLUMN,
          CLAIM_COLUMN,
          VERIFIED_COLUMN,
          MOD_COLUMN,
          STUDY_COLUMN,
        ];
        return {
          columns: medicareAdminColumns,
          editable: EDITABLE_MEDICARE_ADMIN_FIELDS,
        };
      }

      case USER_TYPES.CHIRO_MEDICARE: {
        const chiroColumns = [
          SELECT_COLUMN,
          LOCK_COLUMN,
          PID_COLUMN,
          STUDY_DATE_COLUMN,
          STATUS_COLUMN,
          MEDICARE_COLUMN,
          SERVICES_COLUMN,
          PATIENT_NAME_COLUMN,
          DOB_COLUMN,
          SEX_COLUMN,
          DOCTOR_COLUMN,
          DOCUMENTS_COLUMN,
          CLAIM_COLUMN,
          VERIFIED_COLUMN,
          MOD_COLUMN,
          STUDY_COLUMN,
        ];
        return {
          columns: chiroColumns,
          editable: CHIRO_MEDICARE_EDITABLE_FIELDS,
        };
      }

      case USER_TYPES.RADIOLOGIST: {
        const radiologistColumns = [
          SELECT_COLUMN,
          PID_COLUMN,
          STUDY_DATE_COLUMN,
          STUDY_PROCESS_COLUMN,
          LOCK_COLUMN,
          STATUS_COLUMN,
          MEDICARE_COLUMN,
          SERVICES_COLUMN,
          PATIENT_NAME_COLUMN,
          DOB_COLUMN,
          SEX_COLUMN,
          DOCTOR_COLUMN,
          INSTITUTION_COLUMN,
          DOCUMENTS_COLUMN,
          CLAIM_COLUMN,
          VERIFIED_COLUMN,
          MOD_COLUMN,
          STUDY_COLUMN,
        ];
        return {
          columns: radiologistColumns,
          editable: RADIOLOGIST_EDITABLE_FIELDS,
        };
      }

      case USER_TYPES.MEDICAL:
      case USER_TYPES.DENTAL:
      case USER_TYPES.CHIRO:
      case USER_TYPES.VET:
      case USER_TYPES.PUBLIC:
      default: {
        return {
          columns: DEFAULT_COLUMNS,
          editable: RADIOLOGIST_EDITABLE_FIELDS,
        };
      }
    }
  }, [userType]) as { columns: ColumnConfig[]; editable: string[] };

  const onColumnClick = useCallback(
    (columnId, columnDirection) => {
      setSortingColumn(columnId);
      setSortingDirection(columnDirection);
      dispatch({ type: "CHANGE_QUERY_ARGS" });
    },
    [setSortingColumn, setSortingDirection, dispatch]
  );

  const sortableColumns = useMemo(() => [...SORTABLE_COLUMNS], []);
  return {
    columns,
    editable,
    sortingColumn,
    sortingDirection,
    onColumnClick,
    sortableColumns,
  };
};
