import { useUserType } from "hooks/useUserType";
import { AppTheme } from "./theme.types";
import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";

export const useAppTheme = (): AppTheme => {
  const { userTypeColour } = useUserType();
  const siteTheme = useTheme();

  // declaring it at app root and passing it down through props is cumbersome
  return useMemo(
    () => ({
      ...siteTheme,
      palette: {
        ...siteTheme.palette,
        primary: { main: userTypeColour },
      },
    }),
    [siteTheme, userTypeColour]
  ) as unknown as AppTheme;
};
