import { styled } from "@mui/system";
import * as React from "react";
import Spinner from "react-bootstrap/Spinner";

const ToolSpinnerContainer = styled("div")({
  position: "relative",
  textAlign: "center",
  span: {
    width: "100%",
    marginTop: "0.5rem",
  },
});

export const ToolSpinner = () => {
  return (
    <ToolSpinnerContainer>
      <Spinner
        animation="border"
        role="status"
        style={{
          position: "relative",
          width: "20px",
          height: "20px",
          color: "black",
        }}
      ></Spinner>
    </ToolSpinnerContainer>
  );
};
