import cornerstoneTools from "cornerstone-tools";
import Util from "../../api/Util";
import { getDimensionData } from "../measurementToolUtils";
import { ImageMetadata } from "pages/viewer/dicomViewer.types";
import { TOOL_IDS } from "../../consts/tools.consts";
import Drawing from "../../api/Drawing";
import toolStyle from "../../api/state-management/toolStyle";
import cornerstone, { triggerEvent } from "cornerstone-core";
import toolColors from "../../api/state-management/toolColours";
import { DEFAULT_HANDLE, DEFAULT_LINE_DASH } from "../../consts/tools.defaults";
import Manipulators from "../../api/Manipulators";
const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

const ANGLE_CONFIG = {
  drawHandles: true,
  drawHandlesOnHover: false,
  hideHandlesIfMoving: false,
  renderDashed: false,
};
/**
 * @public
 * @class AngleTool
 * @memberof Tools.Annotation
 * @classdesc Create and position an angle by placing three consecutive points.
 * @extends Tools.Base.BaseAnnotationTool
 * @hideconstructor
 *
 * @param {ToolConfiguration} [props={}]
 */
export default class AngleTool extends BaseAnnotationTool {
  preventNewMeasurement: boolean;
  throttledUpdateCachedStats: any;
  toolId: string;
  imageMetadata: ImageMetadata;
  constructor(props = {}) {
    const defaultProps = {
      name: "Angle",
      supportedInteractionTypes: ["Mouse", "Touch"],
    };
    super(props, defaultProps);
    this.toolId = TOOL_IDS.ANGLE;
    this.preventNewMeasurement = false;
    this.throttledUpdateCachedStats = Util.throttle(
      this.updateCachedStats,
      110
    );
    this.imageMetadata = {};
    this.setImageMetadata = this.setImageMetadata.bind(this);
  }
  public setImageMetadata(imageMetaData: ImageMetadata) {
    if (imageMetaData) {
      this.imageMetadata = imageMetaData;
    }
  }

  createNewMeasurement(eventData) {
    // Create the measurement data for this tool with the end handle activated
    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        middle: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    if (data.visible === false) {
      return false;
    }

    return (
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.middle,
        coords
      ) < 25 ||
      Util.lineSegDistance(
        element,
        data.handles.middle,
        data.handles.end,
        coords
      ) < 25
    );
  }

  updateCachedStats(image, element, data) {
    const sideA = getSide(
      image,
      data.handles.middle,
      data.handles.start,
      this.imageMetadata
    );
    const sideB = getSide(
      image,
      data.handles.end,
      data.handles.middle,
      this.imageMetadata
    );
    const sideC = getSide(
      image,
      data.handles.end,
      data.handles.start,
      this.imageMetadata
    );

    const sideALength = length(sideA);
    const sideBLength = length(sideB);
    const sideCLength = length(sideC);

    // Cosine law
    let angle = Math.acos(
      (Math.pow(sideALength, 2) +
        Math.pow(sideBLength, 2) -
        Math.pow(sideCLength, 2)) /
        (2 * sideALength * sideBLength)
    );

    angle *= 180 / Math.PI;

    data.rAngle = Util.roundToDecimal(angle, 2);
    data.invalidated = false;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const enabledElement = eventData.enabledElement;
    const {
      drawHandles,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
    } = ANGLE_CONFIG;
    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = cornerstoneTools.getToolState(
      evt.currentTarget,
      this.toolId
    );
    const lineDash = DEFAULT_LINE_DASH;

    if (!toolData) {
      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const context = Drawing.getNewContext(eventData.canvasContext.canvas);
    const { image, element } = eventData;
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetadata
    );

    const lineWidth = toolStyle.getToolWidth();

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      Drawing.draw(context, (context) => {
        Drawing.setShadow(context, ANGLE_CONFIG);

        // Differentiate the color of activation tool
        const color = toolColors.getColorIfActive(data);

        const handleStartCanvas = cornerstone.pixelToCanvas(
          eventData.element,
          data.handles.start
        );
        const handleMiddleCanvas = cornerstone.pixelToCanvas(
          eventData.element,
          data.handles.middle
        );

        const lineOptions = { color };

        Drawing.drawJoinedLines(
          context,
          eventData.element,
          data.handles.start,
          [data.handles.middle, data.handles.end],
          lineOptions
        );

        // Draw the handles
        const handleOptions = {
          ...DEFAULT_HANDLE,
          color,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        if (drawHandles) {
          Drawing.drawHandles(context, eventData, data.handles, handleOptions);
        }

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.rAngle) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        if (data.rAngle) {
          const text = textBoxText(data, rowPixelSpacing, colPixelSpacing);

          const distance = 15;

          let textCoords;

          if (!data.handles.textBox.hasMoved) {
            textCoords = {
              x: handleMiddleCanvas.x,
              y: handleMiddleCanvas.y,
            };

            const padding = 5;
            const textWidth = Drawing.textBoxWidth(context, text, padding);

            if (handleMiddleCanvas.x < handleStartCanvas.x) {
              textCoords.x -= distance + textWidth + 10;
            } else {
              textCoords.x += distance;
            }

            const transform = cornerstone.internal.getTransform(enabledElement);

            transform.invert();

            const coords = transform.transformPoint(textCoords.x, textCoords.y);

            data.handles.textBox.x = coords.x;
            data.handles.textBox.y = coords.y;
          }

          Drawing.drawLinkedTextBox(
            context,
            eventData.element,
            data.handles.textBox,
            text,
            data.handles,
            textBoxAnchorPoints,
            color,
            lineWidth,
            0,
            true
          );
        }
      });
    }

    function textBoxText(data, rowPixelSpacing, colPixelSpacing) {
      const suffix = !rowPixelSpacing || !colPixelSpacing ? " (isotropic)" : "";
      const str = "00B0"; // Degrees symbol

      return (
        data.rAngle.toString() + String.fromCharCode(parseInt(str, 16)) + suffix
      );
    }

    function textBoxAnchorPoints(handles) {
      return [handles.start, handles.middle, handles.end];
    }
  }

  addNewMeasurement(evt, interactionType) {
    if (this.preventNewMeasurement) {
      return;
    }

    this.preventNewMeasurement = true;
    evt.preventDefault();
    evt.stopPropagation();

    const eventData = evt.detail;
    const measurementData = this.createNewMeasurement(eventData);
    const element = evt.detail.element;

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstoneTools.addToolState(element, this.toolId, measurementData);
    cornerstone.updateImage(element);

    // Step 1, create start and second middle.
    Manipulators.moveNewHandle(
      eventData,
      this.toolId,
      measurementData,
      measurementData.handles.middle,
      {},
      interactionType,
      (success) => {
        measurementData.active = false;

        if (!success) {
          cornerstoneTools.removeToolState(
            element,
            this.toolId,
            measurementData
          );

          this.preventNewMeasurement = false;

          return;
        }

        measurementData.handles.end.active = true;

        cornerstone.updateImage(element);

        // Step 2, create end.
        Manipulators.moveNewHandle(
          eventData,
          this.toolId,
          measurementData,
          measurementData.handles.end,
          {},
          interactionType,
          (success) => {
            if (success) {
              measurementData.active = false;
              cornerstone.updateImage(element);
            } else {
              cornerstoneTools.removeToolState(
                element,
                this.toolId,
                measurementData
              );
            }

            this.preventNewMeasurement = false;
            cornerstone.updateImage(element);

            const modifiedEventData = {
              toolName: this.toolId,
              toolType: this.toolId, // Deprecation notice: toolType will be replaced by toolName
              element,
              measurementData,
            };

            triggerEvent(
              element,
              cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
              modifiedEventData
            );
          }
        );
      }
    );
  }
}

function length(vector) {
  return Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
}

function getSide(image, handleEnd, handleStart, imageMetadata: ImageMetadata) {
  const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
    image,
    imageMetadata
  );

  return {
    x: (handleEnd.x - handleStart.x) * (colPixelSpacing || 1),
    y: (handleEnd.y - handleStart.y) * (rowPixelSpacing || 1),
  };
}
