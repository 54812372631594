import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Container, CssBaseline, Grid } from "@mui/material";
import { Navigate } from "react-router-dom";
import { motion } from "framer-motion";
import { DicomPageLoader } from "./components/dicom-page-loader/DicomPageLoader";
import { useStudyImageData } from "./hooks/useStudyImageData";
import { DicomViewerOverlay } from "./components/dicom-viewer-overlay/DicomViewerOverlay";
import { useFormatMetaData } from "./hooks/metadata/useFormatMetaData";
import { DicomViewerToolbar } from "./components/dicom-viewer-toolbar/DicomViewerToolbar";
import { DicomImageBar } from "./components/dicom-image-bar/DicomImageBar";
import { useDicomViewports } from "./hooks/useDicomViewports";
import { useGridControllerActions } from "./components/dicom-grid-controller/useGridControllerActions";
import { useScrollImageStack } from "./tools/default-tools/scroll-stack/useScrollImageStack";
import { useSavedAnnotations } from "./tools/default-tools/save-annotations/useSavedAnnotations";
import { useTools } from "./tools/hooks/useTools";
import { ViewerHeaderBar } from "./components/viewer-header-bar/ViewerHeaderBar";
import { useViewerContext, ViewerProvider } from "./context/viewer.context";
import { useFramedModality } from "./hooks/useFramedModality";
import { usePlayPauseFrames } from "./tools/framed-modality-tools/usePlayFrames";
import { useMPRTool } from "./tools/mpr-tool/useMPRTool";
import CornerstoneViewport from "./components/cornerstone-viewport/CornerstoneViewport";
import { useRetrieveDicomMetaData } from "./hooks/metadata/useRetrieveDicomMetaData";
import { getOverlayData } from "./helpers/getOverlayData";

export const DicomViewer = () => {
  return (
    <ViewerProvider>
      <Viewer />
    </ViewerProvider>
  );
};

const Viewer = () => {
  const [queryParameters] = useSearchParams();

  const { studyPk, studyIuid } = useMemo(() => {
    const encodedParams = queryParameters.entries().next().value[0];
    const decodedParams = atob(encodedParams);
    const urlSearchParams = new URLSearchParams(decodedParams);
    const studyPk = urlSearchParams.get("pkid");
    const studyIuid = urlSearchParams.get("pkuid");
    return { studyPk, studyIuid };
  }, [queryParameters]);

  const {
    state: { studyImageData, isMPRActive },
  } = useViewerContext();
  const { isLoading } = useStudyImageData(studyPk, studyIuid);

  const { viewports, onChangeActiveViewport, changeViewportDataOnDrag } =
    useDicomViewports();

  const { isRetrievingImageMetaData } = useRetrieveDicomMetaData(
    viewports,
    studyImageData
  );

  const { imageMetaData } = useFormatMetaData(viewports);
  const { tools, onToolButtonClick, addToolCallback } = useTools();

  useSavedAnnotations(viewports);
  const { gridDimensions } = useGridControllerActions();
  const isPlaying = usePlayPauseFrames();
  const { isFramedModality } = useFramedModality();
  useScrollImageStack(
    studyImageData?.length ?? 0,
    onChangeActiveViewport,
    tools
  );

  useMPRTool();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.25,
      }}
      exit={{ opacity: 0 }}
    >
      {isLoading ? (
        <DicomPageLoader />
      ) : (
        <>
          {studyImageData !== null ? (
            <Box sx={{ display: "flex", backgroundColor: "black" }}>
              <CssBaseline />
              <ViewerHeaderBar />
              <Container
                maxWidth={false}
                sx={{ mt: 6, backgroundColor: "black" }}
              >
                <DicomViewerToolbar
                  setActiveTool={onToolButtonClick}
                  tools={tools.filter((item) => !!item.iconFileName)}
                  isLoadingMetaData={isRetrievingImageMetaData}
                />
                <DicomImageBar
                  activeImages={viewports.map(
                    ({ studyImageIndex }) => studyImageIndex
                  )}
                  studyImageData={studyImageData}
                  setImageActive={onChangeActiveViewport}
                  onImageDrag={changeViewportDataOnDrag}
                />

                <Grid container spacing={0}>
                  {viewports.map(({ imageIds, images, frames }, index) => {
                    const viewerImageIds =
                      isFramedModality && frames ? frames : imageIds;
                    const overlayData = getOverlayData(
                      isMPRActive,
                      index,
                      imageMetaData
                    );

                    return (
                      <Grid item key={index} xs={12 / gridDimensions[0]}>
                        <CornerstoneViewport
                          key={`${viewports.length}-viewport-${index}`}
                          imageIds={viewerImageIds}
                          loadedImages={images}
                          isPlaying={isPlaying}
                          viewportIndex={index}
                          registerToolsToViewport={addToolCallback}
                          viewportOverlayComponent={() => (
                            <DicomViewerOverlay
                              overlayData={overlayData}
                              isRetrievingImageMetaData={
                                isRetrievingImageMetaData
                              }
                            />
                          )}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Container>
            </Box>
          ) : (
            <Navigate to="/error" />
          )}
        </>
      )}
    </motion.div>
  );
};
