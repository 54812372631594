import { useCallback, useEffect } from "react";
import { useUserType } from "./useUserType";
import { USER_TYPES } from "consts";
import { retrieveXcaliberStatus } from "services/xCaliber";
import { useAppContext } from "context/app.context";

export const useSetXCaliberStatus = () => {
  const { userType } = useUserType();
  const {
    dispatch,
    state: { userId, xcaliberStatus },
  } = useAppContext();
  const fetchXCaliberStatus = useCallback(async () => {
    if (xcaliberStatus === null) {
      // @ts-ignore
      const { data } = await retrieveXcaliberStatus(userId);
      if (
        data &&
        data.resultData &&
        data.resultData.subsStts == "subs" &&
        data.resultData.subsDtsStts == "subs"
      ) {
        dispatch({
          type: "SET_XCALIBER_STATUS",
          payload: { xcaliberStatus: true },
        });
      }
    }
  }, [userId, dispatch, xcaliberStatus]);

  useEffect(() => {
    if (userType === USER_TYPES.VET_XCALIBER && userId) {
      fetchXCaliberStatus();
    }
  }, [fetchXCaliberStatus, userType]);
};
