// @ts-nocheck
import cornerstoneTools from "cornerstone-tools";
import Drawing from "../api/Drawing";
import Util from "../api/Util";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

export class CentrePoint extends BaseAnnotationTool {
  hasIncomplete: boolean;
  constructor() {
    super({
      name: "CenterPoint",
      supportedInteractionTypes: ["Mouse", "Touch"],
    });
    this.hasIncomplete = false;
  }

  activeCallback(element) {}

  preMouseDownCallback(event) {}

  createNewMeasurement(event) {
    //store measurement
    const goodEventData =
      event && event.currentPoints && event.currentPoints.image;
    if (!goodEventData) {
      // //console.log(
      //     `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      // );
      return;
    }
    const { x, y } = event.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,

      complete: false, //////////////////

      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: false,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    const validParameters =
      data && data.handles && data.handles.start && data.handles.end;
    if (!validParameters) {
      // //console.log(
      //     `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      // );
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const line =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 10;

    if (line) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
    }
    return line;
  }

  distanceFromPoint(event) {}

  renderToolData(event) {
    const eventData = event.detail;
    // We have tool data for this element - iterate over each one and draw it
    const context = Drawing.getNewContext(eventData.canvasContext.canvas);
    const toolData = cornerstoneTools.getToolState(
      event.currentTarget,
      this.name
    );

    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      const {
        handles: { start, end },
      } = data;
      const midX = (start.x + end.x) / 2;
      const midY = (start.y + end.y) / 2;
      const centerPoint = { x: midX, y: midY };

      Drawing.drawHandles(context, eventData, data.handles, {
        radius: 5,
        color: "green",
      });
      Drawing.drawHandles(
        context,
        eventData,
        { centerPoint },
        {
          radius: 2,
          fill: "red",
          color: "red",
        }
      );
    }
  }
}
