import { styled } from "@mui/system";
import { DicomDetails } from "../../../dicomViewer.types";
import {
  DICOM_DETAILS_HORIZONTAL_POSITIONS,
  DICOM_DETAILS_VERTICAL_POSITIONS,
} from "../../../dicomViewer.consts";
import { DicomOverlaySpinner } from "./../DicomOverlaySpinner";

const DetailsContainer = styled("div")<{
  horizontalPosition: string;
  verticalPosition: string;
}>(({ horizontalPosition, verticalPosition }) => ({
  position: "absolute",
  left:
    horizontalPosition === DICOM_DETAILS_HORIZONTAL_POSITIONS.LEFT
      ? "2%"
      : "80%",
  top: verticalPosition === DICOM_DETAILS_VERTICAL_POSITIONS.TOP ? "0%" : "85%",
  color: "#89cbe0",
}));

export interface OverlayDetailsProps {
  isRetrievingImageMetaData?: boolean;
  details: DicomDetails;
  verticalPosition: string;
  horizontalPosition: string;
}

export const DicomViewerDetails = ({
  details,
  verticalPosition,
  horizontalPosition,
  isRetrievingImageMetaData = false,
}: OverlayDetailsProps) => (
  <DetailsContainer
    verticalPosition={verticalPosition}
    horizontalPosition={horizontalPosition}
  >
    {isRetrievingImageMetaData ? (
      <DicomOverlaySpinner />
    ) : (
      <>
        {Object.keys(details).map((key) => (
          <div key={key}>
            <strong>{key}</strong>: {details[key]}
          </div>
        ))}
      </>
    )}
  </DetailsContainer>
);
