import { RadiologySite } from "models/study.types";

export const getPatientDetails = (patientData) => {
  const { patientName, patientId, patientDob, studyDate, referringDoctor } =
    patientData;
  return `Patient: ${patientName.toUpperCase()} \nPatient ID: ${patientId} \nDOB: ${patientDob.replaceAll(
    "/",
    "."
  )} Date: ${studyDate.replaceAll(
    "/",
    "."
  )}\n\nREFERRING DOCTOR: ${referringDoctor}`;
};

export const getRadiologistSignatureUri = (siteInfo: RadiologySite | null) =>
  siteInfo && siteInfo.radSigUrl ? siteInfo.radSigUrl : null;

export const getReportingDoctor = (siteInfo: RadiologySite | null) =>
  `\n ${siteInfo?.radiologist ?? ""}`;
