import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useFramedModality } from "pages/viewer/hooks/useFramedModality";
import { useMemo } from "react";
import { TOOL_IDS } from "../consts/tools.consts";

export const usePlayPauseFrames = () => {
  const {
    state: { activeTools },
  } = useViewerContext();
  const { isFramedModality } = useFramedModality();
  return useMemo(
    () => isFramedModality && activeTools.includes(TOOL_IDS.PLAY_FRAMES),
    [activeTools, isFramedModality]
  );
};
