import { Field, FieldProps } from "formik";
import DatePicker from "react-datepicker";
import { useCallback, useMemo } from "react";
import { Stack } from "@mui/system";
import { FieldLabel } from "components/text/Text";
import "react-datepicker/dist/react-datepicker.css";
import "./date.css";

interface DateInputProps {
  fieldName: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
}
export const DateInput = ({
  label,
  fieldName,
  required,
  placeholder,
}: DateInputProps) => {
  return (
    <Stack>
      <FieldLabel isRequired={required} label={label ?? fieldName} />
      <span>
        <Field
          name={fieldName}
          component={DatePickerField}
          placeholder={placeholder}
        />
      </span>
    </Stack>
  );
};

const DatePickerField: React.FC<FieldProps> = ({ field, form, ...props }) => {
  const value = useMemo(() => {
    return (
      (field.value && field.value !== "Unknown" && new Date(field.value)) ||
      null
    );
  }, [field]);

  const handleChange = useCallback(
    (date) => {
      if (date !== value) {
        form.setFieldValue(field.name, date);
      }
    },
    [form, value]
  );
  return (
    <DatePicker
      {...props}
      dateFormat="yyyy/MM/dd"
      // @ts-ignore
      placeholderText={props.placeholder ?? "Select Date"}
      selected={value}
      onSelect={handleChange}
    />
  );
};
