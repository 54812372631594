import { Field, FieldProps } from "formik";
import { Stack } from "@mui/system";
import { FieldLabel } from "components/text/Text";
import { DropdownProps } from "components/dropdown/Dropdown";
import { DropdownOptionConfig } from "components/dropdown/dropdown.types";
import { useCallback } from "react";
import { TableDropdown } from "components/dropdown/TableDropdown";

interface DropdownInputProps {
  fieldName: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  value?: string;
  defaultValue?: string;
  optionsConfig: DropdownOptionConfig[];
}
export const FormDropdown = ({
  label,
  fieldName,
  required,
  optionsConfig,
  value,
}: DropdownInputProps) => {
  return (
    <Stack>
      <FieldLabel isRequired={required} label={label ?? fieldName} />
      <span>
        <Field
          name={fieldName}
          component={DropdownField}
          optionsConfig={optionsConfig}
          value={value}
        />
      </span>
    </Stack>
  );
};

type DropdownFieldProps = FieldProps & DropdownProps;
const DropdownField: React.FC<DropdownFieldProps> = ({
  field,
  form,
  optionsConfig,
  value,
  ...props
}) => {
  const handleUpdateValue = useCallback(
    (value) => {
      form.setFieldValue(field.name, value);
    },
    [form, value]
  );
  return (
    <TableDropdown
      {...props}
      value={value}
      optionsConfig={optionsConfig}
      onChange={handleUpdateValue}
    />
  );
};
