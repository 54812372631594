// @ts-nocheck
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addNewGoogleAccount, handleGoogleLogin } from "services/auth";
import { LOGIN_RESPONSE_STATUS } from "../../../pages/login/login.consts";
import { useAuth } from "hooks/useAuth";
import { LoginResponse } from "models/auth.types";
import { useAppContext } from "context/app.context";

export const useHandleGoogleConnectResponse = (toggleIsLoading: () => void) => {
  const navigation = useNavigate();
  const { dispatch } = useAppContext();
  const { saveUserDetails } = useAuth();
  const handleGoogleError = useCallback(() => {
    toast.error("Error with connecting with Google.");
  }, []);
  const {
    state: { userId },
  } = useAppContext();
  const handleGoogleSuccess = useCallback(
    async (res) => {
      toggleIsLoading();
      const loginTime = new Date().toLocaleTimeString();
      try {
        const connectResult = await addNewGoogleAccount(
          userId,
          res.access_token
        );
        if (connectResult.message) {
          toast.error(connectResult.message);
        } else {
          const {
            data: { googleId },
          } = connectResult;

          const loginResult = (await handleGoogleLogin(
            res.access_token
          )) as LoginResponse;
          const { status, message } = loginResult;
          if (loginResult.status === LOGIN_RESPONSE_STATUS.VALID) {
            const { data, refreshToken, accessToken, expiresIn } = loginResult;
            const userAuthData = {
              userId,
              name: data.name,
              userType: data.userType,
              loginTime,
              expiry: data.accountExpiry,
              googleId,
            };
            const tokenData = {
              refreshToken,
              accessToken,
              expiresIn,
            };
            const googleAccessToken = { googleAccessToken: res.access_token };
            saveUserDetails(userAuthData, tokenData, googleAccessToken);

            dispatch({
              type: "LOGIN",
              payload: {
                userId: data.userId,
                userType: data.userType,
                userAuthData,
              },
            });

            navigation("/home");
            toast.success("Google Account Successfully Connected");
          } else if (status === LOGIN_RESPONSE_STATUS.INVALID || message) {
            toast.error(message);
          } else {
            toast.error("Error with logging with Google.");
          }
        }
      } catch (error) {
        console.error("Google login error:", error);
        toast.error("Error with logging with Google.");
      }
      toggleIsLoading();
    },
    [navigation, userId, dispatch]
  );
  return { handleGoogleSuccess, handleGoogleError };
};
