import { useCallback } from "react";
import { StudyData } from "../../../studyTable.types";
import { FIELD_KEYS } from "../../../study-table-columns/columns.consts";
import { encodeDateToHL7Date } from "utils/formatting/encodeHL7";
import { useUpdateStudyData } from "../../../hooks/useUpdateStudyData";
import { PATIENT_FIELDS } from "../../../studyTable.consts";
import { useUpdatePatientData } from "../../../hooks/useUpdatePatientData";

export const useFormSubmission = (
  studyData: StudyData,
  fieldName: string,
  updateDisplayData: (updatedData: Partial<StudyData>) => void
) => {
  const updateStudyData = useUpdateStudyData();
  const updatePatientData = useUpdatePatientData();
  return useCallback(
    async (values: Record<string, string>) => {
      const submissionData = {};

      const { studypk, patientpk } = studyData;
      switch (fieldName) {
        case FIELD_KEYS.MEDICARE_NUMBER:
          {
            submissionData[FIELD_KEYS.MEDICARE_NUMBER] =
              values[FIELD_KEYS.MEDICARE_NUMBER];
            submissionData[FIELD_KEYS.IRN_NUMBER] =
              values[FIELD_KEYS.IRN_NUMBER];
          }
          break;
        case FIELD_KEYS.SERVICES:
          {
            submissionData[FIELD_KEYS.SERVICES] = values[FIELD_KEYS.SERVICES];
          }
          break;
        case FIELD_KEYS.PAT_BIRTHDATE: {
          {
            submissionData[FIELD_KEYS.PAT_BIRTHDATE] = encodeDateToHL7Date(
              values["dob"] as unknown as Date
            );
          }
          break;
        }
        case FIELD_KEYS.PAT_NAME:
          {
            const { suffix, firstName, middleName, lastName, prefix } = values;
            submissionData[
              FIELD_KEYS.PAT_NAME
            ] = `${lastName}^${firstName}^${middleName}^${prefix}^${suffix}`;
          }
          break;
        default:
          break;
      }
      const shouldUpdatePatient = PATIENT_FIELDS.includes(fieldName);
      const updatedValues = shouldUpdatePatient
        ? await updatePatientData(studypk, patientpk, submissionData)
        : await updateStudyData(studypk, submissionData);
      if (updateDisplayData) {
        updateDisplayData(updatedValues);
      }
    },
    [fieldName, studyData, updateStudyData]
  );
};
