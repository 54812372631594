import cornerstoneTools from "cornerstone-tools";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import { MEASUREMENT_SUFFIXES, TOOL_IDS } from "../consts/tools.consts";
import {
  getAxisLengths,
  getDimensionData,
  getDisplayedMeasurement,
} from "./measurementToolUtils";
import { ImageMetadata } from "pages/viewer/dicomViewer.types";
import { DEFAULT_HANDLE } from "../consts/tools.defaults";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);
const ELLIPTICAL_AREA_CONFIG = {
  drawHandles: true,
};
export default class EllipticalArea extends BaseAnnotationTool {
  throttledUpdateCachedStats: any;
  imageMetaData: ImageMetadata;
  configuration: {
    drawHandles: boolean;
  };
  toolId: string;
  constructor(name = TOOL_IDS.ELLIPTICAL_AREA) {
    const props = {
      name,
      supportedInteractionTypes: ["Mouse", "Touch"],
      configuration: {
        drawHandles: true,
        renderDashed: true,
        digits: 2,
      },
    };

    super(props);
    this.toolId = TOOL_IDS.ELLIPTICAL_AREA;
    this.imageMetaData = {};
    this.throttledUpdateCachedStats = Util.throttle(
      this.updateCachedStats,
      110
    );
    this.setImageMetadata = this.setImageMetadata.bind(this);
  }

  public setImageMetadata(imageMetaData: ImageMetadata) {
    if (imageMetaData) {
      this.imageMetaData = imageMetaData;
    }
  }

  createNewMeasurement = (eventData) => {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      return;
    }

    const { x, y } = eventData.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  };

  pointNearTool = (element, data, coords) => {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      // //console.log(
      //     `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      // );

      return false;
    }

    if (data.visible === false) {
      return false;
    }

    return (
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 25
    );
  };

  updateCachedStats(image, _element, data) {
    const {
      handles: { start, end },
    } = data;

    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );
    const { dx, dy } = getAxisLengths(
      start,
      end,
      colPixelSpacing,
      rowPixelSpacing
    );
    const majorRadius = Math.max(dx, dy) / 2;
    const minorRadius = Math.min(dx, dy) / 2;

    const area = majorRadius * minorRadius * Math.PI;

    data.area = area;
    data.suffix = MEASUREMENT_SUFFIXES.MM2;
    data.invalidated = false;
  }

  renderToolData = (event: { currentTarget?: any; detail?: any }) => {
    const { detail } = event;
    // We have tool data for this element - iterate over each one and draw it
    const context = Drawing.getNewContext(detail.canvasContext.canvas);
    const toolData = cornerstoneTools.getToolState(
      event.currentTarget,
      this.toolId
    );

    if (!toolData) {
      return;
    }

    const { image, element } = detail;

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      const { area, visible, suffix } = data;
      if (visible === false) {
        continue;
      }
      const color = cornerstoneTools.toolColors.getColorIfActive(data);
      const lineOptions = { color };
      Drawing.drawEllipse(
        context,
        detail.element,
        data.handles.start,
        data.handles.end,
        lineOptions
      );
      const handleOptions = {
        ...DEFAULT_HANDLE,
        color,
        hideHandlesIfMoving: false,
      };

      const { start, end } = data.handles;
      if (ELLIPTICAL_AREA_CONFIG.drawHandles) {
        Drawing.drawHandles(context, detail, { start, end }, handleOptions);
      }

      if (!data.handles.textBox.hasMoved) {
        const coords = {
          x: Math.max(data.handles.start.x, data.handles.end.x),
          y: 0,
        };

        // Depending on which handle has the largest x-value,
        // Set the y-value for the text box
        if (coords.x === data.handles.start.x) {
          coords.y = data.handles.start.y;
        } else {
          coords.y = data.handles.end.y;
        }

        data.handles.textBox.x = coords.x;
        data.handles.textBox.y = coords.y;
      }

      // Move the textbox slightly to the right and upwards
      // So that it sits beside the length tool handle
      const xOffset = 10;

      // Update textbox stats
      if (data.invalidated === true) {
        if (data.length) {
          this.throttledUpdateCachedStats(image, element, data);
        } else {
          this.updateCachedStats(image, element, data);
        }
      }

      const text = getDisplayedMeasurement(area, suffix);

      const lineWidth = 3;
      Drawing.drawLinkedTextBox(
        context,
        element,
        data.handles.textBox,
        text,
        data.handles,
        textBoxAnchorPoints,
        color,
        lineWidth,
        xOffset,
        true
      );
    }

    function textBoxAnchorPoints(handles) {
      const midpoint = {
        x: (handles.start.x + handles.end.x) / 2,
        y: (handles.start.y + handles.end.y) / 2,
      };

      return [handles.start, midpoint, handles.end];
    }
  };
}
