import { TableBody, TableRow, styled } from "@mui/material";
import { StyledTableCell } from "./TableCell";
import { RowConfig } from "./table.types";
import { TableEmpty } from "./TableEmpty";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface TableRowsProps {
  rows: RowConfig[];
  tableEmptyText?: string;
}
export const TableRows = ({
  rows,
  tableEmptyText = "No items found",
}: TableRowsProps) => {
  return (
    <TableBody>
      {rows.length ? (
        <>
          {rows.map(({ name, rowFields }) => (
            <StyledTableRow key={name}>
              {rowFields.map(({ fieldValue, component }, index) => {
                if (component) {
                  return (
                    <StyledTableCell
                      key={`${fieldValue}-${index}`}
                      align="left"
                    >
                      {component}
                    </StyledTableCell>
                  );
                }
                return (
                  <StyledTableCell key={`${fieldValue}-${index}`} align="left">
                    {fieldValue}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </>
      ) : (
        <TableEmpty displayText={tableEmptyText} />
      )}
    </TableBody>
  );
};
