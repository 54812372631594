import { useCallback, useEffect, useMemo, useState } from "react";
import dicomParser from "dicom-parser";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { METADATA_KEYS } from "pages/viewer/dicomViewer.consts";
import { ViewportState, StudyImageData } from "pages/viewer/dicomViewer.types";

export const useRetrieveDicomMetaData = (
  dicomViewports: ViewportState[],
  studyImageData: StudyImageData[] | null
) => {
  const {
    dispatch,
    state: { studyImageMetaData },
  } = useViewerContext();
  const studyImageIndex = useMemo(
    () => dicomViewports[0]?.studyImageIndex ?? null,
    [dicomViewports]
  );
  const [isRetrievingImageMetaData, setIsRetrievingMetaData] =
    useState<boolean>(false);

  const canRetrieveMetaData = useMemo(
    () =>
      Boolean(
        studyImageIndex !== null &&
          !isRetrievingImageMetaData &&
          studyImageMetaData[studyImageIndex] === null
      ),
    [studyImageIndex, studyImageMetaData, isRetrievingImageMetaData]
  );

  const formattedImages = useMemo(
    () =>
      studyImageData?.map(
        ({ image }) => `${image[0]}&contentType=application/dicom`
      ) ?? [],
    [studyImageData]
  );

  const fetchData = useCallback(async () => {
    setIsRetrievingMetaData(true);
    if (formattedImages.length) {
      try {
        setIsRetrievingMetaData(true);
        const image = await fetch(formattedImages[studyImageIndex]);
        const arrayBuffer = await image.arrayBuffer();
        const byteArray = new Uint8Array(arrayBuffer);
        const dataSet = dicomParser.parseDicom(byteArray);
        const { elements } = dataSet;
        const metadataValues = {};
        Object.values(elements).forEach((element) => {
          const { tag } = element;
          const keyArr = Object.keys(METADATA_KEYS).map((key) => key);
          if (keyArr.includes(tag)) {
            metadataValues[METADATA_KEYS[tag]] = dataSet.string(tag);
          }
        });
        // Return metadata object
        dispatch({
          type: "SAVE_STUDY_METADATA",
          payload: {
            studyImageMetaData: metadataValues,
            studyImageIndex,
            rawMetaData: dataSet,
          },
        });
        setIsRetrievingMetaData(false);
      } catch (error) {
        console.error("Error loading DICOM image:", error);
        setIsRetrievingMetaData(false);
      }
    }
  }, [
    studyImageMetaData,
    setIsRetrievingMetaData,
    formattedImages,
    studyImageIndex,
  ]);

  useEffect(() => {
    if (canRetrieveMetaData) {
      if (studyImageMetaData[studyImageIndex] === null) {
        fetchData();
      }
    }
  }, [fetchData, canRetrieveMetaData]);

  return { isRetrievingImageMetaData };
};
