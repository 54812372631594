import { Fab, Typography } from "@mui/material";
import { DRAWER_SIDES } from "./drawer.consts";
import { DrawerStyles } from "./drawer.types";
import ArrowUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDown from "@mui/icons-material/ArrowDropDown";
const fabStyle = {
  position: "absolute",
  bottom: "50%",
  display: "flex",
  transform: "rotate(-90deg)",
  borderRadius: "5px",
  width: "150px",
  transformOrigin: "center",
  transition: "left 0.2s ease, right 0.2s ease",
};

interface DicomDrawerButtonProps {
  drawerButtonTitle: string;
  isDrawerOpen: boolean;
  drawerSide: string;
  drawerStyles: DrawerStyles;
  onClick: () => void;
}

const DRAWER_HORIZONTAL_POSITION_OFFSET_PX = -50;
export const FloatingDrawerButton = ({
  onClick,
  drawerButtonTitle,
  isDrawerOpen,
  drawerStyles,
  drawerSide,
}: DicomDrawerButtonProps) => {
  const calculatedStyles = calculateButtonPositionAndStyle(
    isDrawerOpen,
    drawerSide,
    drawerStyles
  );

  return (
    <Fab
      sx={{ ...fabStyle, ...calculatedStyles }}
      onClick={onClick}
      aria-label={"drawer-open-close"}
      color={"secondary"}
    >
      {isDrawerOpen ? <ArrowUpIcon /> : <ArrowDown />}
      <Typography>{drawerButtonTitle}</Typography>
      {isDrawerOpen ? <ArrowUpIcon /> : <ArrowDown />}
    </Fab>
  );
};

const calculateButtonPositionAndStyle = (
  isDrawerOpen: boolean,
  drawerSide: string,
  drawerStyles: DrawerStyles
) => {
  const { widthPx } = drawerStyles;
  const drawerHorizontalPosition = isDrawerOpen
    ? widthPx + DRAWER_HORIZONTAL_POSITION_OFFSET_PX
    : DRAWER_HORIZONTAL_POSITION_OFFSET_PX;
  switch (drawerSide) {
    case DRAWER_SIDES.LEFT:
      return {
        left: drawerHorizontalPosition,
        transform: "rotate(-90deg)",
      };
    case DRAWER_SIDES.RIGHT:
      return {
        right: drawerHorizontalPosition,
        transform: "rotate(90deg)",
      };
  }
};
