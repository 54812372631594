import { OverlayData } from "../dicomViewer.types";

const CORONAL_OVERLAY_DATA = {
  topLeftDetails: {
    "View Type": "Coronal",
  },
};

const SAGITTAL_OVERLAY_DATA = {
  topLeftDetails: {
    "View Type": "Sagittal",
  },
};
export const getOverlayData = (
  isMprActive: boolean,
  viewportIndex: number,
  imageMetadata: OverlayData[]
) => {
  if (isMprActive && viewportIndex !== 0) {
    if (viewportIndex === 1) {
      return CORONAL_OVERLAY_DATA;
    } else {
      return SAGITTAL_OVERLAY_DATA;
    }
  }
  return imageMetadata[viewportIndex];
};
