import cornerstoneTools from "cornerstone-tools";

/**
 * Drawing API to assist in consistent annotation creation
 * @namespace Drawing
 */
export const Drawing = {
  getNewContext: cornerstoneTools.importInternal("drawing/getNewContext"),
  draw: cornerstoneTools.importInternal("drawing/draw"),
  path: cornerstoneTools.importInternal("drawing/path"),
  setShadow: cornerstoneTools.importInternal("drawing/setShadow"),
  drawLine: cornerstoneTools.importInternal("drawing/drawLine"),
  drawLines: cornerstoneTools.importInternal("drawing/drawLines"),
  drawJoinedLines: cornerstoneTools.importInternal("drawing/drawJoinedLines"),
  drawCircle: cornerstoneTools.importInternal("drawing/drawCircle"),
  drawEllipse: cornerstoneTools.importInternal("drawing/drawEllipse"),
  drawRect: cornerstoneTools.importInternal("drawing/drawRect"),
  fillOutsideRect: cornerstoneTools.importInternal("drawing/fillOutsideRect"),
  drawTextBox: cornerstoneTools.importInternal("drawing/drawTextBox"),
  drawArrow: cornerstoneTools.importInternal("drawing/drawArrow"),
  fillBox: cornerstoneTools.importInternal("drawing/fillBox"),
  fillTextLines: cornerstoneTools.importInternal("drawing/fillTextLines"),
  drawLink: cornerstoneTools.importInternal("drawing/drawLink"),
  drawLinkedTextBox: cornerstoneTools.importInternal(
    "drawing/drawLinkedTextBox"
  ),
  drawHandles: cornerstoneTools.importInternal("drawing/drawHandles"),
  textBoxWidth: cornerstoneTools.importInternal("drawing/textBoxWidth"),
};

export default Drawing;
