//Class Converts HL7 Data to User Friendly Data
export default class HL7 {
  //Patient Name. format: FAMILY[^GIVEN[^MIDDLE[^PREFIX[^SUFFIX]]]]
  static getPatientName(input) {
    if (input === null || input === undefined) {
      return "Unknown";
    }
    let temp = input.toString().trim();
    let split = temp.split("^");
    for (let i = 0; i < split.length; i++) {
      split[i] = split[i].toLowerCase();
      split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
      if (split[i] !== "") {
        split[i] = split[i] + " ";
      }
    }

    let output = split[3] + split[1] + split[2] + split[0] + split[4];

    return output.trimEnd();
  }
  static getFirstName(input) {
    if (input === null || input === undefined) {
      return "Unknown";
    }
    let temp = input.toString().trim();
    let split = temp.split("^");
    for (let i = 0; i < split.length; i++) {
      split[i] = split[i].toLowerCase();
      split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
      if (split[i] !== "") {
        split[i] = split[i] + " ";
      }
    }

    let output = split[1];

    return output.trimEnd();
  }
  static getLastName(input) {
    if (input === null || input === undefined) {
      return "Unknown";
    }
    let temp = input.toString().trim();
    let split = temp.split("^");
    for (let i = 0; i < split.length; i++) {
      split[i] = split[i].toLowerCase();
      split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
      if (split[i] !== "") {
        split[i] = split[i] + " ";
      }
    }

    let output = split[0];

    return output.trimEnd();
  }
  static getMiddleName(input) {
    if (input === null || input === undefined) {
      return "Unknown";
    }
    let temp = input.toString().trim();
    let split = temp.split("^");
    for (let i = 0; i < split.length; i++) {
      split[i] = split[i].toLowerCase();
      split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
      if (split[i] !== "") {
        split[i] = split[i] + " ";
      }
    }

    let output = split[2];

    return output.trimEnd();
  }
  static getNamePrefix(input) {
    if (input === null || input === undefined) {
      return "Unknown";
    }
    let temp = input.toString().trim();
    let split = temp.split("^");
    for (let i = 0; i < split.length; i++) {
      split[i] = split[i].toLowerCase();
      split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
      if (split[i] !== "") {
        split[i] = split[i] + " ";
      }
    }

    let output = split[3];

    return output.trimEnd();
  }
  static getNameSuffix(input) {
    if (input === null || input === undefined) {
      return "Unknown";
    }
    let temp = input.toString().trim();
    let split = temp.split("^");
    for (let i = 0; i < split.length; i++) {
      split[i] = split[i].toLowerCase();
      split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
      if (split[i] !== "") {
        split[i] = split[i] + " ";
      }
    }

    let output = split[4];

    return output.trimEnd();
  }

  static decodePatientName(input) {
    if (input === null || input === undefined) {
      return "Unknown";
    }
    let split = input.split(" ");
    for (let i = 0; i < split.length; i++) {
      split[i] = split[i].toUpperCase();
    }
    return split.join(">");
  }

  static getStudyDate(input) {
    if (input === null || input === undefined || input.length < 16) {
      return "Unknown";
    }
    input = input.toString().trim();
    let year = input.slice(0, 4);
    let month = input.slice(5, 7);
    let day = input.slice(8, 10);
    let hour = input.slice(11, 13);
    let minute = input.slice(14, 16);
    input = day + "/" + month + "/" + year + " " + hour + ":" + minute;
    return input;
  }

  static getStudyDateOnly(input) {
    if (input === null || input === undefined || input.length < 16) {
      return "Unknown";
    }
    input = input.toString().trim();
    let year = input.slice(0, 4);
    let month = input.slice(5, 7);
    let day = input.slice(8, 10);
    let hour = input.slice(11, 13);
    let minute = input.slice(14, 16);
    input = day + "/" + month + "/" + year;
    return input;
  }

  static getBirthday(input) {
    if (input === null || input === undefined || input.length < 8) {
      return "Unknown";
    }
    return (
      input.slice(6, 8) + "/" + input.slice(4, 6) + "/" + input.slice(0, 4)
    );
  }

  static getBirthDayFormated(input) {
    if (input === null || input === undefined || input.length < 8) {
      return "Unknown";
    }
    return (
      input.slice(0, 4) + "/" + input.slice(4, 6) + "/" + input.slice(6, 8)
    );
  }

  static convertDatabaseDateToJsDate(input) {
    if (input === null || input === undefined || input.length < 8) {
      return "";
    }
    if (input.length == 8) {
      // //console.log('input: '+ input);
      // //console.log('returning ' + input.slice(0,4) + '-' + input.slice(4,6) + '-' + input.slice(6,8));
      return (
        input.slice(0, 4) + "-" + input.slice(4, 6) + "-" + input.slice(6, 8)
      );
    } else {
      return "";
    }
  }
  static convertJsDateToDatabaseDate(input) {
    if (input === null || input === undefined || input.length < 8) {
      return "";
    }
    if (input.length == 10) {
      // //console.log('input: '+ input);
      // //console.log('returning ' + input.slice(0,4) + input.slice(5,7) + input.slice(8,10));
      return input.slice(0, 4) + input.slice(5, 7) + input.slice(8, 10);
    } else {
      return "";
    }
  }
}
