import { useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LOGIN_RESPONSE_STATUS } from "../../../pages/login/login.consts";
import { useAuth } from "hooks/useAuth";
import { LoginResponse } from "models/auth.types";
import { handleGoogleLogin } from "services/auth";
import { useAppContext } from "context/app.context";

export const useHandleGoogleResponse = (
  toggleIsLoading: () => void,
  redirectUrlOnLogin?: string | null
) => {
  const navigation = useNavigate();
  const { dispatch } = useAppContext();
  const { saveUserDetails } = useAuth();
  const handleGoogleError = useCallback(() => {
    toast.error("Error with logging with Google.");
  }, []);

  const handleGoogleSuccess = useCallback(
    async (res) => {
      toggleIsLoading();
      const loginTime = new Date().toLocaleTimeString();
      try {
        const loginResult = (await handleGoogleLogin(
          res.code
        )) as LoginResponse;
        const { status, message } = loginResult;
        if (loginResult.status === LOGIN_RESPONSE_STATUS.VALID) {
          const { data, refreshToken, expiresIn, accessToken } = loginResult;
          const userAuthData = {
            userId: data.userId,
            name: data.name,
            userType: data.userType,
            loginTime,
            expiry: data.accountExpiry,
            googleId: data.googleId,
          };
          const tokenData = {
            refreshToken,
            accessToken,
            expiresIn,
          };
          saveUserDetails(userAuthData, tokenData);

          dispatch({
            type: "LOGIN",
            payload: {
              userId: data.userId,
              userType: data.userType,
              userAuthData,
            },
          });
          if (redirectUrlOnLogin) {
            window.location.href = redirectUrlOnLogin;
          } else {
            navigation("/home");
          }

          toast.success("Login Successful");
        } else if (status === LOGIN_RESPONSE_STATUS.INVALID || message) {
          toast.error(message);
        } else {
          toast.error("Error with logging with Google.");
        }
      } catch (error) {
        console.error("Google login error:", error);
        toast.error("Error with logging with Google.");
      }
      toggleIsLoading();
    },
    [navigation, redirectUrlOnLogin]
  );

  return { handleGoogleSuccess, handleGoogleError };
};
