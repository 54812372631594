const getColourByType = (type) => {
  switch (type) {
    case "medical":
      return "#7f1c53"; //Maroon
    case "dental":
      return "#9F9F9F"; //Grey
    case "chiro":
      return "#24bFd0"; //Aqua
    case "chiroMedicare":
      return "#24bFd0"; //Aqua
    case "vet":
      return "#15AA4E"; //Green
    case "radiologist":
      return "#24bFd0"; //Aqua
    case "admin":
      return "#7f1c53"; //Maroon
    case "medicareAdmin":
      return "#24bFd0"; //Aqua
    case "developer":
      return "#24bFd0"; //Aqua
    case "public":
      return "#7f1c53"; //Maroon
    default:
      return "#7f1c53"; //Maroon
  }
};

export default getColourByType;

//medical: Darker: #430F2B, Normal: #7F1C53, Lighter: #B32774, Pale: #F4CDE2
