import cornerstoneTools from "cornerstone-tools";

/**
 * Util
 * @namespace Util
 */
export const Util = {
  getActiveTool: cornerstoneTools.importInternal("util/getActiveTool"),
  getLuminance: cornerstoneTools.importInternal("util/getLuminance"),
  getROITextBoxCoords: cornerstoneTools.importInternal(
    "util/getROITextBoxCoords"
  ),
  copyPoints: cornerstoneTools.importInternal("util/copyPoints"),
  calculateSUV: cornerstoneTools.importInternal("util/calculateSUV"),
  setContextToDisplayFontSize: cornerstoneTools.importInternal(
    "util/setContextToDisplayFontSize"
  ),
  scrollToIndex: cornerstoneTools.importInternal("util/scrollToIndex"),
  scroll: cornerstoneTools.importInternal("util/scroll"),
  roundToDecimal: cornerstoneTools.importInternal("util/roundToDecimal"),
  projectPatientPointToImagePlane: cornerstoneTools.importInternal(
    "util/projectPatientPointToImagePlane"
  ),
  imagePointToPatientPoint: cornerstoneTools.importInternal(
    "util/imagePointToPatientPoint"
  ),
  planePlaneIntersection: cornerstoneTools.importInternal(
    "util/planePlaneIntersection"
  ),
  pointInsideBoundingBox: cornerstoneTools.importInternal(
    "util/pointInsideBoundingBox"
  ),
  makeUnselectable: cornerstoneTools.importInternal("util/makeUnselectable"),
  getRGBPixels: cornerstoneTools.importInternal("util/getRGBPixels"),
  getBrowserInfo: cornerstoneTools.importInternal("util/getBrowserInfo"),
  isMobileDevice: cornerstoneTools.importInternal("util/isMobileDevice"),
  angleBetweenPoints: cornerstoneTools.importInternal(
    "util/angleBetweenPoints"
  ),
  numbersWithCommas: cornerstoneTools.importInternal("util/numbersWithCommas"),
  lineSegDistance: cornerstoneTools.importInternal("util/lineSegDistance"),
  triggerEvent: cornerstoneTools.importInternal("util/triggerEvent"),
  convertToVector3: cornerstoneTools.importInternal("util/convertToVector3"),
  clip: cornerstoneTools.importInternal("util/clip"),
  clipToBox: cornerstoneTools.importInternal("util/clipToBox"),
  clipBoxToDisplayedArea: cornerstoneTools.importInternal(
    "util/clipBoxToDisplayedArea"
  ),
  debounce: cornerstoneTools.importInternal("util/debounce"),
  deepmerge: cornerstoneTools.importInternal("util/deepmerge"),
  getDefault: cornerstoneTools.importInternal("util/getDefault"),
  getProximityThreshold: cornerstoneTools.importInternal(
    "util/getProximityThreshold"
  ),
  getPixelSpacing: cornerstoneTools.importInternal("util/getPixelSpacing"),
  isEmptyObject: cornerstoneTools.importInternal("util/isEmptyObject"),
  isObject: cornerstoneTools.importInternal("util/isObject"),
  isPointInImage: cornerstoneTools.importInternal("util/isPointInImage"),
  isPointInPolygon: cornerstoneTools.importInternal("util/isPointInPolygon"),
  getLogger: cornerstoneTools.importInternal("util/getLogger"),
  throttle: cornerstoneTools.importInternal("util/throttle"),
  wait: cornerstoneTools.importInternal("util/wait"),
  waitForEnabledElementImageToLoad: cornerstoneTools.importInternal(
    "util/waitForEnabledElementImageToLoad"
  ),
  getKeyPressData: cornerstoneTools.importInternal("util/getKeyPressData"),
  // Whole tool specific util packages
  ellipseUtils: cornerstoneTools.importInternal("util/ellipseUtils"),
  freehandUtils: cornerstoneTools.importInternal("util/freehandUtils"),
  segmentationUtils: cornerstoneTools.importInternal("util/segmentationUtils"),
  zoomUtils: cornerstoneTools.importInternal("util/zoomUtils"),
};

export default Util;
