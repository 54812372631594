import { useGoogleLogin } from "@react-oauth/google";
import { ActionButton } from "components/buttons/Buttons";
import { useHandleGoogleConnectResponse } from "./useHandleGoogleConnectResponse";

interface GoogleConnectSectionProps {
  toggleIsLoading: () => void;
}
export const GoogleConnectSection = ({
  toggleIsLoading,
}: GoogleConnectSectionProps) => {
  const { handleGoogleSuccess, handleGoogleError } =
    useHandleGoogleConnectResponse(toggleIsLoading);

  const connect = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleSuccess(tokenResponse),
    onError: (_error) => handleGoogleError(),
    flow: "auth-code",
    include_granted_scopes: false,
  });
  return (
    <ActionButton onClick={() => connect()} fullWidth>
      Connect WITH GOOGLE
    </ActionButton>
  );
};
