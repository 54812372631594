import { FORM_FIELDS } from "components/form/form.consts";
import { FIELD_KEYS } from "../../study-table/study-table-columns/columns.consts";
import { STATUS_OPTIONS } from "../../study-table/study-table-rows/studyTableRows.consts";

export const DROPDOWN_FILTER_FIELDS = [
  FIELD_KEYS.PAT_SEX,
  FIELD_KEYS.STATUS,
  FIELD_KEYS.LOCK,
  FIELD_KEYS.CLAIM_VERIFICATION,
  FIELD_KEYS.STUDY_PROCESS,
];
export const EMPTY_DROPDOWN_VALUES = ["", "null", "undefined"];
export const STUDY_DATE_FILTERS = {
  STUDY_TO: "studyTo",
  STUDY_FROM: "studyFrom",
};
export const STUDY_DATE_TIME_FILTERS = [
  STUDY_DATE_FILTERS.STUDY_TO,
  STUDY_DATE_FILTERS.STUDY_FROM,
];
// BOOLEAN FIELDS WHERE THE VALUE IN THE DB FALSE === NULL
export const FALSE_IS_NULL_FIELDS = [
  FIELD_KEYS.LOCK,
  FIELD_KEYS.CLAIM_VERIFICATION,
  FIELD_KEYS.STUDY_PROCESS,
];
const SEX_OPTIONS = [
  { label: "Not Set", value: "Not Set" },
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];

const VERIFIED_OPTIONS = [
  { label: "Not Set", value: "Not Set" },
  { label: "Verified", value: true },
  { label: "Unverified", value: false },
];

const LOCKED_OPTIONS = [
  { label: "Select option", value: "Not Set" },
  { label: "Locked", value: true },
  { label: "Unlocked", value: false },
];

const MARKED_OPTIONS = [
  { label: "Select option", value: "Not Set" },
  { label: "Marked", value: true },
  { label: "Unmarked", value: false },
];

export const DEFAULT_SEARCH_FIELDS = [
  FIELD_KEYS.PAT_NAME,
  FIELD_KEYS.PAT_ID,
  FIELD_KEYS.INSTITUTION,
  FIELD_KEYS.STUDY_PK,
];

export const STUDY_FILTER_FORM = {
  [FIELD_KEYS.PAT_NAME]: {
    label: "Patient Name",
  },
  [FIELD_KEYS.PAT_ID]: {
    label: "Patient Id",
  },
};

export const DEFAULT_ADVANCED = {
  [STUDY_DATE_FILTERS.STUDY_FROM]: {
    label: "From",
    inputType: FORM_FIELDS.DATE,
  },
  [STUDY_DATE_FILTERS.STUDY_TO]: {
    label: "To",
    inputType: FORM_FIELDS.DATE,
  },
  [FIELD_KEYS.PAT_BIRTHDATE]: {
    label: "Birth Date",
    inputType: FORM_FIELDS.DATE,
  },
  [FIELD_KEYS.PAT_SEX]: {
    label: "Sex",
    inputType: FORM_FIELDS.DROPDOWN,
    optionsConfig: SEX_OPTIONS,
  },
};

export const MEDICARE_ADMIN_DEFAULT = {
  [FIELD_KEYS.STUDY_PK]: {
    label: "Patient Name",
  },
};

export const INSTITUTION_DEFAULT = {
  [FIELD_KEYS.INSTITUTION]: {
    label: "Institution",
  },
};

export const CHIRO_MEDICARE_ADVANCED = {
  [FIELD_KEYS.STATUS]: {
    label: "Status",
    inputType: FORM_FIELDS.DROPDOWN,
    optionsConfig: STATUS_OPTIONS,
  },
  [FIELD_KEYS.CLAIM_VERIFICATION]: {
    label: "Verified",
    defaultValue: "Not Set",
    inputType: FORM_FIELDS.DROPDOWN,
    optionsConfig: VERIFIED_OPTIONS,
  },
  [FIELD_KEYS.LOCK]: {
    label: "Locked",
    defaultValue: "Not Set",
    inputType: FORM_FIELDS.DROPDOWN,
    optionsConfig: LOCKED_OPTIONS,
  },
};

export const LONG_ADVANCED = {
  [FIELD_KEYS.STATUS]: {
    label: "Status",
    inputType: FORM_FIELDS.DROPDOWN,
    defaultValue: "Not Set",
    optionsConfig: STATUS_OPTIONS,
  },
  [FIELD_KEYS.CLAIM_VERIFICATION]: {
    label: "Verified",
    defaultValue: "Not Set",
    inputType: FORM_FIELDS.DROPDOWN,
    optionsConfig: VERIFIED_OPTIONS,
  },
  [FIELD_KEYS.LOCK]: {
    label: "Locked",
    defaultValue: "Not Set",
    inputType: FORM_FIELDS.DROPDOWN,
    optionsConfig: LOCKED_OPTIONS,
  },
  [FIELD_KEYS.STUDY_PROCESS]: {
    label: "Mark",
    defaultValue: "Not Set",
    inputType: FORM_FIELDS.DROPDOWN,
    optionsConfig: MARKED_OPTIONS,
  },
};
