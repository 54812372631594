// @ts-nocheck
import { useCallback, useState } from "react";
import { FIELD_KEYS } from "../../../study-table-columns/columns.consts";
import { useMemo } from "react";
import {
  DOB_FORM,
  MEDICARE_FORM,
  PATIENT_NAME_FORM,
  SERVICES_FORM,
} from "../../studyTableRows.consts";
import { Form, Formik } from "formik";
import { FormFields } from "components/form/FormField";
import { GetStartedButton } from "components/buttons/Buttons";
import { StudyData } from "../../../studyTable.types";
import { Edit } from "@mui/icons-material";

import {
  decodeFirstNameFromHL7,
  decodeHL7DateToDate,
  decodeLastNameFromHL7,
  decodeMiddleNameFromHL7,
  decodePrefixFromHL7,
  decodeSuffixFromHL7,
} from "utils/formatting/decodeHL7";
import { useFormValidation } from "./useFormValidation";
import { useFormSubmission } from "./useFormSubmission";
import { isObjectEmpty } from "utils/form/validation";
import { useTableItemDisplayData } from "./useTableItemDisplayData";
import { useIsLocked } from "../../../hooks/useIsLocked";
import { FocusedTooltip } from "components/tooltips/FocusedTooltip";
import { Box } from "@mui/system";

interface TableItemFormProps {
  studyData: StudyData;
  fieldName: string;
  isEditable: boolean;
}
export const TableItemForm = ({
  studyData,
  fieldName,
  isEditable,
}: TableItemFormProps) => {
  const {
    formFields,
    displayData,
    initialValues,
    validateFormInput,
    handleFormSubmission,
  } = useTableFormData(studyData, fieldName);

  const [open, setOpen] = useState(false);

  const toggleTooltipOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  const isLocked = useIsLocked(studyData);
  if (!isLocked) {
    return (
      <FocusedTooltip
        tooltipContent={
          <div className="card-handler">
            <Formik
              initialValues={initialValues}
              validateOnBlur
              validateOnMount
              validate={(values) => {
                const errors = validateFormInput(values);
                const isValid = isObjectEmpty(errors);
                if (isValid) {
                }
                return errors;
              }}
              onSubmit={() => {}}
            >
              {({ errors, values }) => (
                <Form>
                  {Object.keys(formFields).map((value: string, index) => {
                    // @ts-ignore
                    const fieldItem = formFields[value];
                    // // @ts-ignore
                    // const fieldError = errors[value];
                    // // @ts-ignore
                    // const fieldMessage = fieldItem["message"];
                    return (
                      <FormFields
                        key={`${value} ${index}`}
                        fieldName={value}
                        field={fieldItem}
                      />
                    );
                  })}
                  <Box style={{ marginTop: "1rem" }}>
                    <GetStartedButton
                      onClick={() => handleFormSubmission(values)}
                    >
                      Submit
                    </GetStartedButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        }
      >
        <span onClick={toggleTooltipOpen}>
          {displayData}
          <Edit />
        </span>
      </FocusedTooltip>
    );
  }
  return <span>{displayData}</span>;
};

const useTableFormData = (studyData: StudyData, fieldName: string) => {
  const formFields = useMemo(() => {
    switch (fieldName) {
      case FIELD_KEYS.MEDICARE_NUMBER:
        return { ...MEDICARE_FORM };
      case FIELD_KEYS.SERVICES:
        return { ...SERVICES_FORM };
      case FIELD_KEYS.PAT_BIRTHDATE:
        return { ...DOB_FORM };
      case FIELD_KEYS.PAT_NAME:
        return { ...PATIENT_NAME_FORM };
      default:
        return {};
    }
  }, [fieldName, studyData]);

  const initialValues = useMemo(
    () =>
      Object.keys(formFields).reduce((prev, curr) => {
        if (studyData[curr]) {
          prev[curr] = studyData[curr];
        } else {
          switch (curr) {
            case "prefix":
              prev[curr] = decodePrefixFromHL7(studyData.patName ?? "");
              break;
            case "firstName":
              prev[curr] = decodeFirstNameFromHL7(studyData.patName ?? "");
              break;
            case "middleName":
              prev[curr] = decodeMiddleNameFromHL7(studyData.patName ?? "");
              break;
            case "lastName":
              prev[curr] = decodeLastNameFromHL7(studyData.patName ?? "");
              break;
            case "suffix":
              prev[curr] = decodeSuffixFromHL7(studyData.patName ?? "");
              break;
            case "dob":
              prev[curr] = decodeHL7DateToDate(studyData.patBirthdate ?? "");
              break;
          }
        }

        return prev;
      }, {} as Record<string, unknown>),
    [formFields, studyData]
  );

  const { displayData, updateDisplayData } = useTableItemDisplayData(
    studyData,
    fieldName
  );

  const validateFormInput = useFormValidation(studyData, fieldName);
  const handleFormSubmission = useFormSubmission(
    studyData,
    fieldName,
    updateDisplayData
  );

  return {
    formFields,
    displayData,
    initialValues,
    validateFormInput,
    handleFormSubmission,
  };
};
