import { createContext, useReducer, useContext, ReactNode } from "react";
import { DIRECTIONS } from "../tools/consts/tools.consts";
import { INITIAL_VIEWER_GRID_STATE } from "../dicomViewer.consts";
import {
  DisplayedDicomMetaData,
  StudyImageData,
  ViewportState,
} from "../dicomViewer.types";
import { formatViewportUpdates } from "../helpers/formatViewportUpdates";
import { setMPRViewports } from "../helpers/setMPRViewports";
import { MPR_STATUSES } from "../tools/mpr-tool/mprTool.consts";

export type ViewerState = {
  activeTools: string[];
  modalityType: null | string;
  viewportFrameIndexes: number[];
  totalFrames: number;
  gridDimensions: number[];
  mprCoordinates: { x: number; y: number };
  viewports: ViewportState[];
  studyImageData: null | StudyImageData[];
  studyImageMetaData: Array<Partial<DisplayedDicomMetaData> | null>;
  rawMetaData: any[];
  mprData: null | any;
  isMPRActive: boolean;
  mprSetupStatus: string;
  axialImages: [];
};
const INITIAL_STATE: ViewerState = {
  rawMetaData: [],
  activeTools: [],
  modalityType: null,
  viewportFrameIndexes: [0],
  totalFrames: 1,
  mprSetupStatus: "",
  gridDimensions: INITIAL_VIEWER_GRID_STATE,
  viewports: [],
  studyImageMetaData: [],
  mprCoordinates: { x: 0, y: 0 },
  studyImageData: null,
  mprData: null,
  isMPRActive: false,
  axialImages: [],
};

export type ViewerActions =
  | {
      type: "SET_CURSOR";
      payload: { cursorId: string };
    }
  | { type: "SET_ACTIVE_TOOLS"; payload: { activeTools: string[] } }
  | {
      type: "SET_STUDY_DATA";
      payload: {
        modalityType: string;
        totalFrames: number;
        studyImageData: StudyImageData[];
      };
    }
  | {
      type: "SAVE_STUDY_METADATA";
      payload: {
        studyImageMetaData;
        studyImageIndex: number;
        rawMetaData: any;
      };
    }
  | { type: "INITIALIZE_VIEWPORT"; payload: {} }
  | {
      type: "SCRUB_IMAGE_FRAME_INDEX";
      payload: { direction: string };
    }
  | {
      type: "SET_IMAGE_FRAME_INDEX";
      payload: { viewportIndex: number; frameIndex: number };
    }
  | {
      type: "UPDATE_VIEWER_GRID";
      payload: {
        updatedGridDimensions: number[];
      };
    }
  | {
      type: "MPR_IMAGE_GENERATED";
      payload: { coronalImages; sagittalImages };
    }
  | { type: "MPR_TOOL_UPDATE"; payload: { x: number; y: number } }
  | { type: "CHANGE_ACTIVE_VIEWPORT"; payload: { newViewport: ViewportState } }
  | {
      type: "UPDATE_VIEWPORT_IMAGE_DATA";
      payload: { viewportIndex: number; viewportData: ViewportState };
    }
  | { type: "SET_MPR_VIEWPORTS" }
  | { type: "TOGGLE_MPR_ACTIVE"; payload: { isActive: boolean } }
  | { type: "LOAD_AXIAL_IMAGES"; payload: { axialImages: any } };

type ViewerContextType = {
  state: ViewerState;
  dispatch: React.Dispatch<ViewerActions>;
};
const ViewerContext = createContext<ViewerContextType | undefined>(undefined);

const reducer = (state: ViewerState, action: ViewerActions) => {
  switch (action.type) {
    case "TOGGLE_MPR_ACTIVE": {
      return {
        ...state,
        isMPRActive: action.payload.isActive,
        mprSetupStatus: action.payload.isActive
          ? MPR_STATUSES.LOADING_AXIAL
          : "",
      };
    }
    case "LOAD_AXIAL_IMAGES": {
      return {
        ...state,
        axialImages: action.payload.axialImages,
        mprCoordinates: {
          x: action.payload.axialImages.length / 2,
          y: action.payload.axialImages.length / 2,
        },
        mprSetupStatus: MPR_STATUSES.RECONSTRUCTING,
      };
    }
    case "MPR_TOOL_UPDATE": {
      return {
        ...state,
        mprCoordinates: action.payload,
      };
    }
    case "SET_MPR_VIEWPORTS": {
      const updatedViewports = setMPRViewports(
        state.viewports,
        state.studyImageData
      );
      return {
        ...state,
        viewports: updatedViewports,
      };
    }
    case "MPR_IMAGE_GENERATED":
      const viewports = state.viewports.map((viewport, index) => {
        const payloadKey =
          index === 0
            ? "axialImages"
            : index === 1
            ? "coronalImages"
            : "sagittalImages";
        if (payloadKey === "axialImages") {
          return viewport;
        }
        return {
          ...viewport,
          images: [action.payload[payloadKey]],
        };
      });
      return {
        ...state,
        mprData: action.payload,
        viewportFrameIndexes: [0, 0, 0],
        viewports,
        mprSetupStatus: MPR_STATUSES.COMPLETED,
      };
    case "SET_STUDY_DATA":
      const initialStudyImageMetaData = action.payload.studyImageData.map(
        () => null
      );
      const initialRawData = action.payload.studyImageData.map(() => null);
      return {
        ...state,
        modalityType: action.payload.modalityType,
        totalFrames: action.payload.totalFrames,
        studyImageData: action.payload.studyImageData,
        studyImageMetaData: initialStudyImageMetaData,
        rawMetaData: initialRawData,
      };
    case "SET_ACTIVE_TOOLS":
      return {
        ...state,
        activeTools: action.payload.activeTools,
      };
    case "INITIALIZE_VIEWPORT": {
      const initialViewport = formatViewportUpdates(
        INITIAL_VIEWER_GRID_STATE,
        state.modalityType,
        state.studyImageData,
        []
      );

      return {
        ...state,
        viewports: initialViewport,
      };
    }
    case "UPDATE_VIEWER_GRID": {
      const { updatedGridDimensions } = action.payload;
      const updatedViewports = formatViewportUpdates(
        updatedGridDimensions,
        state.modalityType,
        state.studyImageData,
        state.viewports
      );
      const updatedFrameIndexArray = new Array(
        updatedGridDimensions[0] * updatedGridDimensions[1]
      )
        .fill(0)
        .map((item, index) => {
          if (state.viewportFrameIndexes[index] !== undefined) {
            return state.viewportFrameIndexes[index];
          }
          return item;
        });

      return {
        ...state,
        gridDimensions: action.payload.updatedGridDimensions,
        viewports: updatedViewports,
        viewportFrameIndexes: updatedFrameIndexArray,
      };
    }

    case "SAVE_STUDY_METADATA": {
      const { studyImageIndex, studyImageMetaData, rawMetaData } =
        action.payload;
      const updatedStudyImageMetaData = state.studyImageMetaData.map(
        (metaData, index) =>
          index === studyImageIndex ? studyImageMetaData : metaData
      );
      const rawData = state.rawMetaData.map((meta, index) =>
        index === studyImageIndex ? rawMetaData : meta
      );
      return {
        ...state,
        studyImageMetaData: updatedStudyImageMetaData,
        rawMetaData: rawData,
      };
    }
    case "SET_IMAGE_FRAME_INDEX": {
      const { viewportIndex, frameIndex } = action.payload;
      const updatedFrameIndexes = state.viewportFrameIndexes.map(
        (viewportFrameIndex, index) =>
          index === viewportIndex ? frameIndex : viewportFrameIndex
      );

      return {
        ...state,
        viewportFrameIndexes: updatedFrameIndexes,
      };
    }
    case "SCRUB_IMAGE_FRAME_INDEX": {
      const { direction } = action.payload;
      const { viewportFrameIndexes } = state;
      const newFrameIndexes = viewportFrameIndexes.map((frameIndex, index) => {
        const newIndex =
          direction === DIRECTIONS.FORWARD ? frameIndex + 1 : frameIndex - 1;
        const totalFrames = state.viewports[index].totalFrames;
        const frameIndexForViewport =
          newIndex >= totalFrames
            ? 0
            : newIndex < 0
            ? totalFrames - 1
            : newIndex;
        return frameIndexForViewport;
      });
      return {
        ...state,
        viewportFrameIndexes: newFrameIndexes,
      };
    }
    case "CHANGE_ACTIVE_VIEWPORT": {
      const updatedViewports = state.viewports.map((viewport, index) =>
        index === 0 ? action.payload.newViewport : viewport
      );

      return {
        ...state,
        viewports: updatedViewports,
      };
    }
    case "UPDATE_VIEWPORT_IMAGE_DATA": {
      const { viewportIndex, viewportData } = action.payload;
      const updatedViewports = state.viewports.map((viewport, index) =>
        viewportIndex === index ? viewportData : viewport
      );
      return {
        ...state,
        viewports: updatedViewports,
      };
    }
    default:
      return state;
  }
};

const ViewerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <ViewerContext.Provider value={{ state, dispatch }}>
      {children}
    </ViewerContext.Provider>
  );
};

const useViewerContext = () => {
  const context = useContext(ViewerContext);
  if (!context) {
    throw new Error("useViewerContext must be used within a Viewer Provider");
  }

  return context;
};

export { ViewerProvider, useViewerContext };
