import { useCallback, useEffect, useState } from "react";
import { DIRECTIONS } from "../../consts/tools.consts";

export const useScrubImage = (
  imageStackLength: number,
  onChangeActiveViewport: (newViewportIndex: number) => void
) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  useEffect(() => {
    onChangeActiveViewport(currentImageIndex);
  }, [currentImageIndex, onChangeActiveViewport]);

  return useCallback(
    (direction: string) => {
      if (direction === DIRECTIONS.FORWARD) {
        setCurrentImageIndex((prev) =>
          prev === imageStackLength - 1 ? 0 : prev + 1
        );
      } else {
        setCurrentImageIndex((prev) =>
          prev === 0 ? imageStackLength - 1 : prev - 1
        );
      }
    },
    [imageStackLength]
  );
};
