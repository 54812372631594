import { getAuthorizationData } from "./getAuthorizationData";
import { snakeToCamelCase } from "./snakeToCamelCase";

export const fetchData = async (uri: string, convertToCamel = false) => {
  try {
    const { accessToken } = getAuthorizationData();
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/${uri}`,
      {
        method: "GET",
        headers,
      }
    );
    const responseData = await response.json();
    if (!response.ok) {
      const errorMessage = responseData.message ?? "Get Request Failed";
      if (errorMessage === "Forbidden: Token invalid") {
        window.location.href = "/login";
      }
      throw new Error(errorMessage);
    }

    if (convertToCamel) {
      const formattedResponseData = snakeToCamelCase(responseData);
      return formattedResponseData;
    } else {
      return responseData;
    }
  } catch (error) {
    return { errorMessage: error };
  }
};
