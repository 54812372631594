import { useInitializeTools } from "./useInitializeTools";
import { useActiveTools } from "./useActiveTools";
import { useImageMetaData } from "pages/viewer/hooks/metadata/useImageMetaData";
import { useParseImageMetadataOnLoad } from "./useParseImageMetadataOnLoad";

export const useTools = () => {
  const { imageMetaData } = useImageMetaData();
  const { addToolCallback, initializedTools } = useInitializeTools();
  const { onToolButtonClick } = useActiveTools(imageMetaData, initializedTools);
  useParseImageMetadataOnLoad(imageMetaData);
  return {
    tools: initializedTools,
    addToolCallback,
    onToolButtonClick,
  };
};
