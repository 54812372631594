import { TextField } from "@mui/material";
import { styled } from "@mui/system";

export const DefaultTextField = styled(TextField)<{ activecolor: string }>(
  ({ activecolor }) => ({
    "& .MuiInputBase-root": {
      color: activecolor,
      padding: "16.5px  0px",
      border: 0,
      boxSizing: "content-box",
      background: "none",
      height: "1.4375em",
      margin: 0,
      animationName: "mui-auto-fill-cancel",
      animationDuration: "10ms",
    },
    "& .MuiInputBase-input": {
      style: {
        color: "inherit", // Set input text color to inherit
      },
      borderColor: activecolor,
    },
    "& .MuiInputLabel-root": {
      color: activecolor,
    },
    "& .Mui-focused": {
      ".MuiOutlinedInput-notchedOutline ": {
        border: `2px solid ${activecolor}`,
      },
    },
  })
);

export const TextFieldContact = styled(TextField)({
  marginTop: 10,
  marginBottom: 10,
  display: "flex",
});
