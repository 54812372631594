import { useAppContext } from "context/app.context";
import { useCallback, useEffect } from "react";
import { refreshTokens } from "utils/networking/refreshTokens";
import secureLocalStorage from "react-secure-storage";
import { TokenData } from "models/auth.types";

const REFRESH_INTERVAL = 30000;
export const useRefreshTokens = () => {
  const {
    state: { isLoggedIn },
  } = useAppContext();

  const refreshAndUpdateTokens = useCallback(async () => {
    if (isLoggedIn) {
      const refreshAndUpdate = async () => {
        const refreshResponse =
          (await refreshTokens()) as unknown as TokenData | null;
        if (refreshResponse) {
          const tokenData = {
            accessToken: refreshResponse.accessToken,
            refreshToken: refreshResponse.refreshToken,
            expiresIn: refreshResponse.expiresIn,
          };
          secureLocalStorage.setItem("tokenData", tokenData);
        }
      };
      setInterval(refreshAndUpdate, REFRESH_INTERVAL);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    refreshAndUpdateTokens();
  }, [refreshAndUpdateTokens]);
};
