import { useEffect, useState } from "react";

const updateDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

export default function useWindowSize() {
  const [windowDimensions, setWindowDimensions] = useState(updateDimensions());

  useEffect(() => {
    // Create a resize listener on mount
    const handleResize = () => setWindowDimensions(updateDimensions());
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
