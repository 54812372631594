import { USER_TYPE_COLORS } from "theme/theme.consts";

export const getUserTypeColorName = (userTypeColour) => {
  switch (userTypeColour) {
    case USER_TYPE_COLORS.AQUA:
      return "aqua";
    case USER_TYPE_COLORS.GREEN:
      return "green";
    case USER_TYPE_COLORS.GREY:
      return "grey";
    default:
      return "maroon";
  }
};
