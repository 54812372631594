import { snakeToCamelCase } from "utils/networking/snakeToCamelCase";
import { FIELD_KEYS } from "../study-table-columns/columns.consts";
import { ViewerLink } from "../../../../../components/table/table-row-components/viewer-link/ViewerLink";
import { XCaliberLink } from "./components/XCaliberLink";
import { ValueDropdown } from "./components/ValueDropdown";
import { TableItemForm } from "./components/table-item-form/TableItemForm";
import { StudyData } from "../studyTable.types";
import { LockComponent } from "./components/LockComponent";
import { ValueCheckbox } from "./components/ValueCheckbox";
import { Documents } from "./components/documents/Documents";

export const getFieldValueComponent = (
  studyData,
  fieldName: string,
  isEditable: boolean
) => {
  const formattedStudyData = snakeToCamelCase(
    studyData
  ) as unknown as StudyData;
  switch (fieldName) {
    case FIELD_KEYS.XCALIBER:
      return <XCaliberLink studyData={formattedStudyData} />;
    case FIELD_KEYS.STUDY:
      return <ViewerLink studyData={formattedStudyData} />;
    case FIELD_KEYS.LOCK: {
      return <LockComponent studyData={formattedStudyData} />;
    }
    case FIELD_KEYS.DOCUMENTS: {
      return <Documents studyData={formattedStudyData} />;
    }
    case FIELD_KEYS.SELECT:
    case FIELD_KEYS.CLAIM_VERIFICATION:
    case FIELD_KEYS.STUDY_PROCESS: {
      return (
        <ValueCheckbox
          studyData={formattedStudyData}
          fieldName={fieldName}
          isEditable={isEditable}
        />
      );
    }
    case FIELD_KEYS.MEDICARE_NUMBER:
    case FIELD_KEYS.SERVICES:
    case FIELD_KEYS.PAT_BIRTHDATE:
    case FIELD_KEYS.PAT_NAME: {
      return (
        <TableItemForm
          fieldName={fieldName}
          studyData={formattedStudyData}
          isEditable={isEditable}
        />
      );
    }

    case FIELD_KEYS.STATUS: {
      return (
        <ValueDropdown
          studyData={formattedStudyData}
          fieldName={fieldName}
          isEditable={isEditable}
        />
      );
    }
    default:
      return null;
  }
};
