// use this method for image URLS - it will return with http:// at the start
export function studyImageFinder_png(input) {
  return studyImageFinder_advanced(input, "https", "image/png");
}

export function studyImageFinder_jpeg(input) {
  return studyImageFinder_advanced(input, "https", "image/jpeg");
}

export function studyImageFinder_dicom(input) {
  // return studyImageFinder_advanced(input, 'https', 'application/dicom')
  return studyImageFinder_advanced(input, "dicomweb", "application/dicom");
}

// WARNING: if you don't pass in the second parameter, the
// URL will start with dicomweb:// which is not recognised by the browser
export function studyImageFinder_advanced(input, protocol, contentType) {
  //CHANGED SO THAT ONLY INCLUDES FIRST IMAGE OF MULTI-FRAMED IMAGES, WITH THE REST IN A SEPARATE ARRAY
  const urlImage = `${process.env.REACT_APP_ITX_VIEWER_URL}/wado?requestType=WADO&`;
  let images = [];
  let n = -1;
  if (!input && !input.studyArray && !input.studyArray[0]) {
    images.push({
      id: n,
      image: [""],
      study_pk: "", //input.study[x].pk,
      study_iuid: "",
      series_iuid: "",
      sop_iuid: "",
    });
    return images;
  }

  //For determining which studies have their instances as sub-sets (frames) of the series
  const maxInstancesToShow = 30;
  const framedModalities = ["CT", "US", "RF", "XA", "MR", "NM", "OT"];

  for (let i = 0; i < input.studyArray.length; i++) {
    for (let j = 0; j < input.studyArray[i].seriesArray.length; j++) {
      const frames = [];
      const modality = input.studyArray[i].seriesArray[j].modality;
      const numInstances = input.studyArray[i].seriesArray[j].num_instances;
      const isFramedModality = framedModalities.includes(modality);

      for (
        let k = 0;
        k < input.studyArray[i].seriesArray[j].instanceArray.length;
        k++
      ) {
        let imageArray = [];
        const imageURL =
          "studyUID=" +
          input.studyArray[i].study_iuid +
          "&seriesUID=" +
          input.studyArray[i].seriesArray[j].series_iuid +
          "&objectUID=" +
          input.studyArray[i].seriesArray[j].instanceArray[k].sop_iuid +
          "&frameNumber=" +
          1;
        imageArray.push(urlImage + imageURL + "&contentType=" + contentType);

        n++;

        let pk = input.studyArray[i].pk;
        if (!pk) {
          pk = input.studyArray[i].studypk;
        }

        if (isFramedModality || numInstances > maxInstancesToShow) {
          frames.push({
            id: n,
            image: imageArray,
            study_pk: pk, //input.study[x].pk,
            study_iuid: input.studyArray[i].study_iuid,
            series_iuid: input.studyArray[i].seriesArray[j].series_iuid,
            sop_iuid:
              input.studyArray[i].seriesArray[j].instanceArray[k].sop_iuid,
          });
        } else {
          images.push({
            id: n,
            image: imageArray,
            study_pk: pk, //input.study[x].pk,
            study_iuid: input.studyArray[i].study_iuid,
            series_iuid: input.studyArray[i].seriesArray[j].series_iuid,
            sop_iuid:
              input.studyArray[i].seriesArray[j].instanceArray[k].sop_iuid,
          });
        }
      }

      if (isFramedModality || numInstances > maxInstancesToShow) {
        images.push({
          ...frames[0],
          frames: frames,
        });
      }
    }
  }

  return images;
}
