import { CssBaseline, Box, Container, Typography } from "@mui/material";
import { motion } from "framer-motion";
import Spinner from "react-bootstrap/Spinner";
import { DarkModeSwitch } from "../../components/DarkmodeSwitch/DarkmodeSwitch";
import { Copyright } from "../../components/Copywrite";
import { useDarkModeTheme } from "hooks/useDarkmodeTheme";
import { useCallback, useState } from "react";
import { GoogleConnectSection } from "components/google/google-connect/GoogleConnect";
import { useAppContext } from "context/app.context";
import { GoogleLoginSection } from "components/google/google-login/GoogleLoginSection";

export const GoogleConnect = () => {
  const { backgroundThemeColor, borderThemeColor, textThemeColor } =
    useDarkModeTheme();

  const [isLoading, setIsLoading] = useState(false);
  const toggleIsLoading = useCallback(() => {
    setIsLoading((prev) => !prev);
  }, [setIsLoading]);

  const {
    state: { userAuthData },
  } = useAppContext();
  const googleId = userAuthData?.googleId;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.25,
      }}
      exit={{ opacity: 0 }}
    >
      <>
        {isLoading ? (
          <Spinner
            animation="border"
            role="status"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              width: "4rem",
              height: "4rem",
              color: textThemeColor,
            }}
          ></Spinner>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.25,
            }}
            exit={{ opacity: 0 }}
          >
            <DarkModeSwitch />
            <form
              style={{
                backgroundColor: backgroundThemeColor,
                borderColor: borderThemeColor,
              }}
              className="form login-form "
            >
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{ color: textThemeColor, textAlign: "center" }}
                    variant="h5"
                    sx={{ color: "black" }}
                  >
                    Connect to iTX Cloud PACS with Google
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    style={{ color: textThemeColor }}
                  >
                    You may need to connect with google to access some features
                  </Typography>
                  <Box component={"div"} className="box" sx={{ mt: 3, mb: 2 }}>
                    {googleId ? (
                      <GoogleLoginSection toggleIsLoading={toggleIsLoading} />
                    ) : (
                      <GoogleConnectSection toggleIsLoading={toggleIsLoading} />
                    )}
                  </Box>
                </Box>
                <Copyright
                  style={{ color: textThemeColor }}
                  sx={{ mt: 8, mb: 4 }}
                />
              </Container>
            </form>
          </motion.div>
        )}
      </>
    </motion.div>
  );
};
