import {
  TableFooter,
  TableRow,
  IconButton,
  Box,
  useTheme,
  Pagination,
  Typography,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useMemo } from "react";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5, marginBottom: "1rem" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

interface TablePagintionProps {
  currentPage: number;
  rowsPerPage: number;
  columns: any;
  totalRows: number;
  onHandlePageChange: (newPage: number) => void;
}

const PAGE_SIZE = 10;
export const TablePagination = ({
  columns,
  currentPage,
  rowsPerPage,
  totalRows,
  onHandlePageChange,
}: TablePagintionProps) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onHandlePageChange(newPage - 1);
  };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   // setRowsPerPage(parseInt(event.target.value, 10));
  //   // setPage(0);
  // };

  const count = useMemo(() => {
    return Math.ceil(totalRows / PAGE_SIZE); // Use Math.ceil to include all items
  }, [totalRows]);

  const { start, end } = useMemo(() => {
    const start = currentPage * PAGE_SIZE + 1;
    const end = Math.min(start + PAGE_SIZE - 1, totalRows);
    return { start, end };
  }, [currentPage, totalRows]);
  return (
    <TableFooter>
      <TableRow>
        <td colSpan={columns.length}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "0 auto",
              justifyContent: "center",
              padding: "1rem",
            }}
          >
            <Typography
              style={{
                marginTop: "5px",
              }}
            >
              {start}-{end} of {totalRows}
            </Typography>
            <Pagination
              page={currentPage + 1}
              count={count}
              showFirstButton
              showLastButton
              onChange={handleChangePage}
            />
          </Box>
        </td>
      </TableRow>
    </TableFooter>
  );
};
