import { RawStudyImageData } from "models/study.types";
import {
  EMPTY_IMAGE,
  FRAMED_MODALITIES,
  MAX_IMAGE_INSTANCES_TO_SHOW,
} from "../dicomViewer.consts";
import { StudyImageData } from "../dicomViewer.types";

const ROOT_URL = `${process.env.REACT_APP_ITX_VIEWER_URL}/wado?requestType=WADO&`;

export const formatStudyImageUrls = (
  studyData: RawStudyImageData[],
  contentType?: string
): { images: StudyImageData[]; studyModality: string } => {
  try {
    const images: StudyImageData[] = [];
    if (!studyData) {
      console.warn("empty study data");
      return { images: [EMPTY_IMAGE], studyModality: null };
    }

    let imageId = 0;
    let studyModality = "";
    studyData.forEach((study) => {
      const { seriesArray, studyIuid, pk } = study;
      seriesArray.forEach((seriesData) => {
        const { modality, numInstances, seriesIuid, instanceArray } =
          seriesData;
        studyModality = modality;
        const isFramedModality = FRAMED_MODALITIES.includes(modality);
        if (instanceArray) {
          if (isFramedModality || numInstances > MAX_IMAGE_INSTANCES_TO_SHOW) {
            const orderedInstanceArray = instanceArray
              .sort((a, b) => Number(a.instNo) - Number(b.instNo))
              .reverse();

            const frameArray = orderedInstanceArray.map(
              (instanceData, index) => {
                const { sopIuid } = instanceData;
                const imageUrl = `${ROOT_URL}studyUID=${studyIuid}&seriesUID=${seriesIuid}&objectUID=${sopIuid}${
                  contentType ? `application/dicom` : ""
                }`;
                return {
                  id: imageId + index,
                  image: [imageUrl],
                  studyPk: pk,
                  studyIuid,
                  seriesIuid,
                  sopIuid,
                };
              }
            );
            imageId += frameArray.length - 1;
            const frameImageIds = frameArray.map(({ image }) => image[0]);
            images.push({
              ...frameArray[0],
              frames: frameArray,
              frameImageIds,
            });
          } else {
            instanceArray.forEach(async (instanceData) => {
              const { sopIuid } = instanceData;
              const imageUrl = `${ROOT_URL}studyUID=${studyIuid}&seriesUID=${seriesIuid}&objectUID=${sopIuid}${
                contentType ? `` : ""
              }`;

              images.push({
                id: imageId,
                image: [imageUrl],
                studyPk: pk,
                studyIuid,
                seriesIuid,
                sopIuid,
              });
              imageId++;
            });
          }
        }
      });
    });
    return { images: images, studyModality };
  } catch {
    console.error("error formatting image urls");
    return { images: [], studyModality: null };
  }
};
