import { styled } from "@mui/system";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactNode } from "react";

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: "auto",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: -1,
  minHeight: 26,
  "&.Mui-expanded": {
    minHeight: 26,
  },
  "& .MuiAccordionSummary-content": {
    margin: "0.1rem 0",
    "&.Mui-expanded": {
      margin: "0.1rem 0",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface DicomToolbarSectionProps {
  sectionTitle: string;
  children: ReactNode;
}
export const DicomToolbarSection = ({
  sectionTitle,
  children,
}: DicomToolbarSectionProps) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>{sectionTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
