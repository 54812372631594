export const DICOM_DETAILS_VERTICAL_POSITIONS = {
  TOP: "TOP",
  BOTTOM: "BOTTOM",
};

export const DICOM_DETAILS_HORIZONTAL_POSITIONS = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};

export const EMPTY_IMAGE = {
  id: -1,
  image: [],
  studyPk: "",
  studyIuid: "",
  seriesIuid: "",
  sopIuid: "",
};

export const INITIAL_VIEWER_GRID_STATE = [1, 1];

export const MAX_IMAGE_INSTANCES_TO_SHOW = 30;
export const FRAMED_MODALITIES = ["CT", "US", "RF", "XA", "MR", "NM", "OT"];

export const MIN_CLIENT_WIDTH_PX = 100;
export const DICOM_EXPORT_TYPES = {
  DICOM: "dicom",
  JPG: "jpeg",
  PNG: "png",
};

// https://www.dicomlibrary.com/dicom/dicom-tags/ to see tags
export const METADATA_KEYS = {
  x00100010: "patientName",
  x00100020: "patientId",
  x00280030: "pixelSpacing",
  x00080080: "institutionName",
  x00080090: "referringPhysiciansName",
  x0008009C: "consultingPhysiciansName",
  x00081050: "performingPhysiciansName",
  x00321032: "requestingPhysiciansName",
  x00080030: "studyTime",
  x00080020: "studyDate",
  x00280103: "pixelRepresentation",
  x00180086: "echoNumber",
  x00283010: "voiLutSequence",
  x00283002: "lutDescriptior",
  x00283003: "lutExplanation",
  x00283006: "lutData",
  x00281050: "windowCenter",
  x00281051: "windowWidth",
  x00200052: "frameOfReferenceUID",
  x00180050: "sliceThickness",
  x00201041: "silceLocation",
};
