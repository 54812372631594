import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import { ALL_TOOLS } from "../consts/tools.consts";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

export class ClearAnnotations extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "ClearAnnotations",
      supportedInteractionTypes: ["Mouse", "Touch"],
      configuration: {},
    };
    super(props, defaultProps);
  }

  createNewMeasurement(event) {
    const tools = ALL_TOOLS;
    this.resetToolState(event.element, tools);
    this.renderToolData(event);

    //Get a reference to the external toolInstructions element
    const toolInstructions = document.getElementById("toolInstructions");
    //Set the Tool Instructions contents to empty
    toolInstructions.innerHTML = "";
  }

  resetToolState(element, tools) {
    // Loop through all the tool types
    for (let nameIndex = 0; nameIndex < tools.length; nameIndex++) {
      //   Get the tool data for this tool type
      let toolType = tools[nameIndex].name;
      ////console.log('looking at tool ' + toolType);
      let toolData = cornerstoneTools.getToolState(element, toolType);
      ////console.log('tool data:');
      ////console.log(toolData);
      if (toolData !== undefined) {
        ////console.log(toolType + ' has ' + toolData.data.length + ' datasets');
        const dataLength = toolData.data.length;
        for (
          let toolDataIndex = 0;
          toolDataIndex < dataLength;
          toolDataIndex++
        ) {
          ////console.log('removing dataset ' + toolDataIndex);
          cornerstoneTools.removeToolState(element, toolType, toolData.data[0]); //Set at 0 because that data refreshes each time and so the larger entries don't exist anymore
          ////console.log("tool data removed & image updated");
        }
      }
    }
    cornerstone.updateImage(element);
  }

  renderToolData(evt) {}

  //TODO Create a separate function that both CreateNewMeasurement and autoPerform call, rather than duplicating the code

  autoPerform(element) {
    const tools = ALL_TOOLS;

    this.resetToolState(element.element, tools);
    this.renderToolData(element);

    //Get a reference to the external toolInstructions element
    const toolInstructions = document.getElementById("toolInstructions");
    //Set the Tool Instructions contents to empty
    toolInstructions.innerHTML = "";
  }
}
