import React from "react";

interface PDFViewerProps {
  pdfUrl: string;
  pdfTitle: string;
}

export const PDFViewer = ({ pdfUrl, pdfTitle }: PDFViewerProps) => {
  return (
    <div>
      <object
        width="1100px"
        height="800px"
        data={pdfUrl}
        type="application/pdf"
      >
        {" "}
      </object>
    </div>
  );
};
