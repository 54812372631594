// @ts-nocheck
import cornerstoneTools from "cornerstone-tools";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import cornerstone from "cornerstone-core";
import { TOOL_IDS } from "../consts/tools.consts";
import { getDimensionData } from "../measurement-tools/measurementToolUtils";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

export class GeorgesLine extends BaseAnnotationTool {
  constructor() {
    super({
      name: TOOL_IDS.GEORGES_LINE,
      supportedInteractionTypes: ["Mouse", "Touch"],
    });

    this.hasIncomplete = false;
    this.imageMetaData = {};
    this.setImageMetadata = this.setImageMetadata.bind(this);
  }

  public setImageMetadata(imageMetaData: ImageMetadata) {
    if (imageMetaData) {
      this.imageMetaData = imageMetaData;
    }
  }

  activeCallback(element) {}
  preMouseDownCallback(event) {}
  createNewMeasurement(event) {
    const goodEventData =
      event && event.currentPoints && event.currentPoints.image;
    if (!goodEventData) {
      // //console.log(
      //     `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      // );
      return;
    }

    const { x, y } = event.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,

      complete: false,

      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: false,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    const validParameters =
      data && data.handles && data.handles.start && data.handles.end;

    if (!validParameters) {
      // //console.log(
      //     `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      // );
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const line =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 10;

    if (line) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
    }
    return line;
  }

  distanceFromPoint(event) {}

  renderToolData(event) {
    const eventData = event.detail;
    const { image, element, canvasContext } = eventData;
    // We have tool data for this element - iterate over each one and draw it
    const context = Drawing.getNewContext(eventData.canvasContext.canvas);
    const toolData = cornerstoneTools.getToolState(
      event.currentTarget,
      this.name
    );
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );

    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      const textBoxAnchorPoints = (handles) =>
        textBoxAnchorPointsLine(data.handles);

      const newContext = Drawing.getNewContext(canvasContext.canvas);

      const firstpointx = {
        x: data.handles.end.x,
        y: data.handles.end.y,
      };

      const secondpointx = {
        x: data.handles.start.x,
        y: data.handles.end.y,
      };

      const textCoordsStart = cornerstone.pixelToCanvas(
        eventData.element,
        firstpointx
      );

      const textCoordsEnd = cornerstone.pixelToCanvas(
        eventData.element,
        secondpointx
      );

      let suffix = "mm";
      if (!rowPixelSpacing || !colPixelSpacing) {
        suffix = " pixels";
      }

      const dist = (
        distance(data.handles.start.x, data.handles.end.x) * rowPixelSpacing
      ).toFixed(2);

      Drawing.drawArrow(context, textCoordsStart, textCoordsEnd, "red", 1);
      Drawing.draw(newContext, (context) => {
        Drawing.drawHandles(context, eventData, data.handles, (5, "green"));

        Drawing.drawLinkedTextBox(
          context,
          element,
          data.handles.textBox,
          dist + suffix,
          data.handles,
          textBoxAnchorPoints,
          "red",
          2,
          true
        );
      });

      function distance(A, B) {
        if (A > B) {
          let dist = A - B;
          return dist.toFixed(2);
        } else {
          let dist = B - A;
          return dist.toFixed(2);
        }
      }
    }
  }
}

function textBoxAnchorPointsLine(handles) {
  return [handles.start, handles.end];
}
