export const FIELD_KEYS = {
  SELECT: "SELECT",
  PAT_ID: "patId",
  PAT_NAME: "patName",
  IRN_NUMBER: "irnNumber",
  PAT_BIRTHDATE: "patBirthdate",
  PAT_SEX: "patSex",
  STUDY_DATETIME: "studyDatetime",
  PHYSICIAN: "physician",
  MODS_IN_STUDY: "modsInStudy",
  XCALIBER: "xCaliber",
  STUDY_PROCESS: "studyProcess",
  STUDY_PK: "studypk",
  LOCK: "editLock",
  STATUS: "studyStatus",
  MEDICARE_NUMBER: "medicareNumber",
  SERVICES: "services",
  DOCUMENTS: "documents",
  CLAIM_STATUS: "claimStatus",
  CLAIM_VERIFICATION: "claimVerification",
  INSTITUTION: "institution",
  STUDY: "study",
  REFERRAL_REPORT_IDS: "refRepIds",
  MEDICAL_REPORT_IDS: "medRepIds",
};

export const SORTABLE_COLUMNS = [
  FIELD_KEYS.PAT_NAME,
  FIELD_KEYS.PAT_SEX,
  FIELD_KEYS.CLAIM_STATUS,
  FIELD_KEYS.SERVICES,
  FIELD_KEYS.STATUS,
  FIELD_KEYS.PAT_BIRTHDATE,
  FIELD_KEYS.PAT_ID,
  FIELD_KEYS.MEDICARE_NUMBER,
  FIELD_KEYS.STUDY_DATETIME,
  FIELD_KEYS.PHYSICIAN,
  FIELD_KEYS.LOCK,
  FIELD_KEYS.MODS_IN_STUDY,
];
export const SELECT_COLUMN = {
  field: FIELD_KEYS.SELECT,
  headerName: "",
  width: 40,
};

export const PID_COLUMN = {
  field: FIELD_KEYS.PAT_ID,
  headerName: "PID",
  width: 100,
};
export const PATIENT_NAME_COLUMN = {
  field: FIELD_KEYS.PAT_NAME,
  headerName: "Patient Name",
  width: 140,
};
export const DOB_COLUMN = {
  field: FIELD_KEYS.PAT_BIRTHDATE,
  headerName: "DOB",
  width: 140,
};
export const SEX_COLUMN = {
  field: FIELD_KEYS.PAT_SEX,
  headerName: "Sex",
  width: 100,
};
export const STUDY_DATE_COLUMN = {
  field: FIELD_KEYS.STUDY_DATETIME,
  headerName: "Study Date",
  width: 140,
};
export const DOCTOR_COLUMN = {
  field: FIELD_KEYS.PHYSICIAN,
  headerName: "Doctor",
  width: 140,
};
export const MOD_COLUMN = {
  field: FIELD_KEYS.MODS_IN_STUDY,
  headerName: "MOD",
  width: 70,
};
export const XCALIBER_COLUMN = {
  field: FIELD_KEYS.XCALIBER,
  headerName: "AI Report",
  width: 70,
};

export const STUDY_COLUMN = {
  field: FIELD_KEYS.STUDY,
  headerName: "Study",
  width: 70,
};

export const SORT_DIRECTIONS = {
  DESC: "desc",
  ASC: "asc",
};

export const HL7_DECODE = [
  FIELD_KEYS.PAT_NAME,
  FIELD_KEYS.STUDY_DATETIME,
  FIELD_KEYS.PAT_BIRTHDATE,
  FIELD_KEYS.PHYSICIAN,
];
export const DEFAULT_COLUMNS = [
  SELECT_COLUMN,
  PID_COLUMN,
  STUDY_DATE_COLUMN,
  PATIENT_NAME_COLUMN,
  DOB_COLUMN,
  SEX_COLUMN,
  DOCTOR_COLUMN,
  MOD_COLUMN,
  STUDY_COLUMN,
];

export const STUDY_PROCESS_COLUMN = {
  field: FIELD_KEYS.STUDY_PROCESS,
  headerName: "Mark",
  width: 70,
};
export const PK_COLUMN = {
  field: FIELD_KEYS.STUDY_PK,
  headerName: "STUDY PK",
  width: 100,
};
export const LOCK_COLUMN = {
  field: FIELD_KEYS.LOCK,
  headerName: "",
  width: 100,
};
export const STATUS_COLUMN = {
  field: FIELD_KEYS.STATUS,
  headerName: "Status",
  width: 100,
};
export const MEDICARE_COLUMN = {
  field: FIELD_KEYS.MEDICARE_NUMBER,
  headerName: "Medicare",
  width: 100,
};
export const SERVICES_COLUMN = {
  field: FIELD_KEYS.SERVICES,
  headerName: "Services",
  width: 100,
};
export const DOCUMENTS_COLUMN = {
  field: FIELD_KEYS.DOCUMENTS,
  headerName: "Documents",
  width: 100,
};
export const CLAIM_COLUMN = {
  field: FIELD_KEYS.CLAIM_STATUS,
  headerName: "Claim",
  width: 100,
};
export const VERIFIED_COLUMN = {
  field: FIELD_KEYS.CLAIM_VERIFICATION,
  headerName: "Verified",
  width: 100,
};
export const INSTITUTION_COLUMN = {
  field: FIELD_KEYS.INSTITUTION,
  headerName: "Institution",
  width: 100,
};

export const RADIOLOGIST_EDITABLE_FIELDS = [
  FIELD_KEYS.LOCK,
  FIELD_KEYS.STUDY_PROCESS,
  FIELD_KEYS.STATUS,
  FIELD_KEYS.DOCUMENTS,
  FIELD_KEYS.CLAIM_VERIFICATION,
];

export const CHIRO_MEDICARE_EDITABLE_FIELDS = [
  FIELD_KEYS.LOCK,
  FIELD_KEYS.STATUS,
  FIELD_KEYS.MEDICARE_NUMBER,
  FIELD_KEYS.SERVICES,
  FIELD_KEYS.PAT_NAME,
  FIELD_KEYS.PAT_BIRTHDATE,
  FIELD_KEYS.DOCUMENTS,
  FIELD_KEYS.CLAIM_VERIFICATION,
];

export const EDITABLE_MEDICARE_ADMIN_FIELDS = [
  FIELD_KEYS.STUDY_PROCESS,
  FIELD_KEYS.LOCK,
  FIELD_KEYS.STATUS,
  FIELD_KEYS.MEDICARE_NUMBER,
  FIELD_KEYS.SERVICES,
  FIELD_KEYS.PAT_NAME,
  FIELD_KEYS.PAT_BIRTHDATE,
  FIELD_KEYS.DOCUMENTS,
  FIELD_KEYS.CLAIM_VERIFICATION,
];
