import { useAppContext } from "context/app.context";
import { useMemo } from "react";
import { useUserType } from "./useUserType";

export const useDarkModeTheme = () => {
  const {
    state: { isDarkModeOn },
  } = useAppContext();
  const { userTypeColour } = useUserType();
  const backgroundThemeColor = useMemo(
    () => (isDarkModeOn ? "#212121" : "#f0f0f0"),
    [isDarkModeOn]
  );
  const textThemeColor = useMemo(
    () => (isDarkModeOn ? "#ffffff" : "#000000"),
    [isDarkModeOn]
  );
  const inputThemeColor = useMemo(
    () => (isDarkModeOn ? "#ffffff" : "#000000"),
    [isDarkModeOn]
  );
  const borderThemeColor = useMemo(
    () => (isDarkModeOn ? "#7b1fa2" : "#545454"),
    [isDarkModeOn]
  );

  const brandThemeColor = useMemo(
    () => (isDarkModeOn ? userTypeColour : "#7f1c53"),
    [isDarkModeOn, userTypeColour]
  );
  const textFieldThemeColor = useMemo(
    () => (isDarkModeOn ? "#ffffff" : "#7b1fa2"),
    [isDarkModeOn]
  );

  return {
    backgroundThemeColor,
    textFieldThemeColor,
    borderThemeColor,
    brandThemeColor,
    inputThemeColor,
    textThemeColor,
    isDarkModeOn,
  };
};
