import Util from "../../api/Util";
import { MEASUREMENT_SUFFIXES } from "../../consts/tools.consts";
import { getUnit } from "../miscellaneousToolUtils";

const DEFAULT_OPTIONS = {
  showMinMax: false,
  showHounsfieldUnits: false,
};
const DEFAULT_CACHED_STATS = {
  area: 0,
  radius: 0,
  perimeter: 0,
  mean: 0,
  stdDev: 0,
  min: 0,
  max: 0,
  meanStdDevSUV: { mean: 0, stdDev: 0 },
};
export const createTextBoxContent = (
  context,
  isColorImage,
  cachedStats = DEFAULT_CACHED_STATS,
  modality,
  hasPixelSpacing,
  options = DEFAULT_OPTIONS,
  circleTitle = "Circle"
) => {
  const { meanStdDevSUV, min, max, mean, stdDev, perimeter, radius } =
    cachedStats;
  const showMinMax = options.showMinMax || false;
  const textLines = [circleTitle];

  // Don't display mean/standardDev for color images
  const otherLines = [];

  if (!isColorImage) {
    const hasStandardUptakeValues = meanStdDevSUV && meanStdDevSUV.mean !== 0;
    const unit = getUnit(modality, options.showHounsfieldUnits);

    let meanString = `Mean: ${Util.numbersWithCommas(mean.toFixed(2))} ${unit}`;
    const stdDevString = `Std Dev: ${Util.numbersWithCommas(
      stdDev.toFixed(2)
    )} ${unit}`;

    // If this image has SUV values to display, concatenate them to the text line
    if (hasStandardUptakeValues) {
      const SUVtext = " SUV: ";

      const meanSuvString = `${SUVtext}${Util.numbersWithCommas(
        meanStdDevSUV.mean.toFixed(2)
      )}`;
      const stdDevSuvString = `${SUVtext}${Util.numbersWithCommas(
        meanStdDevSUV.stdDev.toFixed(2)
      )}`;

      const targetStringLength = Math.floor(
        context.measureText(`${stdDevString}     `).width
      );

      while (context.measureText(meanString).width < targetStringLength) {
        meanString += " ";
      }

      //otherLines.push(`${meanString}${meanSuvString}`);
      //otherLines.push(`${stdDevString}     ${stdDevSuvString}`);
    } else {
      //otherLines.push(`${meanString}     ${stdDevString}`);
    }

    if (showMinMax) {
      let minString = `Min: ${min} ${unit}`;
      const maxString = `Max: ${max} ${unit}`;
      const targetStringLength = hasStandardUptakeValues
        ? Math.floor(context.measureText(`${stdDevString}     `).width)
        : Math.floor(context.measureText(`${meanString}     `).width);

      while (context.measureText(minString).width < targetStringLength) {
        minString += " ";
      }

      otherLines.push(`${minString}${maxString}`);
    }
  }

  if (radius) {
    textLines.push(formatLength(radius, "Radius", true));
  }
  if (perimeter) {
    textLines.push(formatLength(perimeter, "Perimeter", true));
  }
  otherLines.forEach((x) => textLines.push(x));

  return textLines;
};

const formatLength = (value, name, hasPixelSpacing) => {
  if (!value) {
    return "";
  }
  const suffix = hasPixelSpacing
    ? MEASUREMENT_SUFFIXES.MM
    : MEASUREMENT_SUFFIXES.PIXELS;

  return `${name}: ${Util.numbersWithCommas(value.toFixed(1))} ${suffix}`;
};
