//@ts-nocheck
import cornerstoneTools from "cornerstone-tools";
import cornerstoneMath from "cornerstone-math";
import cornerstone from "cornerstone-core";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import Manipulators from "../api/Manipulators";
import { TOOL_IDS } from "../consts/tools.consts";
import { calculateEllipseStats, getUnit } from "./miscellaneousToolUtils";
import { getDimensionData } from "../measurement-tools/measurementToolUtils";
const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);
const circleRoiCursor = cornerstoneTools.importInternal("tools/cursors");

export class DARA extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: TOOL_IDS.DARA,
      supportedInteractionTypes: ["Mouse", "Touch"],
      svgCursor: circleRoiCursor,
      configuration: {
        centerPointRadius: 6,
        renderDashed: false,
        hideHandlesIfMoving: true,
        handleRadius: 6,
        drawHandlesOnHover: true,
      },
    };
    super(props, defaultProps);
    this.hasIncomplete = false;
    this.throttledUpdateCachedStats = Util.throttle(
      this.updateCachedStats,
      110
    );
    this.imageMetaData = {};
    this.setImageMetadata = this.setImageMetadata.bind(this);
  }

  public setImageMetadata(imageMetaData: ImageMetadata) {
    if (imageMetaData) {
      this.imageMetaData = imageMetaData;
    }
  }
  updateCachedStats(image, element, data) {
    const seriesModule =
      cornerstone.metaData.get("generalSeriesModule", image.imageId) || {};
    const modality = seriesModule.modality;
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );
    const stats = calculateEllipseStats(
      image,
      element,
      data.handles.start,
      data.handles.end,
      modality,
      rowPixelSpacing,
      colPixelSpacing
    );

    if (data.complete) {
      const stats2 = calculateEllipseStats(
        image,
        element,
        data.handles.start2,
        data.handles.end2,
        modality,
        rowPixelSpacing,
        colPixelSpacing
      );
      data.cachedStats2 = stats2;

      // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
      const dx =
        (data.handles.start2.x - data.handles.start.x) * (colPixelSpacing || 1);
      const dy =
        (data.handles.start2.y - data.handles.start.y) * (rowPixelSpacing || 1);

      // Calculate the length, and create the text variable with the millimeters or pixels suffix
      const length = Math.sqrt(dx * dx + dy * dy);

      // Store the length inside the tool for outside access
      data.length = length;
    }

    data.cachedStats = stats;
    data.invalidated = false;
  }

  addNewMeasurement(evt, interactionType) {
    evt.preventDefault(); //Makes triggerEvent(el, type, detail) return false
    evt.stopPropagation(); //Prevents MOUSE_DOWN_ACTIVATE from firing (MOUSE_DOWN_ACTIVATE is used to create new measurement data for mouse events).

    const eventData = evt.detail;

    let measurementData;
    let toMoveHandle;
    let doneMovingCallback = (success) => {
      // DoneMovingCallback for first measurement.
      if (!success) {
        cornerstoneTools.removeToolState(element, this.name, measurementData); //Removes specific tool state from the toolStateManager (in this case removes the measurementData)

        return;
      }
      const eventType = cornerstone.EVENTS.MEASUREMENT_COMPLETED;
      const eventData = {
        toolName: this.name,
        toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
        element,
        measurementData,
      };

      Util.triggerEvent(element, eventType, eventData); //Triggers a custom event
    };

    // Search for incomplete measurements
    const element = evt.detail.element;
    const pendingMeasurement = this.getIncomplete(element);

    if (pendingMeasurement) {
      measurementData = pendingMeasurement;
      measurementData.complete = true;
      measurementData.handles.start2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: false,
      };
      measurementData.handles.end2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      toMoveHandle = measurementData.handles.end2;
      this.hasIncomplete = false;
      doneMovingCallback = (success) => {
        // DoneMovingCallback for second measurement
        if (!success) {
          cornerstoneTools.removeToolState(element, this.name, measurementData);

          return;
        }

        const eventType = cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED;
        const eventData = {
          toolName: this.name,
          toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
          element,
          measurementData,
        };

        Util.triggerEvent(element, eventType, eventData);
      };
    } else {
      measurementData = this.createNewMeasurement(eventData);
      cornerstoneTools.addToolState(element, this.name, measurementData);
      toMoveHandle = measurementData.handles.end;
    }

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);

    Manipulators.moveNewHandle(
      eventData,
      this.name,
      measurementData,
      toMoveHandle,
      this.options,
      interactionType,
      doneMovingCallback
    );
  }

  getIncomplete(element) {
    const toolState = cornerstoneTools.getToolState(element, this.name);

    if (toolState && Array.isArray(toolState.data)) {
      return toolState.data.find(({ complete }) => complete === false);
    }
  }

  createNewMeasurement(eventData) {
    this.hasIncomplete = true;
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      console.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );

      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: "",
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        start2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        initialRotation: eventData.viewport.rotation,
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: true,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
        textBox2: {
          active: false,
          hasMoved: false,
          movesIndependently: true,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
        textBoxLine: {
          active: false,
          hasMoved: false,
          movesIndependently: true,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
      handles2: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords, interactionType) {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;

    const getDistance = cornerstoneMath.point.distance;

    if (!hasStartAndEndHandles) {
      console.warn(
        `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      );
    }

    if (!hasStartAndEndHandles || data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const distance = interactionType === "mouse" ? 15 : 25;

    const startCanvas = cornerstone.pixelToCanvas(element, data.handles.start);

    const endCanvas = cornerstone.pixelToCanvas(element, data.handles.end);

    const startCanvas2 = cornerstone.pixelToCanvas(
      element,
      data.handles.start2
    );

    const endCanvas2 = cornerstone.pixelToCanvas(element, data.handles.end2);
    // StartCanvas is the center of the circle
    const distanceFromCenter = getDistance(startCanvas, coords);
    const distanceFromCenter2 = getDistance(startCanvas2, coords);

    // Getting radius of circle annotation in canvas
    const radius = getDistance(startCanvas, endCanvas);
    const radius2 = getDistance(startCanvas2, endCanvas2);

    // Checking if point is near the tool by comparing its distance from the center of the circle
    const circle1 =
      distanceFromCenter > radius - distance / 2 &&
      distanceFromCenter < radius + distance / 2;
    const circle2 =
      distanceFromCenter2 > radius2 - distance / 2 &&
      distanceFromCenter2 < radius2 + distance / 2;
    const line =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.start2,
        coords
      ) < 25;

    if (circle1) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
      data.handles.start2.movesIndependently = true;
      data.handles.end2.movesIndependently = true;
    } else if (circle2) {
      data.handles.start.movesIndependently = true;
      data.handles.end.movesIndependently = true;
      data.handles.start2.movesIndependently = false;
      data.handles.end2.movesIndependently = false;
    } else if (line) {
      data.handles.start.movesIndependently = false;
      data.handles.start2.movesIndependently = false;
      data.handles.end.movesIndependently = false;
      data.handles.end2.movesIndependently = false;
    }

    // Checking if point is near the tool by comparing its distance from the center of the circle
    return circle1 || circle2 || line;
  }

  renderToolData(evt) {
    const toolData = cornerstoneTools.getToolState(
      evt.currentTarget,
      this.name
    );
    if (!toolData) {
      return;
    }

    const getDistance = cornerstoneMath.point.distance;
    const eventData = evt.detail;
    const { image, element, canvasContext } = eventData;
    const lineWidth = cornerstoneTools.toolStyle.getToolWidth();
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
      centerPointRadius,
    } = this.configuration;
    const newContext = Drawing.getNewContext(canvasContext.canvas);
    const { rowPixelSpacing, colPixelSpacing } = Util.getPixelSpacing(image);
    const lineDash = cornerstoneTools.getModule("globalConfiguration")
      .configuration.lineDash;

    // Meta
    const seriesModule =
      cornerstone.metaData.get("generalSeriesModule", image.imageId) || {};

    // Pixel Spacing
    const modality = seriesModule.modality;
    const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;

    Drawing.draw(newContext, (context) => {
      // If we have tool data for this element, iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }
        const angle1 = find_angle(
          data.handles.end,
          data.handles.start,
          data.handles.start2
        );

        const angle2 = find_angle2(
          data.handles.end2,
          data.handles.start2,
          data.handles.start
        );

        // Configure
        const color2 = cornerstoneTools.toolColors.getColorIfActive(data);
        let color = color2;

        if (color2 == "white") {
          color = "lime";
        }

        Drawing.setShadow(context, this.configuration);

        const startCanvas = cornerstone.pixelToCanvas(
          element,
          data.handles.start
        );

        const endCanvas = cornerstone.pixelToCanvas(element, data.handles.end);

        const startCanvas2 = cornerstone.pixelToCanvas(
          element,
          data.handles.start2
        );

        const endCanvas2 = cornerstone.pixelToCanvas(
          element,
          data.handles.end2
        );

        // Calculating the radius where startCanvas is the center of the circle to be drawn
        const radius = getDistance(startCanvas, endCanvas);
        const radius2 = getDistance(startCanvas2, endCanvas2);

        const circleOptions = { color };
        const lineOptions = { color };

        if (renderDashed) {
          circleOptions.lineDash = lineDash;
          lineOptions.lineDash = lineDash;
        }

        // Draw Circle
        Drawing.drawCircle(
          context,
          element,
          data.handles.start,
          radius,
          circleOptions,
          "pixel"
        );

        Drawing.drawCircle(
          context,
          element,
          data.handles.end,
          centerPointRadius,
          circleOptions,
          "pixel"
        );

        if (centerPointRadius && radius > 3 * centerPointRadius) {
          Drawing.drawCircle(
            context,
            element,
            data.handles.start,
            centerPointRadius,
            circleOptions,
            "pixel"
          );
        }

        if (data.complete) {
          // Draw Circle
          Drawing.drawCircle(
            context,
            element,
            data.handles.start2,
            radius2,
            circleOptions,
            "pixel"
          );

          Drawing.drawCircle(
            context,
            element,
            data.handles.end2,
            centerPointRadius,
            circleOptions,
            "pixel"
          );

          Drawing.drawLine(
            context,
            element,
            data.handles.start2,
            data.handles.end2,
            lineOptions,
            "pixel"
          );
          Drawing.drawLine(
            context,
            element,
            data.handles.start,
            data.handles.start2,
            lineOptions,
            "pixel"
          );
          Drawing.drawLine(
            context,
            element,
            data.handles.start,
            data.handles.end,
            "pixel"
          );

          if (centerPointRadius && radius2 > 3 * centerPointRadius) {
            Drawing.drawCircle(
              context,
              element,
              data.handles.start2,
              centerPointRadius,
              circleOptions,
              "pixel"
            );
          }
        }

        if (data.handles) {
          data.handles.start.drawnIndependently = true;
          data.handles.end.drawnIndependently = true;
        }

        data.handles2.start = data.handles.start2;
        data.handles2.end = data.handles.end2;

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.cachedStats) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        // Default to textbox on right side of ROI
        if (!data.handles.textBox.hasMoved) {
          const defaultCoords = Util.getROITextBoxCoords(
            eventData.viewport,
            data.handles
          );

          Object.assign(data.handles.textBox, defaultCoords);
        }

        // Default to textbox on right side of ROI
        if (!data.handles.textBox2.hasMoved) {
          const defaultCoords = Util.getROITextBoxCoords(
            eventData.viewport,
            data.handles2
          );

          Object.assign(data.handles.textBox2, defaultCoords);
        }

        const textBoxAnchorPoints = (handles) =>
          _findTextBoxAnchorPoints(handles.start, handles.end);

        data.unit = getUnit(modality, this.configuration.showHounsfieldUnits);

        const text1 = textBoxText1(
          data,
          rowPixelSpacing,
          colPixelSpacing,
          angle1
        );

        if (!data.handles.textBoxLine.hasMoved) {
          const textCoords = {
            x: (data.handles.start.x + data.handles.start2.x) / 2,
            y: (data.handles.start.y + data.handles.start2.y) / 2 - 10,
          };
          data.handles.textBoxLine.x = textCoords.x;
          data.handles.textBoxLine.y = textCoords.y;
        }
        Drawing.drawLinkedTextBox(
          context,
          element,
          data.handles.textBox,
          text1,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          10,
          true
        );
        if (data.complete) {
          const textBoxAnchorPoints2 = (handles) =>
            _findTextBoxAnchorPoints(data.handles.start2, data.handles.end2);

          const text2 = textBoxText2(
            data,
            rowPixelSpacing,
            colPixelSpacing,
            angle2
          );

          if (!data.handles.textBoxLine.hasMoved) {
            const textCoords = {
              x: (data.handles.start.x + data.handles.start2.x) / 2,
              y: (data.handles.start.y + data.handles.start2.y) / 2 - 10,
            };
            data.handles.textBoxLine.x = textCoords.x;
            data.handles.textBoxLine.y = textCoords.y;
          }

          Drawing.drawLinkedTextBox(
            context,
            element,
            data.handles.textBox2,
            text2,
            data.handles2,
            textBoxAnchorPoints2,
            color,
            lineWidth,
            10,
            true
          );
        }
      }
    });
  }
}

/**
 * Attempts to sanitize a value by casting as a number; if unable to cast,
 * we return `undefined`
 *
 * @param {*} value
 * @returns a number or undefined
 */

function _sanitizeMeasuredValue(value) {
  const parsedValue = Number(value);
  const isNumber = !isNaN(parsedValue);

  return isNumber ? parsedValue : undefined;
}
function textBoxText1(annotation, rowPixelSpacing, colPixelSpacing, angle1) {
  const measuredValue = _sanitizeMeasuredValue(annotation.length);

  // Measured value is not defined, return empty string
  if (!measuredValue) {
    return "";
  }

  // Set the length text suffix depending on whether or not pixelSpacing is available
  let suffix = "°";
  const value = angle1;

  annotation.unit = suffix;

  return `  ${value} ${suffix}   `;
}

function textBoxText2(annotation, rowPixelSpacing, colPixelSpacing, angle2) {
  const measuredValue = _sanitizeMeasuredValue(annotation.length);

  // Measured value is not defined, return empty string
  if (!measuredValue) {
    return "";
  }

  // Set the length text suffix depending on whether or not pixelSpacing is available
  let suffix = "°";
  const value = angle2;

  annotation.unit = suffix;

  return `  ${value} ${suffix}  `;
}

function find_angle(A, B, C) {
  var AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
  var BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
  var AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
  const rad = Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB));
  const angle = (rad * 180) / Math.PI;
  return angle.toFixed(1);
}

function find_angle2(A, B, C) {
  var AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
  var BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
  var AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
  const rad = Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB));
  const angle2 = (rad * 180) / Math.PI;
  return angle2.toFixed(1);
}

/**
 *
 *
 * @param {*} startHandle
 * @param {*} endHandle
 * @returns {Array.<{x: number, y: number}>}
 */
function _findTextBoxAnchorPoints(startHandle, endHandle) {
  const { left, top, width, height } = getCircleCoords(startHandle, endHandle);

  return [
    {
      // Top middle point of ellipse
      x: left + width / 2,
      y: top,
    },
    {
      // Left middle point of ellipse
      x: left,
      y: top + height / 2,
    },
    {
      // Bottom middle point of ellipse
      x: left + width / 2,
      y: top + height,
    },
    {
      // Right middle point of ellipse
      x: left + width,
      y: top + height / 2,
    },
  ];
}
