
import React, { useState, useEffect, useLayoutEffect } from 'react';
import Modal from 'react-modal';

const XCaliberPopup = ({ id, isOpen, messageLine1, messageLine2, onRequestClose, buttonId, onButtonClick, colourValue }) => {
    const [buttonPosition, setButtonPosition] = useState(null);

    useEffect(() => {
        const button = document.getElementById(buttonId);
        if (button) {
        const rect = button.getBoundingClientRect();
        setButtonPosition({
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
            width: rect.width,
            height: rect.height,
        });
        }
    }, [isOpen]);

    const calculateModalPosition = () => {
        if (buttonPosition) {
            const top = buttonPosition.top - 10; // Adjust as needed
            const left = buttonPosition.left + buttonPosition.width + 10; // Adjust as needed

            // Check if the modal will overflow the right edge of the window
            const overflowRight = left + 300 > window.innerWidth; // 300 is the approximate width of your modal

            // Adjust the position to keep the modal within the window bounds
            if (overflowRight) {
            return { top, right: 10, transform: 'none', width: 'fit-content', height: 'fit-content' }; // Adjust width as needed
            }
            return { top, left, transform: 'none', width: 'fit-content', height: 'fit-content' }; // Adjust width as needed
        }
        return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 'fit-content', height: 'fit-content' }; // Adjust width as needed
    }; 

    const modalStyle = {
        content: {
            position: 'absolute',
            padding: '20px',
            border: '1px solid #ccc',
            ...calculateModalPosition(),
            padding: '20px',
            //border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: 'white',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            color: colourValue,
        },
    };
  
    const buttonStyle = {
        marginLeft: '10px',
        marginRight: '10px',
        margintTop: '5px',
        marginBottom: '5px',
        padding: '10px',
        cursor: 'pointer',
        borderRadius: '4px',
        border: '1px solid '+ colourValue,
        //backgroundColor: {colourValue},
        color: 'white',
        textAlign: 'center',
        background: colourValue,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
    };

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            contentLabel="Warning"
            onRequestClose={onRequestClose}
            style={modalStyle}
            overlayClassName={{ backgroundColor: 'transparent' }} // Inline style for the overlay
            ariaHideApp={false} // Add this prop to prevent covering the entire screen
        >
            <div>
            <p>{messageLine1}<br/>{messageLine2}</p>
            <button style={buttonStyle} onClick={() => { onButtonClick(true) }}>OK</button>
            <button style={buttonStyle} onClick={() => { onButtonClick(false) }}>CANCEL</button>
            </div>
        </Modal>
    );
};

export default XCaliberPopup;

