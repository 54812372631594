import { useImageMetaData } from "pages/viewer/hooks/metadata/useImageMetaData";
import { useEffect, useState } from "react";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { reconstructImages } from "./reconstructImage";
import { useAxialImages } from "./useAxialImages";

export const useLoadMprData = () => {
  const {
    dispatch,
    state: { axialImages, isMPRActive, mprCoordinates },
  } = useViewerContext();
  const [mprImagesLoaded, setMprImagesLoaded] = useState(false);
  const { unprocessedMeta, imageMetaData } = useImageMetaData();
  useAxialImages();
  useEffect(() => {
    const loadAndSetUpMPRData = async () => {
      if (isMPRActive && axialImages.length && imageMetaData) {
        const { coronalImages, sagittalImages } = await reconstructImages(
          mprCoordinates,
          axialImages,
          0,
          imageMetaData
        );
        if (coronalImages && sagittalImages) {
          dispatch({
            type: "MPR_IMAGE_GENERATED",
            payload: {
              coronalImages,
              sagittalImages,
            },
          });
          setMprImagesLoaded(true);
        }
      }
    };
    loadAndSetUpMPRData();
  }, [
    ,
    unprocessedMeta,
    isMPRActive,
    axialImages,
    mprCoordinates,
    setMprImagesLoaded,
  ]);

  return { mprImagesLoaded };
};
