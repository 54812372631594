export const USER_TYPES = {
  MEDICAL: "medical",
  DENTAL: "dental",
  CHIRO: "chiro",
  CHIRO_MEDICARE: "chiroMedicare",
  VET: "vet",
  VET_XCALIBER: "vetXcaliber",
  ADMIN: "admin",
  RADIOLOGIST: "radiologist",
  MEDICARE_ADMIN: "medicareAdmin",
  DEVELOPER: "developer",
  PUBLIC: "public",
};
