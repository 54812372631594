import { useDarkModeTheme } from "hooks/useDarkmodeTheme";
import { DropdownOptionConfig } from "./dropdown.types";
import "./dropdown.css";
import { ExpandMore } from "@mui/icons-material";
interface TableDropdownProps {
  title: string;
  isDisabled?: boolean;
  value?: string;
  hasPlaceholder?: boolean;
  defaultValue?: string;
  optionsConfig: DropdownOptionConfig[];
  onChange: (selectedOptionValue: string) => void;
}
export const TableDropdown = ({
  title,
  hasPlaceholder = true,
  isDisabled = false,
  value,
  defaultValue,
  optionsConfig,
  onChange,
}: TableDropdownProps) => {
  const { isDarkModeOn, inputThemeColor } = useDarkModeTheme();
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className="dropdown-container">
      <select
        className={`table-dropdown ${isDarkModeOn ? "dropdown-darkmode" : ""}`}
        style={{
          cursor: "pointer",
          color: inputThemeColor,
        }}
        value={value}
        name={title}
        disabled={isDisabled}
        defaultValue={defaultValue}
        onChange={(e) => handleChange(e)}
      >
        {hasPlaceholder && (
          <option value="" disabled selected className="dropdown-placeholder">
            Select option
          </option>
        )}

        {optionsConfig.map(
          ({ label, value, disabled, hidden, placeholder }) => (
            <option value={value} disabled={disabled} hidden={hidden}>
              {label}
            </option>
          )
        )}
      </select>
      <ExpandMore className="expand-more-icon" />
    </div>
  );
};
