import { ExpandMore, Search } from "@mui/icons-material";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Stack,
  Typography,
  styled,
  Badge,
} from "@mui/material";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { DEFAULT_SEARCH_FIELDS } from "./studyFilter.consts";
import { Form, Formik } from "formik";
import { FormFields } from "components/form/FormField";
import { FocusedTooltip } from "components/tooltips/FocusedTooltip";
import { useDebouncedCallback } from "use-debounce";
import { useHomePageContext } from "pages/home-page/context/homepage.context";
import { GetStartedButton } from "components/buttons/Buttons";
import { useStudyFilterForm } from "./useStudyFilterForm";
import { isObjectEmpty } from "utils/form/validation";
import { CustomFab } from "components/buttons/CustomFab";
import { useStudyFilterCount } from "./useStudyFilterCount";

export const StudyFilter = () => {
  const studyFilterCount = useStudyFilterCount();
  return (
    <FocusedTooltip tooltipContent={<StudyFilterContent />}>
      <CustomFab ariaLabel="search-filter">
        <Badge
          badgeContent={studyFilterCount !== 0 ? studyFilterCount : null}
          color="info"
          sx={{ span: { top: "-5px" } }}
        >
          <Search style={{ marginRight: "5px" }} />
          <Typography>Search</Typography>
        </Badge>
      </CustomFab>
    </FocusedTooltip>
  );
};

const StudyFilterContent = () => {
  const {
    initialValues,
    formFields,
    handleValueChange,
    clearFilters,
    resetToggleKey,
  } = useStudyFilterContent();

  const onValueUpdate = useDebouncedCallback((values) => {
    handleValueChange(values);
  }, 2000);

  return (
    <Stack>
      <Formik
        key={`form-${resetToggleKey}`}
        initialValues={initialValues}
        validateOnBlur
        validate={(values) => {
          onValueUpdate(values);
        }}
        onSubmit={() => {}}
      >
        {({ errors, values, resetForm }) => (
          <Form key={`form `}>
            {Object.keys(formFields).map((value: string, index) => {
              if (DEFAULT_SEARCH_FIELDS.includes(value)) {
                const fieldItem = formFields[value];

                return (
                  <FormFields
                    value={values[value] ?? null}
                    key={`${value} ${index} ${resetToggleKey}`}
                    fieldName={value}
                    field={fieldItem}
                  />
                );
              }
              return [];
            })}
            <AdvancedFilters key={` ${resetToggleKey}`}>
              {Object.keys(formFields).flatMap((value: string, index) => {
                if (DEFAULT_SEARCH_FIELDS.includes(value)) {
                  return [];
                }
                const fieldItem = formFields[value];

                return (
                  <FormFields
                    value={values[value] ?? null}
                    key={`${value} ${index} ${resetToggleKey}`}
                    fieldName={value}
                    field={fieldItem}
                  />
                );
              })}
            </AdvancedFilters>
            <Box
              style={{
                marginTop: "1rem",
                width: "100%",
              }}
            >
              <GetStartedButton
                onClick={() => {
                  clearFilters();
                  resetForm();
                }}
              >
                Clear Filters
              </GetStartedButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

const useStudyFilterContent = () => {
  const {
    state: { filters },
  } = useHomePageContext();
  const formFields = useStudyFilterForm();
  const initialValues = useMemo(() => {
    return filters;
  }, [filters]);
  const { dispatch } = useHomePageContext();
  const [filtersToUpdate, setFiltersToUpdate] =
    useState<Record<string, any>>(initialValues);
  const [resetToggleKey, setResetToggleKey] = useState<boolean>(false);

  const clearFilters = useCallback(() => {
    setFiltersToUpdate({});
  }, [setFiltersToUpdate]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_FILTERS",
      payload: { filters: filtersToUpdate },
    });
    if (isObjectEmpty(filtersToUpdate)) {
      setTimeout(() => {
        setResetToggleKey((prev) => !prev);
      }, 500);
    }
  }, [dispatch, filtersToUpdate, filters, setResetToggleKey]);

  const handleValueChange = useCallback(
    (values) => {
      const updatedFilters = Object.entries(values).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {}
      );
      setFiltersToUpdate(updatedFilters);
    },
    [setFiltersToUpdate]
  );
  return {
    initialValues: filtersToUpdate,
    formFields,

    handleValueChange,
    clearFilters,
    resetToggleKey,
  };
};

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: "auto",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: "1rem 0",
  minHeight: 26,
  "&.Mui-expanded": {
    minHeight: 26,
  },
  "& .MuiAccordionSummary-content": {
    margin: "0.1rem 0",
    "&.Mui-expanded": {
      margin: "0.1rem 0",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface AdvancedFilterProps {
  children: ReactNode;
}

const AdvancedFilters = ({ children }: AdvancedFilterProps) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<FilterHeader />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>Advanced Filters</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const FilterHeader = () => <ExpandMore />;
