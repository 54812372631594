export const FORM_FIELDS = {
  TEXT: "TEXT",
  DROPDOWN: "DROPDOWN",
  CHECKBOX: "CHECKBOX",
  TEXT_AREA: "TEXT_AREA",
  DATE: "DATE",
};

export const REQUIRED = " *";

export const TEXT_INPUT_TYPES = {
  PASSWORD: "password",
  TEXT: "text",
  NUMERIC: "numeric",
};

export const ALLOWED_KEYS = [
  "Backspace",
  "Delete",
  "Enter",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
];
