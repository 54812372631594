import { styled } from "@mui/system";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { MPR_STATUSES } from "pages/viewer/tools/mpr-tool/mprTool.consts";
import { useMemo } from "react";
import Spinner from "react-bootstrap/Spinner";

const SpinnerContainer = styled("div")({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  fontSize: "0.8rem",
  width: "100%",
  height: "100%",
  backgroundColor: "black",
  span: {
    color: "#89cbe0",
    width: "100%",
  },
});

interface LoadingOverlayProps {
  viewportIndex: number;
  isLoadingImage: boolean;
}
export const LoadingOverlay = ({
  isLoadingImage,
  viewportIndex,
}: LoadingOverlayProps) => {
  const {
    state: { isMPRActive, mprSetupStatus },
  } = useViewerContext();
  const loadingData = useMemo(() => {
    if (
      isMPRActive &&
      viewportIndex !== 0 &&
      mprSetupStatus !== MPR_STATUSES.COMPLETED
    ) {
      return mprSetupStatus;
    } else if (isLoadingImage) {
      return "Loading Image...";
    }
    return null;
  }, [isMPRActive, mprSetupStatus, viewportIndex, isLoadingImage]);
  if (loadingData !== null) {
    return (
      <SpinnerContainer>
        <Spinner
          animation="border"
          role="status"
          style={{
            position: "relative",
            left: "50%",
            top: "40%",
            width: "5rem",
            height: "5rem",
            color: "#89cbe0",
          }}
        ></Spinner>
        <span>{loadingData}</span>
      </SpinnerContainer>
    );
  }
  return null;
};
