import { useEffect } from "react";
import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import { REQUIRES_PARSED_METADATA } from "../consts/tools.consts";
import { MIN_CLIENT_WIDTH_PX } from "pages/viewer/dicomViewer.consts";
import { ImageMetadata } from "pages/viewer/dicomViewer.types";

export const useParseImageMetadataOnLoad = (
  imageMetaData: ImageMetadata | null
) => {
  useEffect(() => {
    if (imageMetaData) {
      const enabledElementsArray = cornerstone.getEnabledElements();
      enabledElementsArray.forEach((enabledElement) => {
        if (enabledElement.element.clientWidth > MIN_CLIENT_WIDTH_PX) {
          REQUIRES_PARSED_METADATA.forEach((id) => {
            try {
              const toolInstance = cornerstoneTools.getToolForElement(
                enabledElement.element,
                id
              );
              if (toolInstance && toolInstance.setImageMetadata) {
                toolInstance.setImageMetadata(imageMetaData);
              }
            } catch (error) {
              console.error(error);
              console.warn(`error trying to parse metadata for ${id}`);
            }
          });
        }
      });
    }
  }, [imageMetaData]);
};
