import { TableButton } from "components/buttons/Buttons";
import { StudyData } from "../../studyTable.types";
import { useDarkModeTheme } from "hooks/useDarkmodeTheme";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppContext } from "context/app.context";
import { useCallback } from "react";

interface XCaliberProps {
  studyData: StudyData;
}

const SubscriptionInvalidLink = () => {
  return (
    <div>
      <p>
        Upgrade to unlock AI Assistance.
        <a href="https://atx.com.au/ai-vet-diagnosis/" target="_blank">
          Learn More
        </a>
      </p>
    </div>
  );
};
export const XCaliberLink = ({ studyData }: XCaliberProps) => {
  const { studypk, studyIuid } = studyData;
  const { isDarkModeOn } = useDarkModeTheme();
  const {
    state: { xcaliberStatus },
  } = useAppContext();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (xcaliberStatus === true) {
      navigate(formatXCaliberUrl(studypk, studyIuid));
    } else {
      toast.warn(<SubscriptionInvalidLink />);
    }
  }, [xcaliberStatus, navigate]);

  return (
    <TableButton onClick={handleClick}>
      <img
        src={`../assets/images/logos/x-caliber${
          isDarkModeOn ? "-dark" : ""
        }.png`}
        style={{ height: "1.5rem", marginRight: "5px" }}
      />
      Ask AI
    </TableButton>
  );
};

export const formatXCaliberUrl = (studypk: string, studyIuid: string) => {
  const queryParams = btoa(`pkid=${studypk}&pkuid=${studyIuid}`);
  return `/xcaliber?${queryParams}`;
};
