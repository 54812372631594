import cornerstoneTools from "cornerstone-tools";
import cornerstone, {
  CanvasCoordinate,
  PixelCoordinate,
} from "cornerstone-core";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import { TOOL_IDS } from "../consts/tools.consts";
import {
  checkEventData,
  isPointNearToolEventValid,
} from "../functions/eventHandlingValidation";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

export class MPRTool extends BaseAnnotationTool {
  hasIncomplete: boolean;

  contextFunction:
    | null
    | ((toolId: string, data: { x: number; y: number }) => void);
  imagePosition: { x: number; y: number };
  canvas: null | any;
  viewport: null | any;
  constructor() {
    super({
      name: TOOL_IDS.MPR_TOOL,
      supportedInteractionTypes: ["Mouse", "Touch"],
    });
    this.imagePosition = { x: 0, y: 0 };
    this.hasIncomplete = false;
    this.contextFunction = null;
    this.canvas = null;
    this.viewport = null;
    this.setContextFunction = this.setContextFunction.bind(this);
    this.onToolActivation = this.onToolActivation.bind(this);
    this.renderInitial = this.renderInitial.bind(this);
  }

  public setContextFunction(contextFunction) {
    this.contextFunction = contextFunction;
  }
  public onToolActivation() {
    // I couldn't get the tool interactivity to work well
    // So I Have come up with this custom implemntation - I have to use a custom canvas so I can clear and redraw the lines
    // Without loosing the image

    const viewportContainer = document.getElementById("viewport-0");
    const cornerstoneCanvas = viewportContainer.querySelector("canvas");
    if (cornerstoneCanvas) {
      this.canvas = cornerstoneCanvas;
      this.viewport = viewportContainer;
    }
  }

  public renderInitial() {
    if (this.canvas && this.viewport) {
      const cornerstoneContext = Drawing.getNewContext(this.canvas);
      const canvasWidth = cornerstoneContext.canvas.width;
      const canvasHeight = cornerstoneContext.canvas.height;
      const pixelCoords = cornerstone.canvasToPixel(this.viewport, {
        x: canvasWidth / 2,
        y: canvasHeight / 2,
      } as CanvasCoordinate);
      this.imagePosition = pixelCoords;
      /*  this.renderToolData(
        { event: { target: this.canvas }, element: this.viewport },
        true
      );
      */
    }
  }

  activeCallback(_element) {}

  preMouseDownCallback(event) {}

  createNewMeasurement(event) {
    const isEventDataValid = checkEventData(event);
    if (isEventDataValid) {
      this.imagePosition = event.currentPoints.image;
      this.renderToolData(event, true);
    }
  }

  pointNearTool(element, data, coords) {
    const validPointerEvent = isPointNearToolEventValid(data);
    if (validPointerEvent) {
      if (this.hasIncomplete) {
        return false;
      }
      const line =
        Util.lineSegDistance(
          element,
          {
            x: data.handles.start.x,
            y: Math.min(data.handles.start.y, data.handles.end.y),
          },
          {
            x: data.handles.start.x,
            y: Math.max(data.handles.start.y, data.handles.end.y),
          },
          coords
        ) < 10;

      if (line) {
        data.handles.start.movesIndependently = false;
        data.handles.end.movesIndependently = false;
      }
      return line;
    }
  }
  updateCachedStats(image, _element, data) {
    const {} = data;
  }
  distanceFromPoint(_event) {}

  renderToolData(event, isClicked?: boolean) {
    if (!isClicked) {
      return;
    }
    // console.log(event);
    const cornerstoneCanvas = event.event.target;
    if (this.contextFunction && cornerstoneCanvas) {
      // We have tool data for this element - iterate over each one and draw it
      const cornerstoneContext = Drawing.getNewContext(cornerstoneCanvas);
      const canvasWidth = cornerstoneContext.canvas.width;
      const canvasHeight = cornerstoneContext.canvas.height;
      const mprPosition = cornerstone.pixelToCanvas(
        event.element,
        this.imagePosition as PixelCoordinate
      );
      const horizontalY = mprPosition.y;
      const verticalX = mprPosition.x;

      cornerstoneContext.clearRect(0, 0, canvasWidth, canvasHeight);

      // Draw horizontal line
      cornerstoneContext.beginPath();
      cornerstoneContext.strokeStyle = "red";
      cornerstoneContext.moveTo(0, horizontalY);
      cornerstoneContext.lineTo(canvasWidth, horizontalY);
      cornerstoneContext.stroke();

      // Draw vertical line
      cornerstoneContext.beginPath();
      cornerstoneContext.moveTo(verticalX, 0);
      cornerstoneContext.lineTo(verticalX, canvasHeight);
      cornerstoneContext.stroke();

      // Draw intersection point
      cornerstoneContext.beginPath();
      cornerstoneContext.fillStyle = "red";
      cornerstoneContext.arc(verticalX, horizontalY, 3, 0, 2 * Math.PI);
      cornerstoneContext.fill();

      // Display labels with x and y coordinates
      cornerstoneContext.fillStyle = "greenyellow";
      cornerstoneContext.font = "15px Arial";
      const text = `X: ${this.imagePosition.x.toFixed(
        2
      )}, Y : ${this.imagePosition.y.toFixed(2)}`;
      cornerstoneContext.fillText(text, verticalX + 5, horizontalY - 5);
      this.contextFunction(TOOL_IDS.MPR_TOOL, this.imagePosition);
    }
  }
}
