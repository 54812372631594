import { ALL_TOOLS } from "../../consts/tools.consts";
import cornerstoneTools from "cornerstone-tools";
import { useCallback } from "react";
import { saveDicomImageAnnotations } from "services/studies/studies";

export const useSaveAnnotation = () =>
  useCallback(async (enabledElement) => {
    const allTools = ALL_TOOLS;
    //Iterate over each tool and add to list to save if the tool meets the conditions
    const elementToolState = allTools.flatMap((tool) => {
      const toolState = cornerstoneTools.getToolState(
        enabledElement.element,
        tool.name
      );
      if (toolState) {
        return { toolName: tool.name, toolState };
      }
      return [];
    });
    if (elementToolState.length) {
      const image = enabledElement.image.imageId;
      const imageURL = new URL(image);
      const searchParams = new URLSearchParams(imageURL.search);
      const studyIUID = searchParams.get("studyUID");
      const seriesIUID = searchParams.get("seriesUID");
      const instanceIUID = searchParams.get("objectUID");

      const stringifiedToolState = JSON.stringify(elementToolState);
      const result = await saveDicomImageAnnotations(
        stringifiedToolState,
        studyIUID,
        seriesIUID,
        instanceIUID
      );
    }
  }, []);
