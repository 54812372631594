import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "context/app.context";
import { TableHeader } from "components/table/TableHeader";
import { TableRows } from "components/table/TableRows";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { Paper } from "@mui/material";
import { TablePagination } from "components/table/TablePagination";
import { TableLoader } from "components/table/TableLoader";
import { useRowData } from "./study-table-rows/useRowData";
import { useColumnConfigs } from "./study-table-columns/useColumnConfigs";
import { useHomePageContext } from "pages/home-page/context/homepage.context";
import {
  FIELD_KEYS,
  SORT_DIRECTIONS,
} from "./study-table-columns/columns.consts";
import { fetchStudyTableRowData } from "services/studies/studies";

export const StudyTable = () => {
  const {
    columns,
    editable,
    sortingColumn,
    sortingDirection,
    onColumnClick,
    sortableColumns,
  } = useColumnConfigs();

  const { count, page, rowsPerPage, handlePageChange } = useTablePages();
  const { rowData, isLoadingData } = useRowData(
    columns,
    editable,
    sortingColumn,
    sortingDirection,
    page
  );
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHeader
          columns={columns}
          onColumnClick={onColumnClick}
          sortedColumnId={sortingColumn}
          sortDirection={sortingDirection}
          sortableColumnIds={sortableColumns}
        />
        {isLoadingData ? (
          <TableLoader columns={columns} />
        ) : (
          <TableRows rows={rowData} />
        )}
        {count && !isLoadingData ? (
          <TablePagination
            columns={columns}
            currentPage={page}
            rowsPerPage={rowsPerPage}
            onHandlePageChange={handlePageChange}
            totalRows={count}
          />
        ) : null}
      </Table>
    </TableContainer>
  );
};

const useTablePages = () => {
  const {
    state: { userId },
  } = useAppContext();
  const {
    dispatch,
    state: { hasLoadedFirstPage, page, filters, requiresReload },
  } = useHomePageContext();

  const [count, setCount] = useState<number | null>(null);
  const [rowsPerPage] = useState<number>(10);

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch({ type: "CHANGE_PAGE", payload: { page: newPage } });
    },
    [dispatch]
  );

  const fetchStudiesCount = useCallback(async () => {
    // Fetch the count after loading the first page - to optimize performance
    if (userId && hasLoadedFirstPage && requiresReload) {
      const result = await fetchStudyTableRowData(
        userId,
        page - 1,
        // Because we are only returning the count - it doesn't matter what sorting data we parse
        FIELD_KEYS.STUDY_DATETIME,
        SORT_DIRECTIONS.DESC,
        filters,
        true
      );
      if (result && result[0]) {
        const { count } = result[0];
        if (count) {
          setCount(Number(count));
          dispatch({ type: "ON_COUNT_LOAD" });
        }
      }
    }
  }, [userId, setCount, hasLoadedFirstPage, page, filters, dispatch]);

  useEffect(() => {
    fetchStudiesCount();
  }, [fetchStudiesCount]);

  return { count, page, rowsPerPage, handlePageChange };
};
