import { FormField } from "components/form/Form.types";

export const validateAllRequired = (
  formFields: Record<string, FormField>,
  formValues: Record<string, string>
) => {
  const errors: Record<string, string> = {};
  Object.keys(formFields).forEach((key) => {
    const fieldItem = formFields[key];
    if (fieldItem.required && !formValues[key]) {
      errors[key] = `${fieldItem.label} Required`;
    }
  });
  return errors;
};

export const isObjectEmpty = (obj: object): boolean =>
  Object.keys(obj).length === 0;

export const isValidString = (stringValue: string) => stringValue.length > 0;
