// @ts-nocheck
import { useState } from "react";
import { Button, Grid, Paper } from "@mui/material";
import { useUserType } from "../../../../hooks/useUserType";

import { ReactComponent as LayoutIcon } from "../../../../img/icon/Layout.svg";
import { useGridControllerActions } from "./useGridControllerActions";

const DEFAULT_SELECTED_INDEX = -1;
const DIMENSIONS = [3, 3];

export const DicomGridController = () => {
  const { updateGridAndViewport } = useGridControllerActions();
  const [isGridControllerOpen, setGridControllerOpen] =
    useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(DEFAULT_SELECTED_INDEX);

  const handleGridHover = (event) => {
    setSelectedIndex(event.target.getAttribute("index"));
  };

  const setGridDimensions = () => {
    const selectedDimensions = [
      Math.floor(selectedIndex % DIMENSIONS[0]) + 1,
      Math.floor(selectedIndex / DIMENSIONS[0]) + 1,
    ];
    updateGridAndViewport(selectedDimensions);
    closeGridController();
  };

  const closeGridController = () => {
    setGridControllerOpen(false);
    setSelectedIndex(DEFAULT_SELECTED_INDEX);
  };

  const { userTypeColour } = useUserType();

  return (
    <>
      <Button
        variant="contained"
        style={{
          margin: "5px",
          backgroundColor: userTypeColour,
          color: "white",
        }}
        onClick={() => setGridControllerOpen(!isGridControllerOpen)}
      >
        <LayoutIcon style={{ height: "24px", width: "24px" }} />
      </Button>

      {isGridControllerOpen && (
        <Paper
          variant="outlined"
          onMouseLeave={closeGridController}
          sx={{
            position: "absolute",
            top: 45,
            p: 0.5,
            border: 1,
            zIndex: 1,
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Grid container spacing={0.5}>
            {Array.from(Array(DIMENSIONS[0] * DIMENSIONS[1]).keys()).map(
              (index) => (
                <Grid item key={index} xs={12 / DIMENSIONS[0]}>
                  <Paper
                    index={index}
                    variant="outlined"
                    onMouseEnter={handleGridHover}
                    onClick={setGridDimensions}
                    sx={
                      index / DIMENSIONS[0] <= selectedIndex / DIMENSIONS[0] &&
                      index % DIMENSIONS[0] <= selectedIndex % DIMENSIONS[0]
                        ? { px: 0.5, py: 2, bgcolor: userTypeColour }
                        : { px: 0.5, py: 2 }
                    }
                  />
                </Grid>
              )
            )}
          </Grid>
        </Paper>
      )}
    </>
  );
};
