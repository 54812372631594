import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useCallback } from "react";

export const useUpdateFrameCount = () => {
  const { dispatch } = useViewerContext();
  return useCallback(
    (viewportIndex: number, frameIndex: number) => {
      dispatch({
        type: "SET_IMAGE_FRAME_INDEX",
        payload: { viewportIndex, frameIndex },
      });
    },
    [dispatch]
  );
};
