// @ts-nocheck
import "./darkmodeSwitch.css";
import { useAppContext } from "context/app.context";
import { useUserType } from "hooks/useUserType";
import { useCallback, useEffect, useMemo } from "react";
import { useAppTheme } from "theme/useAppTheme";
import { getUserTypeColorName } from "utils/colors/userColors";
const SUN_ICON_DARKMODE =
  "../assets/images/icons/dark-mode-toggle/darkmode/sun-icon.png";
const MOON_ICON_DARKMODE =
  "../assets/images/icons/dark-mode-toggle/darkmode/moon-icon.png";

export const DarkModeSwitch = () => {
  const {
    dispatch,
    state: { isDarkModeOn },
  } = useAppContext();

  const toggleDarkmode = useCallback(() => {
    dispatch({
      type: "TOGGLE_DARK_MODE",
      payload: { isDarkModeOn: !isDarkModeOn },
    });
  }, [isDarkModeOn, dispatch]);

  useEffect(() => {
    try {
      if (isDarkModeOn === null) {
        const isInitialDarkMode = JSON.parse(
          localStorage.getItem("isDarkModeOn")
        );

        if (isInitialDarkMode !== undefined) {
          dispatch({
            type: "TOGGLE_DARK_MODE",
            payload: { isDarkModeOn: isInitialDarkMode },
          });
        }
      }
    } catch {
      console.warn("no darkmode data");
    }
  }, [dispatch, isDarkModeOn]);

  useEffect(() => {
    if (isDarkModeOn) {
      document.body.style.setProperty(
        "background-color",
        "#181818",
        "important"
      );
    } else {
      document.body.style.setProperty(
        "background-color",
        "#f5f5f5",
        "important"
      );
    }
    localStorage.setItem("isDarkModeOn", String(isDarkModeOn));
  }, [isDarkModeOn]);

  const theme = useAppTheme();
  const { sun, moon } = useIcons();
  return (
    <div className="toggle-theme-wrapper">
      <img src={isDarkModeOn ? SUN_ICON_DARKMODE : sun} height="44px" />
      <label className="toggle-theme" htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          onChange={toggleDarkmode}
          checked={isDarkModeOn ?? false}
        />
        <div
          className="slider round"
          style={{
            backgroundColor: theme.palette.primary.main,
            opacity: "70%",
          }}
        ></div>
      </label>
      <img src={isDarkModeOn ? MOON_ICON_DARKMODE : moon} height="32px" />
    </div>
  );
};

const useIcons = () => {
  const { userTypeColour } = useUserType();

  return useMemo(() => {
    const folder = getUserTypeColorName(userTypeColour);
    const baseIconUrl = "../assets/images/icons/dark-mode-toggle/";
    const SUN_ICON = "/sun-icon.png";
    const MOON_ICON = "/moon-icon.png";

    return {
      sun: `${baseIconUrl}${folder}${SUN_ICON}`,
      moon: `${baseIconUrl}${folder}${MOON_ICON}`,
    };
  }, [userTypeColour]);
};
