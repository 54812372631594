//@ts-nocheck
import cornerstoneTools from "cornerstone-tools";
import cornerstoneMath from "cornerstone-math";
import cornerstone from "cornerstone-core";
import Drawing from "../../api/Drawing";
import Util from "../../api/Util";
import Manipulators from "../../api/Manipulators";
import Tools from "../../api/Tools";
import Base from "../../api/Base";
import { MEASUREMENT_SUFFIXES, TOOL_IDS } from "../../consts/tools.consts";

import { createTextBoxContent } from "./formatTextBoxContent";
import { getDimensionData } from "../../measurement-tools/measurementToolUtils";
import { calculateEllipseStats, getUnit } from "../miscellaneousToolUtils";
const circleRoiCursor = Tools.cursors;

export class HipDistractionIndex extends Base.BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: TOOL_IDS.HIP_DISTRACTION_INDEX,
      supportedInteractionTypes: ["Mouse", "Touch"],
      svgCursor: circleRoiCursor,
      configuration: {
        centerPointRadius: 6,
        renderDashed: false,
        hideHandlesIfMoving: true,
        handleRadius: 6,
        drawHandlesOnHover: true,
      },
    };

    super(props, defaultProps);

    this.hasIncomplete = false;

    this.throttledUpdateCachedStats = Util.throttle(
      this.updateCachedStats,
      110
    );
    this.imageMetaData = {};
    this.setImageMetadata = this.setImageMetadata.bind(this);
  }
  public setImageMetadata(imageMetaData: ImageMetadata) {
    if (imageMetaData) {
      this.imageMetaData = imageMetaData;
    }
  }

  //Updates cached statistics for the tool's annotation data on the element
  updateCachedStats(image, element, data) {
    const seriesModule =
      cornerstone.metaData.get("generalSeriesModule", image.imageId) || {};
    const modality = seriesModule.modality;
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );

    const stats = calculateEllipseStats(
      image,
      element,
      data.handles.start,
      data.handles.end,
      modality,
      rowPixelSpacing,
      colPixelSpacing
    );

    if (data.complete) {
      const stats2 = calculateEllipseStats(
        image,
        element,
        data.handles.start2,
        data.handles.end2,
        modality,
        rowPixelSpacing,
        colPixelSpacing
      );
      data.cachedStats2 = stats2;

      // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
      const dx =
        (data.handles.start2.x - data.handles.start.x) * (colPixelSpacing || 1);
      const dy =
        (data.handles.start2.y - data.handles.start.y) * (rowPixelSpacing || 1);

      // Calculate the length, and create the text variable with the millimeters or pixels suffix
      const length = Math.sqrt(dx * dx + dy * dy);

      // Store the length inside the tool for outside access
      data.length = length;
    }

    data.cachedStats = stats;
    data.invalidated = false;
  }

  addNewMeasurement(evt, interactionType) {
    evt.preventDefault();
    evt.stopPropagation();

    const eventData = evt.detail;

    let measurementData;
    let toMoveHandle;

    //DoneMovingCallback function for first measurement. (Is called at the end of the first circle - this function is redefined later for the second circle)
    let doneMovingCallback = (success) => {
      if (!success) {
        cornerstoneTools.removeToolState(element, this.name, measurementData);
        return;
      }
      const eventType = cornerstone.EVENTS.MEASUREMENT_COMPLETED;
      const eventData = {
        toolName: this.name,
        toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
        element,
        measurementData,
      };

      //Triggers a CustomEvent, with parameters (elemente to trigger event upon, event type name, event data to be sent)
      Util.triggerEvent(element, eventType, eventData);
    };

    // Search for incomplete measurements
    const element = evt.detail.element;

    //Returns toolstate.data for the element if the data.complete value is false
    const pendingMeasurement = this.getIncomplete(element);

    if (pendingMeasurement) {
      measurementData = pendingMeasurement;
      measurementData.complete = true;
      measurementData.handles.start2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: false,
      };
      measurementData.handles.end2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      toMoveHandle = measurementData.handles.end2;
      this.hasIncomplete = false;
      doneMovingCallback = (success) => {
        // DoneMovingCallback for second measurement
        if (!success) {
          cornerstoneTools.removeToolState(element, this.name, measurementData);
          return;
        }

        const eventType = cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED;
        const eventData = {
          toolName: this.name,
          toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
          element,
          measurementData,
        };

        Util.triggerEvent(element, eventType, eventData);
      };
    } else {
      measurementData = this.createNewMeasurement(eventData);
      cornerstoneTools.addToolState(element, this.name, measurementData);
      toMoveHandle = measurementData.handles.end;
    }

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);

    //"Manipulators describe a tool's handle behavior. Leveraging a small set of manipulators allows us to create a consistent experience when interacting with tools via their handles.""
    Manipulators.moveNewHandle(
      eventData, //eventData
      this.name, //toolName
      measurementData, //annotation
      toMoveHandle, //handle
      this.options, //options
      interactionType, //interaction type
      doneMovingCallback //doneMovingCallback
    );
  }

  getIncomplete(element) {
    const toolState = cornerstoneTools.getToolState(element, this.name); //Returns the element's state object for the given tool name

    if (toolState && Array.isArray(toolState.data)) {
      return toolState.data.find(({ complete }) => complete === false);
    }
  }

  createNewMeasurement(eventData) {
    this.hasIncomplete = true;
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      console.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );

      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: "",
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        start2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        initialRotation: eventData.viewport.rotation,
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: true,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
        textBox2: {
          active: false,
          hasMoved: false,
          movesIndependently: true,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
        textBoxLine: {
          active: false,
          hasMoved: false,
          movesIndependently: true,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
      handles2: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
      },
    };
  }

  //Called by Cornerstone on each mouse movement (except before measurement initiationi and while creating circles etc). Returns true if the given coords are need the tool.
  pointNearTool(element, data, coords, interactionType) {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;

    const getDistance = cornerstoneMath.point.distance;

    if (!hasStartAndEndHandles) {
      console.warn(
        `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      );
    }

    if (!hasStartAndEndHandles || data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const distance = interactionType === "mouse" ? 15 : 25; //adjust hover zone

    const startCanvas = cornerstone.pixelToCanvas(element, data.handles.start);

    const endCanvas = cornerstone.pixelToCanvas(element, data.handles.end);

    const startCanvas2 = cornerstone.pixelToCanvas(
      element,
      data.handles.start2
    );

    const endCanvas2 = cornerstone.pixelToCanvas(element, data.handles.end2);

    // StartCanvas is the center of the circle
    const distanceFromCenter = getDistance(startCanvas, coords);
    const distanceFromCenter2 = getDistance(startCanvas2, coords);

    // Getting radius of circle annotation in canvas
    const radius = getDistance(startCanvas, endCanvas);
    const radius2 = getDistance(startCanvas2, endCanvas2);

    //Get Tool highlighted
    const circle1 =
      distanceFromCenter > radius - distance / 2 &&
      distanceFromCenter < radius + distance / 2;
    const circle2 =
      distanceFromCenter2 > radius2 - distance / 2 &&
      distanceFromCenter2 < radius2 + distance / 2;
    const line =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.start2,
        coords
      ) < 25;

    if (circle1) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
      data.handles.start2.movesIndependently = true;
      data.handles.end2.movesIndependently = true;
    } else if (circle2) {
      data.handles.start.movesIndependently = true;
      data.handles.end.movesIndependently = true;
      data.handles.start2.movesIndependently = false;
      data.handles.end2.movesIndependently = false;
    } else if (line) {
      data.handles.start.movesIndependently = false;
      data.handles.start2.movesIndependently = false;
      data.handles.end.movesIndependently = false;
      data.handles.end2.movesIndependently = false;
    }

    // Checking if point is near the tool by comparing its distance from the center of the circle
    return circle1 || circle2 || line;
  }

  renderToolData(evt) {
    const toolData = cornerstoneTools.getToolState(
      evt.currentTarget,
      this.name
    );

    if (!toolData) {
      return;
    }

    const getDistance = cornerstoneMath.point.distance;

    const eventData = evt.detail;

    const { image, element, canvasContext } = eventData;
    const lineWidth = cornerstoneTools.toolStyle.getToolWidth();

    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
      centerPointRadius,
    } = this.configuration;

    const newContext = Drawing.getNewContext(canvasContext.canvas);

    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );
    const lineDash = cornerstoneTools.getModule("globalConfiguration")
      .configuration.lineDash;

    // Meta
    const seriesModule =
      cornerstone.metaData.get("generalSeriesModule", image.imageId) || {};

    // Pixel Spacing
    const modality = seriesModule.modality;

    const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;

    //"This function manages the save/restore pattern for working in a new context state stack. The parameter fn is passed the context and can execute any API calls in a clean stack.""
    Drawing.draw(newContext, (context) => {
      // If we have tool data for this element, iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }

        // Configure
        const color = cornerstoneTools.toolColors.getColorIfActive(data);

        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };
        Drawing.setShadow(context, this.configuration);

        const startCanvas = cornerstone.pixelToCanvas(
          element,
          data.handles.start
        );

        const endCanvas = cornerstone.pixelToCanvas(element, data.handles.end);

        const startCanvas2 = cornerstone.pixelToCanvas(
          element,
          data.handles.start2
        );

        const endCanvas2 = cornerstone.pixelToCanvas(
          element,
          data.handles.end2
        );

        // Calculating the radius where startCanvas is the center of the circle to be drawn
        const radius = getDistance(startCanvas, endCanvas);
        const radius2 = getDistance(startCanvas2, endCanvas2);

        const circleOptions = { color };
        const lineOptions = { color };

        if (renderDashed) {
          circleOptions.lineDash = lineDash;
          lineOptions.lineDash = lineDash;
        }

        // Draw Circle
        Drawing.drawCircle(
          context,
          element,
          data.handles.start,
          radius,
          circleOptions,
          "pixel"
        );

        Drawing.drawCircle(
          context,
          element,
          data.handles.end,
          centerPointRadius,
          circleOptions,
          "pixel"
        );

        if (centerPointRadius && radius > 3 * centerPointRadius) {
          Drawing.drawCircle(
            context,
            element,
            data.handles.start,
            centerPointRadius,
            circleOptions,
            "pixel"
          );
        }

        if (data.complete) {
          // Draw Circle
          Drawing.drawCircle(
            context,
            element,
            data.handles.start2,
            radius2,
            circleOptions,
            "pixel"
          );

          Drawing.drawCircle(
            context,
            element,
            data.handles.end2,
            centerPointRadius,
            circleOptions,
            "pixel"
          );

          Drawing.drawLine(
            context,
            element,
            data.handles.start,
            data.handles.start2,
            lineOptions,
            "pixel"
          );

          if (centerPointRadius && radius2 > 3 * centerPointRadius) {
            Drawing.drawCircle(
              context,
              element,
              data.handles.start2,
              centerPointRadius,
              circleOptions,
              "pixel"
            );
          }
        }

        if (data.handles) {
          data.handles.start.drawnIndependently = true;
          data.handles.end.drawnIndependently = true;
        }

        // Drawing.drawHandles(context, eventData, data.handles, handleOptions);
        data.handles2.start = data.handles.start2;
        data.handles2.end = data.handles.end2;

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.cachedStats) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        // Default to textbox on right side of ROI
        if (!data.handles.textBox.hasMoved) {
          const defaultCoords = Util.getROITextBoxCoords(
            eventData.viewport,
            data.handles
          );

          Object.assign(data.handles.textBox, defaultCoords);
        }

        // Default to textbox on right side of ROI
        if (!data.handles.textBox2.hasMoved) {
          const defaultCoords = Util.getROITextBoxCoords(
            eventData.viewport,
            data.handles2
          );

          Object.assign(data.handles.textBox2, defaultCoords);
        }

        const textBoxAnchorPoints = (handles) =>
          _findTextBoxAnchorPoints(handles.start, handles.end);

        const textBoxContent = createTextBoxContent(
          context,
          image.color,
          data.cachedStats,
          modality,
          hasPixelSpacing,
          this.configuration,
          "First Circle"
        );

        data.unit = getUnit(modality, this.configuration.showHounsfieldUnits);

        Drawing.drawLinkedTextBox(
          context,
          element,
          data.handles.textBox,
          textBoxContent,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          10,
          true
        );
        if (data.complete) {
          const textBoxAnchorPoints2 = (handles) =>
            _findTextBoxAnchorPoints(data.handles.start2, data.handles.end2);

          const textBoxAnchorPointsLine2 = (handles) =>
            textBoxAnchorPointsLine(data.handles);

          const secondCircleTextboxContent = createTextBoxContent(
            context,
            image.color,
            data.cachedStats2,
            modality,
            hasPixelSpacing,
            this.configuration,
            "Second Circle"
          );

          Drawing.drawLinkedTextBox(
            context,
            element,
            data.handles.textBox2,
            secondCircleTextboxContent,
            data.handles2,
            textBoxAnchorPoints2,
            color,
            lineWidth,
            10,
            true
          );

          const text = textBoxText(
            data,
            rowPixelSpacing,
            colPixelSpacing,
            radius2
          );

          if (!data.handles.textBoxLine.hasMoved) {
            const textCoords = {
              x: (data.handles.start.x + data.handles.start2.x) / 2,
              y: (data.handles.start.y + data.handles.start2.y) / 2 - 10,
            };
            data.handles.textBoxLine.x = textCoords.x;
            data.handles.textBoxLine.y = textCoords.y;
          }

          Drawing.drawLinkedTextBox(
            context,
            element,
            data.handles.textBoxLine,
            text,
            data.handles,
            textBoxAnchorPointsLine2,
            color,
            lineWidth,
            10,
            true
          );
        }
      }
    });
  }
}

/**
 * Attempts to sanitize a value by casting as a number; if unable to cast,
 * we return `undefined`
 *
 * @param {*} value
 * @returns a number or undefined
 */
function _sanitizeMeasuredValue(value) {
  const parsedValue = Number(value);
  const isNumber = !isNaN(parsedValue);

  return isNumber ? parsedValue : undefined;
}

function textBoxText(annotation, rowPixelSpacing, colPixelSpacing, radius) {
  const measuredValue = _sanitizeMeasuredValue(annotation.length);

  // Measured value is not defined, return empty string
  if (!measuredValue) {
    return "";
  }

  let textLines = [];

  // Set the length text suffix depending on whether or not pixelSpacing is available
  const suffix =
    rowPixelSpacing && colPixelSpacing
      ? MEASUREMENT_SUFFIXES.MM
      : MEASUREMENT_SUFFIXES.PIXELS;
  const value = measuredValue / radius;

  annotation.unit = suffix;
  textLines.push("Distraction Index: " + value.toFixed(2));
  textLines.push("Distance: " + measuredValue.toFixed(2) + suffix);

  return textLines;
}

function textBoxAnchorPointsLine(handles) {
  const midpoint = {
    x: (handles.start.x + handles.start2.x) / 2,
    y: (handles.start.y + handles.start2.y) / 2,
  };

  return [handles.start, midpoint, handles.start2];
}

/**
 *
 *
 * @param {*} startHandle
 * @param {*} endHandle
 * @returns {Array.<{x: number, y: number}>}
 */
function _findTextBoxAnchorPoints(startHandle, endHandle) {
  const { left, top, width, height } = getCircleCoords(startHandle, endHandle);

  return [
    {
      // Top middle point of ellipse
      x: left + width / 2,
      y: top,
    },
    {
      // Left middle point of ellipse
      x: left,
      y: top + height / 2,
    },
    {
      // Bottom middle point of ellipse
      x: left + width / 2,
      y: top + height,
    },
    {
      // Right middle point of ellipse
      x: left + width,
      y: top + height / 2,
    },
  ];
}
