import cornerstoneTools from "cornerstone-tools";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import {
  ANNOTATION_TEXT_X_OFFSET_PX,
  MEASUREMENT_SUFFIXES,
  TOOL_IDS,
} from "../consts/tools.consts";
import {
  getAxisLengths,
  getDimensionData,
  getDisplayedMeasurement,
} from "./measurementToolUtils";
import { ImageMetadata } from "pages/viewer/dicomViewer.types";
import { DEFAULT_HANDLE } from "../consts/tools.defaults";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);
const LENGTH_TOOL_CONFIGURATION = {
  drawHandles: true,
  drawHandlesOnHover: false,
  hideHandlesIfMoving: false,
  renderDashed: true,
  digits: 2,
};
export default class LengthTool extends BaseAnnotationTool {
  throttledUpdateCachedStats: any;
  imageMetadata: ImageMetadata;
  toolId: string;
  constructor(name = TOOL_IDS.LENGTH_TOOL) {
    const props = {
      name,
      supportedInteractionTypes: ["Mouse", "Touch"],
    };

    super(props);
    this.toolId = TOOL_IDS.LENGTH_TOOL;

    this.throttledUpdateCachedStats = Util.throttle(
      this.updateCachedStats,
      110
    );
    this.imageMetadata = {};
    this.setImageMetadata = this.setImageMetadata.bind(this);
  }

  public setImageMetadata(imageMetadata: ImageMetadata) {
    if (imageMetadata) {
      this.imageMetadata = imageMetadata;
    }
  }

  createNewMeasurement = (eventData) => {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      return;
    }

    const { x, y } = eventData.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  };

  pointNearTool = (element, data, coords) => {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    return (
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 25
    );
  };

  updateCachedStats(image, _element, data) {
    const {
      handles: { end, start },
    } = data;
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetadata
    );
    const { dx, dy } = getAxisLengths(
      start,
      end,
      colPixelSpacing,
      rowPixelSpacing
    );
    const length = Math.sqrt(dx * dx + dy * dy);
    data.suffix = MEASUREMENT_SUFFIXES.MM;
    data.length = length;
    data.invalidated = false;
  }

  renderToolData = (event: { currentTarget?: any; detail?: any }) => {
    const { drawHandlesOnHover, hideHandlesIfMoving, drawHandles } =
      LENGTH_TOOL_CONFIGURATION;
    const { detail } = event;
    // We have tool data for this element - iterate over each one and draw it
    const context = Drawing.getNewContext(detail.canvasContext.canvas);
    const toolData = cornerstoneTools.getToolState(
      event.currentTarget,
      this.toolId
    );

    if (!toolData) {
      return;
    }

    const { image, element } = detail;

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      const color = cornerstoneTools.toolColors.getColorIfActive(data);
      const {
        length,
        suffix,
        visible,
        handles: { start, end },
      } = data;
      if (visible === false) {
        continue;
      }

      const lineOptions = { color };
      Drawing.setShadow(context, LENGTH_TOOL_CONFIGURATION);
      Drawing.drawLine(context, detail.element, start, end, lineOptions);
      const handleOptions = {
        ...DEFAULT_HANDLE,
        color,
        drawHandlesIfActive: drawHandlesOnHover,
        hideHandlesIfMoving,
      };

      if (drawHandles) {
        Drawing.drawHandles(context, detail, { start, end }, handleOptions);
      }

      if (!data.handles.textBox.hasMoved) {
        const coords = {
          x: Math.max(start.x, end.x),
          y: end.y,
        };
        // Depending on which handle has the largest x-value,
        // Set the y-value for the text box
        if (coords.x === data.handles.start.x) {
          coords.y = data.handles.start.y;
        }

        data.handles.textBox.x = coords.x;
        data.handles.textBox.y = coords.y;
      }

      // Update textbox stats
      if (data.invalidated === true) {
        if (data.length) {
          this.throttledUpdateCachedStats(image, element, data);
        } else {
          this.updateCachedStats(image, element, data);
        }
      }

      const text = getDisplayedMeasurement(length, suffix);
      const lineWidth = 3;
      Drawing.drawLinkedTextBox(
        context,
        element,
        data.handles.textBox,
        text,
        data.handles,
        textBoxAnchorPoints,
        color,
        lineWidth,
        ANNOTATION_TEXT_X_OFFSET_PX,
        true
      );
    }

    function textBoxAnchorPoints(handles) {
      const midpoint = {
        x: (handles.start.x + handles.end.x) / 2,
        y: (handles.start.y + handles.end.y) / 2,
      };

      return [handles.start, midpoint, handles.end];
    }
  };
}
