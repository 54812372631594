import { useDarkModeTheme } from "hooks/useDarkmodeTheme";
import { StudyData } from "../../studyTable.types";
import { FIELD_KEYS } from "../../study-table-columns/columns.consts";
import { useCallback, useMemo, useState } from "react";
import { useUpdatePatientData } from "../../hooks/useUpdatePatientData";
import { useUpdateStudyData } from "../../hooks/useUpdateStudyData";
import { PATIENT_FIELDS } from "../../studyTable.consts";
import { useIsLocked } from "../../hooks/useIsLocked";
import { useHomePageContext } from "pages/home-page/context/homepage.context";

interface ValueCheckboxProps {
  studyData: StudyData;
  fieldName: string;
  isEditable: boolean;
}
export const ValueCheckbox = ({ studyData, fieldName }: ValueCheckboxProps) => {
  const { isChecked, onCheckChange } = useCheckboxProps(studyData, fieldName);
  const { textThemeColor, backgroundThemeColor } = useDarkModeTheme();

  const isLocked = useIsLocked(studyData);
  return (
    <input
      type="checkbox"
      value={fieldName}
      checked={isChecked}
      style={{
        cursor: "pointer",
        color: textThemeColor,
        backgroundColor: backgroundThemeColor,
        transform: "scale(1.5)",
      }}
      disabled={isLocked && fieldName !== FIELD_KEYS.SELECT}
      onChange={(e) => onCheckChange(e)}
    />
  );
};

const useCheckboxProps = (studyData: StudyData, fieldName: string) => {
  const isInitialChecked = useMemo(() => {
    switch (fieldName) {
      case FIELD_KEYS.CLAIM_VERIFICATION:
      case FIELD_KEYS.STUDY_PROCESS:
        return Boolean(studyData[fieldName]);
    }
  }, [fieldName, studyData]);

  const [isChecked, setIsChecked] = useState<boolean>(isInitialChecked);

  const updatePatientValues = useUpdatePatientData();
  const updateStudy = useUpdateStudyData();

  const { toggleSelected } = useSelectCheck(studyData);
  const onCheckChange = useCallback(
    async (event) => {
      const {
        target: { checked },
      } = event;
      setIsChecked(checked);
      if (fieldName === FIELD_KEYS.SELECT) {
        toggleSelected(checked);
      } else {
        const { studypk, patientpk } = studyData;
        const shouldUpdatePatient = PATIENT_FIELDS.includes(fieldName);
        const submissionData = { [fieldName]: checked };
        shouldUpdatePatient
          ? await updatePatientValues(studypk, patientpk, submissionData)
          : await updateStudy(studypk, submissionData);
      }
    },
    [studyData, setIsChecked, updatePatientValues, updateStudy, toggleSelected]
  );

  return { onCheckChange, isChecked };
};

const useSelectCheck = (studyData: StudyData) => {
  const { dispatch } = useHomePageContext();
  const toggleSelected = useCallback(
    (isChecked: boolean) => {
      dispatch({
        type: "TOGGLE_ROW_SELECT",
        payload: { isChecked, selectedStudy: studyData },
      });
    },
    [dispatch, studyData]
  );

  return { toggleSelected };
};
