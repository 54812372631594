import { createContext, useReducer, useContext, ReactNode } from "react";
import { StudyData } from "../components/study-table/studyTable.types";

export type HomePageState = {
  page: number;
  hasLoadedFirstPage: boolean;
  cachedStudyData: Record<number, StudyData[]>;
  lockedRows: string[];
  unlockedRows: string[];
  requiresReload: boolean;
  filters: Record<string, string>;
  selectedRows: StudyData[];
};
const INITIAL_STATE: HomePageState = {
  page: 0,
  hasLoadedFirstPage: false,
  cachedStudyData: {},
  lockedRows: [],
  unlockedRows: [],
  requiresReload: true,
  filters: {},
  selectedRows: [],
};

export type HomePageActions =
  | {
      type: "LOAD_PAGE";
      payload: { page: number; studyData: StudyData[] };
    }
  | { type: "CHANGE_PAGE"; payload: { page: number } }
  | {
      type: "TOGGLE_ROW_LOCK";
      payload: { unlockedRows: string[]; lockedRows: string[] };
    }
  | { type: "CHANGE_QUERY_ARGS" }
  | { type: "UPDATE_FILTERS"; payload: { filters: Record<string, string> } }
  | {
      type: "UPDATE_ROW_ITEM";
      payload: {
        page: number;
        studypk: string;
        updatedStudyData: Partial<StudyData>;
      };
    }
  | { type: "ON_COUNT_LOAD" }
  | {
      type: "TOGGLE_ROW_SELECT";
      payload: { selectedStudy: StudyData; isChecked: boolean };
    };

type HomePageContextType = {
  state: HomePageState;
  dispatch: React.Dispatch<HomePageActions>;
};
const HomePageContext = createContext<HomePageContextType | undefined>(
  undefined
);

const reducer = (state: HomePageState, action: HomePageActions) => {
  switch (action.type) {
    case "CHANGE_PAGE": {
      return { ...state, page: action.payload.page };
    }
    case "LOAD_PAGE": {
      return {
        ...state,
        hasLoadedFirstPage: true,
        page: action.payload.page,
        cachedStudyData: {
          ...state.cachedStudyData,
          [action.payload.page]: action.payload.studyData,
        },
      };
    }
    case "ON_COUNT_LOAD": {
      return {
        ...state,
        requiresReload: false,
      };
    }
    case "UPDATE_ROW_ITEM": {
      return {
        ...state,
        cachedStudyData: {
          ...state.cachedStudyData,
          [action.payload.page]: state.cachedStudyData[action.payload.page].map(
            (item) =>
              item.studypk === action.payload.studypk
                ? { ...item, ...action.payload.updatedStudyData }
                : item
          ),
        },
      };
    }
    case "TOGGLE_ROW_LOCK": {
      return {
        ...state,
        lockedRows: [
          ...state.lockedRows.filter(
            (item) => !action.payload.unlockedRows.includes(item)
          ),
          ...(action.payload.lockedRows ?? []),
        ],
        unlockedRows: [
          ...state.unlockedRows.filter(
            (item) => !action.payload.lockedRows.includes(item)
          ),
          ...(action.payload.unlockedRows ?? []),
        ],
      };
    }
    case "UPDATE_FILTERS": {
      // TODO - check if filters are actually different
      return {
        ...state,
        page: 0,
        cachedStudyData: {},
        hasLoadedFirstPage: false,
        filters: action.payload.filters,
        requiresReload: true,
      };
    }
    case "TOGGLE_ROW_SELECT": {
      const { selectedStudy, isChecked } = action.payload;
      const { studypk } = selectedStudy;
      const selectedStudies = state.selectedRows.flatMap((row) =>
        isChecked === true || row.studypk !== studypk ? row : []
      );
      if (isChecked === true) {
        selectedStudies.push(selectedStudy);
      }
      return {
        ...state,
        selectedRows: selectedStudies,
      };
    }
    case "CHANGE_QUERY_ARGS": {
      return { ...state, requiresReload: true };
    }
    default:
      return state;
  }
};

const HomePageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <HomePageContext.Provider value={{ state, dispatch }}>
      {children}
    </HomePageContext.Provider>
  );
};

const useHomePageContext = () => {
  const context = useContext(HomePageContext);
  if (!context) {
    throw new Error(
      "useHomePageContext must be used within a HomePage Provider"
    );
  }

  return context;
};

export { HomePageProvider, useHomePageContext };
