import { useEffect, useMemo, useRef, useState } from "react";
import cornerstone from "cornerstone-core";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { DIRECTIONS } from "pages/viewer/tools/consts/tools.consts";
import { DEFAULT_FRAMES_PER_SECOND } from "./viewport.consts";
import { LoadingOverlay } from "./LoadingOverlay";
import { calculateViewportStyles } from "./functions/calculateViewportStyles";

interface CornerstoneViewportProps {
  imageIds: string[];
  loadedImages?: any[];
  viewportIndex: number;
  isPlaying?: boolean;
  viewportOverlayComponent?;
  parsedStyle?;
  viewportIdentifier?: string;
  registerToolsToViewport?: any;
}

const CornerstoneViewport = ({
  imageIds,
  loadedImages,
  viewportIndex,
  parsedStyle,
  isPlaying = false,
  viewportOverlayComponent,
  viewportIdentifier,
  registerToolsToViewport,
}: CornerstoneViewportProps) => {
  const {
    dispatch,
    state: { gridDimensions, viewportFrameIndexes, isMPRActive },
  } = useViewerContext();
  const elementRef = useRef(null);
  const mprBackroundRef = useRef(null);
  const currentImageIndex = useMemo(
    () => viewportFrameIndexes[viewportIndex] ?? 0,
    [viewportFrameIndexes, viewportFrameIndexes[viewportIndex], viewportIndex]
  );
  const playbackFrameLength = useMemo(
    () => Math.round(1000 / DEFAULT_FRAMES_PER_SECOND),
    []
  );
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);

  useEffect(() => {
    const element = elementRef.current;
    cornerstone.enable(element);
    const mprElement = mprBackroundRef.current;
    if (mprElement) {
      cornerstone.enable(mprElement);
    }
    const loadAndDisplayImage = (index) => {
      if (loadedImages && loadedImages.length && index < loadedImages.length) {
        const displayedImageIndex =
          index <= loadedImages.length - 1 ? index : 0;
        const imageToLoad = loadedImages[displayedImageIndex];
        cornerstone.displayImage(element, imageToLoad, {
          scale: 1,
          pixelReplication: true,
          voi: {
            windowWidth: imageToLoad.windowWidth,
            windowCenter: imageToLoad.windowCenter,
          },
        });
        setIsLoadingImage(false);
      } else {
        if (imageIds[index]) {
          cornerstone.loadImage(imageIds[index]).then((image) => {
            cornerstone.displayImage(element, image);
            if (mprElement) {
              cornerstone.displayImage(mprElement, image);
            }
            setIsLoadingImage(false);
          });
        }
      }
    };
    loadAndDisplayImage(currentImageIndex);
    if (registerToolsToViewport && element) {
      registerToolsToViewport(element, viewportIndex);
    }

    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        dispatch({
          type: "SCRUB_IMAGE_FRAME_INDEX",
          payload: { direction: DIRECTIONS.FORWARD },
        });
      }, playbackFrameLength);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      cornerstone.disable(element);
    };
  }, [
    imageIds,
    currentImageIndex,
    isPlaying,
    dispatch,
    loadedImages,
    registerToolsToViewport,
    elementRef,
    mprBackroundRef,
  ]);

  const viewportStyle = calculateViewportStyles(
    gridDimensions,
    viewportIndex,
    parsedStyle
  );
  return (
    <>
      <div
        ref={elementRef}
        className="cornerstone-viewport"
        id={`viewport-${viewportIndex}${
          viewportIdentifier ? `-${viewportIdentifier}` : ""
        }`}
        style={viewportStyle}
      >
        {viewportOverlayComponent ? viewportOverlayComponent() : null}
        {!viewportIdentifier && (
          <LoadingOverlay
            isLoadingImage={isLoadingImage}
            viewportIndex={viewportIndex}
          />
        )}
      </div>
      {isMPRActive && viewportIndex === 0 && (
        <div
          ref={mprBackroundRef}
          className="cornerstone-viewport"
          id={`viewport-${viewportIndex}-mpr-background`}
          style={viewportStyle}
        >
          {viewportOverlayComponent ? viewportOverlayComponent() : null}
        </div>
      )}
    </>
  );
};

export default CornerstoneViewport;
