import { Fab } from "@mui/material";
import { useDarkModeTheme } from "hooks/useDarkmodeTheme";

interface CustomFabProps {
  ariaLabel: string;
  onClick?: () => void;
  disabled?: boolean;
  children: JSX.Element;
}
export const CustomFab = ({
  children,
  disabled = false,
  onClick,
}: CustomFabProps) => {
  const { brandThemeColor } = useDarkModeTheme();
  return (
    <Fab
      onClick={onClick}
      disabled={disabled}
      color="primary"
      variant="extended"
      aria-label="search studies"
      sx={{
        background: brandThemeColor,
        color: "white",
        "&:hover": {
          color: "black",
        },
      }}
    >
      {children}
    </Fab>
  );
};
