import cornerstoneTools from "cornerstone-tools";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

export class Pointer extends BaseAnnotationTool {
  hasIncomplete: boolean;
  constructor() {
    super({
      name: "Pointer",
      supportedInteractionTypes: ["Mouse", "Touch"],
    });

    this.hasIncomplete = false; ///////////////////////
  }

  activeCallback(element) {
    //Intentionally blank
  }

  preMouseDownCallback(event) {
    //Intentionally blank
  }

  createNewMeasurement(event) {
    //Intentionally blank
  }

  pointNearTool(element, data, coords) {
    //Intentionally blank
  }

  distanceFromPoint(event) {
    //Intentionally blank
  }

  renderToolData(event) {
    //Intentionally blank
  }
}
