import { AccountCircle } from "@mui/icons-material";
import { Container, Fab, Typography } from "@mui/material";
import { CustomFab } from "components/buttons/CustomFab";
import { FocusedTooltip } from "components/tooltips/FocusedTooltip";
import { useAppContext } from "context/app.context";

export const UserInfo = () => {
  const { name, date, loginTime, expiry } = useUserInfoData();

  return (
    <FocusedTooltip
      tooltipContent={
        <UserInfoContent date={date} loginTime={loginTime} expiry={expiry} />
      }
    >
      <CustomFab ariaLabel="user-info">
        <>
          <AccountCircle style={{ marginRight: "5px" }} />
          <Typography> {name}</Typography>
        </>
      </CustomFab>
    </FocusedTooltip>
  );
};
const UserInfoContent = ({ date, loginTime, expiry }) => {
  return (
    <Container style={{ width: "300px" }}>
      <Typography variant="subtitle2">Date: {date}</Typography>
      <Typography variant="subtitle2">Time of Login: {loginTime}</Typography>
      <Typography variant="subtitle2">Account Expiry: {expiry}</Typography>
      <Typography variant="subtitle2">
        App Version:
        {process.env.REACT_APP_FRONTEND_VERSION}
      </Typography>
    </Container>
  );
};

const useUserInfoData = () => {
  const {
    state: { userAuthData },
  } = useAppContext();
  return {
    name: userAuthData?.name ?? "",
    date: new Date().toDateString(),
    loginTime: userAuthData?.loginTime,
    expiry: userAuthData?.expiry ?? "Unknown",
  };
};
