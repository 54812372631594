import { useCallback, useMemo } from "react";
import { DisplayedDicomMetaData, ViewportState } from "../../dicomViewer.types";
import { decodeDateString } from "utils/formatting/decodeHL7";
import { useViewerContext } from "../../context/viewer.context";

export const useFormatMetaData = (dicomViewports: ViewportState[]) => {
  const {
    state: { studyImageMetaData, studyImageData },
  } = useViewerContext();

  const setViewportMetaData = useCallback(
    (viewportData, viewportIndex: number) => {
      // const updatedViewportMetaData = [...viewportMetaData];
      // updatedViewportMetaData[viewportIndex] = viewportData;
      // updateViewportMetaData(updatedViewportMetaData);
    },
    []
  );

  const imageMetaData = useMemo(
    () =>
      dicomViewports.map(({ studyImageIndex }, index) => {
        if (studyImageMetaData) {
          const dicomMetaData = studyImageMetaData[studyImageIndex];
          if (dicomMetaData) {
            return {
              topLeftDetails: {
                "Patient Name": dicomMetaData.patientName,
                "Patient Id": dicomMetaData.patientId,
                "Institution Name": dicomMetaData.institutionName,
                ...formatStudyDateAndTime(
                  dicomMetaData.studyTime,
                  dicomMetaData.studyDate
                ),
              },
              bottomLeftDetails: getPhysicianData(dicomMetaData),
              bottomRightDetails: {
                Image: `${studyImageIndex + 1}/${studyImageData.length}`,
                ...dicomMetaData[index],
              },
            };
          }
          return {
            topLeftDetails: {},
            bottomLeftDetails: {},
            bottomRightDetails: {
              Image: `${studyImageIndex + 1}/${studyImageData.length}`,
            },
          };
        }
        return {};
      }),
    [studyImageMetaData, dicomViewports, studyImageData]
  );
  return { imageMetaData, setViewportMetaData };
};

const getPhysicianData = (metadata: Partial<DisplayedDicomMetaData>) => {
  const physicianMetaData = {};
  if (metadata.consultingPhysiciansName) {
    physicianMetaData["Consulting Physician"] =
      metadata.consultingPhysiciansName;
  }
  if (metadata.performingPhysiciansName) {
    physicianMetaData["Performing Physician"] =
      metadata.performingPhysiciansName;
  }
  if (metadata.referringPhysiciansName) {
    physicianMetaData["Referring Physician"] = metadata.referringPhysiciansName;
  }
  return physicianMetaData;
};

const formatStudyDateAndTime = (studyTime: string, studyDate: string) => {
  const hour = studyTime.substring(0, 2);
  const min = studyTime.substring(2, 4);
  const sec = studyTime.substring(4, 6);

  // Construct the date string in the format DD/MM/YYYY
  const formattedDate = decodeDateString(studyDate);
  const formattedTime = `${hour}:${min}:${sec}`;
  return { "Study Date": formattedDate, "Study Time": formattedTime };
};
