import * as React from "react";
import Spinner from "react-bootstrap/Spinner";

export const DicomPageLoader = () => (
  <div>
    <Spinner
      animation="border"
      role="status"
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "4rem",
        height: "4rem",
        color: "#000000",
      }}
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);
