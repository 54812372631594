// @ts-nocheck
import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import Manipulators from "../api/Manipulators";
import Tools from "../api/Tools";
import Base from "../api/Base";
import { TOOL_IDS } from "../consts/tools.consts";
import { getDimensionData } from "../measurement-tools/measurementToolUtils";
const VertebralHeartScoreCursor = Tools.cursors;

export class VertebralHeartScore extends Base.BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: TOOL_IDS.VERTEBRAL_HEART_SCORE,
      supportedInteractionTypes: ["Mouse", "Touch"],
      svgCursor: VertebralHeartScoreCursor,
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
      },
    };

    super(props, defaultProps);

    this.hasIncomplete = false;

    this.throttledUpdateCachedStats = Util.throttle(
      this.updateCachedStats,
      110
    );
    this.imageMetaData = {};
    this.setImageMetadata = this.setImageMetadata.bind(this);
  }

  public setImageMetadata(imageMetaData: ImageMetadata) {
    if (imageMetaData) {
      this.imageMetaData = imageMetaData;
    }
  }

  createNewMeasurement(eventData) {
    // Create the measurement data for this tool with the end handle activated
    this.hasIncomplete = true;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: "",
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        start2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        end2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        start3: {
          x: eventData.currentPoints.image.x + 100,
          y: eventData.currentPoints.image.y + 100,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        end3: {
          x: eventData.currentPoints.image.x + 100,
          y: eventData.currentPoints.image.y + 100,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: true,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
        textBox2: {
          active: false,
          hasMoved: false,
          movesIndependently: true,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const seg1Near =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 25;
    const seg2Near =
      Util.lineSegDistance(
        element,
        data.handles.start2,
        data.handles.end2,
        coords
      ) < 25;

    //const segMoveLine = Util.lineSegDistance(element, data.handles.start3, data.handles.end3, coords) < 25;

    if (seg1Near) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
      data.handles.start2.movesIndependently = true;
      data.handles.end2.movesIndependently = true;
    } else if (seg2Near) {
      data.handles.start.movesIndependently = true;
      data.handles.end.movesIndependently = true;
      data.handles.start2.movesIndependently = false;
      data.handles.end2.movesIndependently = false;
      // } else if (segMoveLine) {
      //     data.handles.start.movesIndependently = true;
      //     data.handles.start2.movesIndependently = true;
      //     data.handles.end.movesIndependently = true;
      //     data.handles.end2.movesIndependently = true;
      // }
    }

    return seg1Near || seg2Near;
  }

  updateCachedStats(image, element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );

    // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
    const dx =
      (data.handles.end.x - data.handles.start.x) * (colPixelSpacing || 1);
    const dy =
      (data.handles.end.y - data.handles.start.y) * (rowPixelSpacing || 1);

    const dx1 =
      (data.handles.end2.x - data.handles.start2.x) * (colPixelSpacing || 1);
    const dy1 =
      (data.handles.end2.y - data.handles.start2.y) * (rowPixelSpacing || 1);

    // Calculate the length, and create the text variable with the millimeters or pixels suffix
    const length = Math.sqrt(dx * dx + dy * dy);
    const length2 = Math.sqrt(dx1 * dx1 + dy1 * dy1);

    data.length = length.toFixed(2);
    data.length2 = length2.toFixed(2);
    data.invalidated = false;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
    } = this.configuration;
    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = cornerstoneTools.getToolState(
      evt.currentTarget,
      this.name
    );

    if (!toolData) {
      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const context = Drawing.getNewContext(eventData.canvasContext.canvas);

    const lineWidth = cornerstoneTools.toolStyle.getToolWidth();
    const lineDash = cornerstoneTools.getModule("globalConfiguration")
      .configuration.lineDash;
    const font = cornerstoneTools.textStyle.getFont();
    const { element } = evt.detail;
    const image = cornerstone.getEnabledElement(element).image;
    const { rowPixelSpacing, colPixelSpacing } = Util.getPixelSpacing(image);

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      if (!data.value) {
        data.value = this.textBoxText(
          data.length,
          rowPixelSpacing,
          colPixelSpacing
        );
      }

      if (!data.value2) {
        data.value2 = this.textBoxText(
          data.length2,
          rowPixelSpacing,
          colPixelSpacing
        );
      }

      Drawing.draw(context, (context) => {
        Drawing.setShadow(context, this.configuration);

        // Differentiate the color of activation tool
        const color = cornerstoneTools.toolColors.getColorIfActive(data);
        const textBoxAnchorPoints = (handles) =>
          _findTextBoxAnchorPoints(handles.start, handles.end);
        const textBoxAnchorPoints2 = (handles) =>
          _findTextBoxAnchorPoints(handles.start2, handles.end2);

        const lineOptions = { color };

        if (renderDashed) {
          lineOptions.lineDash = lineDash;
        }

        Drawing.drawLine(
          context,
          eventData.element,
          data.handles.start,
          data.handles.end,
          lineOptions
        );

        if (data.complete) {
          Drawing.drawLine(
            context,
            eventData.element,
            data.handles.start2,
            data.handles.end2,
            lineOptions
          );
          const lengthX = Math.abs(data.handles.start.x - data.handles.end.x);
          const lengthY = Math.abs(data.handles.start.y - data.handles.end.y);
          const lengthOX = data.handles.end2.x - data.handles.start2.x;
          const lengthOY = data.handles.end2.y - data.handles.start2.y;

          if (lengthOY !== lengthY || lengthOX !== lengthX) {
            data.handles.start.x = data.handles.end.x + lengthX;
            const cordsEnd = {
              active: false,
              drawnIndependently: false,
              highlight: true,
              x: data.handles.start3.x + lengthOY,
              y: data.handles.start3.y + lengthOX,
            };
            Drawing.drawLine(
              context,
              eventData.element,
              data.handles.start3,
              cordsEnd,
              lineOptions
            );
          }
        }

        // Draw the handles
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        if (this.configuration.drawHandles) {
          Drawing.drawHandles(context, eventData, data.handles, handleOptions);
        }

        // Draw the text
        context.fillStyle = color;

        const text = data.value;
        const text2 = data.value2;

        if (!data.handles.textBox.hasMoved) {
          const textCoords = {
            x: (data.handles.start.x + data.handles.end.x) / 2,
            y: (data.handles.start.y + data.handles.end.y) / 2 - 10,
          };

          context.font = font;
          data.handles.textBox.x = textCoords.x;
          data.handles.textBox.y = textCoords.y;
        }

        if (!data.handles.textBox2.hasMoved) {
          const textCoords = {
            x: (data.handles.start2.x + data.handles.end2.x) / 2,
            y: (data.handles.start2.y + data.handles.end2.y) / 2 - 10,
          };

          context.font = font;
          data.handles.textBox2.x = textCoords.x;
          data.handles.textBox2.y = textCoords.y;
        }

        Drawing.drawLinkedTextBox(
          context,
          eventData.element,
          data.handles.textBox,
          text,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          0,
          true
        );

        if (data.complete) {
          Drawing.drawLinkedTextBox(
            context,
            eventData.element,
            data.handles.textBox2,
            text2,
            data.handles,
            textBoxAnchorPoints2,
            color,
            lineWidth,
            10,
            true
          );
        }
      });
    }
  }

  textBoxText(length, rowPixelSpacing, colPixelSpacing) {
    if (length === undefined) {
      return "";
    }
    if (Number.isNaN(length)) {
      return "";
    }

    const suffix = "mm";

    return `${length} ${suffix}`;
  }

  getIncomplete(element) {
    const toolState = cornerstoneTools.getToolState(element, this.name);

    if (toolState && Array.isArray(toolState.data)) {
      return toolState.data.find(({ complete }) => complete === false);
    }
  }

  addNewMeasurement(evt, interactionType) {
    evt.preventDefault();
    evt.stopPropagation();

    const eventData = evt.detail;

    let measurementData;
    let toMoveHandle;
    let doneMovingCallback = (success) => {
      // DoneMovingCallback for first measurement.
      if (!success) {
        cornerstoneTools.removeToolState(element, this.name, measurementData);

        return;
      }
      const eventType = cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED;
      const eventData = {
        toolName: this.name,
        toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
        element,
        measurementData,
      };

      Util.triggerEvent(element, eventType, eventData);
    };

    // Search for incomplete measurements
    const element = evt.detail.element;
    const pendingMeasurement = this.getIncomplete(element);

    if (pendingMeasurement) {
      measurementData = pendingMeasurement;
      measurementData.complete = true;
      measurementData.handles.start2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: false,
      };
      measurementData.handles.end2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      measurementData.handles.start3 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      measurementData.handles.end3 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      toMoveHandle = measurementData.handles.end2;
      this.hasIncomplete = false;
      doneMovingCallback = (success) => {
        // DoneMovingCallback for second measurement
        if (!success) {
          cornerstoneTools.removeToolState(element, this.name, measurementData);

          return;
        }

        const eventType = cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED;
        const eventData = {
          toolName: this.name,
          toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
          element,
          measurementData,
        };

        Util.triggerEvent(element, eventType, eventData);
      };
    } else {
      measurementData = this.createNewMeasurement(eventData);
      cornerstoneTools.addToolState(element, this.name, measurementData);
      toMoveHandle = measurementData.handles.end;
    }

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);

    Manipulators.moveNewHandle(
      eventData,
      this.name,
      measurementData,
      toMoveHandle,
      this.options,
      interactionType,
      doneMovingCallback
    );
  }

  activeCallback(element) {
    //console.log("ACTIVE CALLBACK");
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  passiveCallback(element) {
    //console.log("PASSIVE CALLBACK");
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  enabledCallback(element) {
    //console.log("ENABLED CALLBACK");
    element.removeEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  disabledCallback(element) {
    //console.log("DISABLED CALLBACK");
    element.removeEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  onMeasureModified(ev) {
    //console.log("ON MEASURE MODIFIED");
    const { element } = ev.detail;
    const image = cornerstone.getEnabledElement(element).image;
    const { rowPixelSpacing, colPixelSpacing } = Util.getPixelSpacing(image);

    if (ev.detail.toolName !== this.name) {
      return;
    }
    const data = ev.detail.measurementData;

    // Update textbox stats
    if (data.invalidated === true) {
      if (data.length) {
        this.throttledUpdateCachedStats(image, element, data);
      } else {
        this.updateCachedStats(image, element, data);
      }
    }

    data.value = this.textBoxText(
      data.length,
      rowPixelSpacing,
      colPixelSpacing
    );
    data.value2 = this.textBoxText(
      data.length2,
      rowPixelSpacing,
      colPixelSpacing
    );
  }
}

function _findTextBoxAnchorPoints(startHandle, endHandle) {
  return [
    {
      x: startHandle.x,
      y: startHandle.y,
    },
    {
      x: endHandle.x,
      y: endHandle.y,
    },
  ];
}
