import { useCallback, useEffect, useMemo, useState } from "react";
import { getImageUrlData } from "../../functions/getImageUrlData";
import { addToolState } from "../../functions/addToolState";
import { useImageMetaData } from "pages/viewer/hooks/metadata/useImageMetaData";
import { getDicomImageAnnotations } from "services/studies/studies";

export const useSavedAnnotations = (dicomViewports) => {
  const { imageMetaData } = useImageMetaData();
  const [fetchedViewports, setFetchedViewports] = useState<number[]>([]);
  const index = useMemo(() => 0, []);

  const fetchAnnotations = useCallback(async () => {
    if (dicomViewports.length) {
      setFetchedViewports((prev) => [...prev, 0]);
      if (!fetchedViewports.includes(index)) {
        const imageUrl = dicomViewports[0]["imageIds"][0];
        const { studyIUID, seriesIUID, instanceIUID } =
          getImageUrlData(imageUrl);
        const annotations = await getDicomImageAnnotations(
          studyIUID,
          seriesIUID,
          instanceIUID
        );
        if (!!annotations && annotations.length) {
          const toolState = JSON.parse(annotations[0]["annotation"]);
          if (toolState) {
            addToolState({ studyIUID, seriesIUID, instanceIUID }, toolState);
          }
        }
      }
    }
  }, [dicomViewports]);

  useEffect(() => {
    if (imageMetaData !== null) {
      fetchAnnotations();
    }
  }, [fetchAnnotations, imageMetaData]);
};
